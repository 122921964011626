var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"bg-white px-2 pb-2 border-form",class:_vm.hasStepGuide ? 'radius-content' : 'pt-3 pt-lg-2 py-2',attrs:{"md":"9","xs":"12"}},[_c('b-row',[_c('b-col',{attrs:{"md":"12","xs":"12"}},[_c('label',{staticClass:"font-14 mrb-10"},[_vm._v(_vm._s(_vm.$t('payment.wiseTransfer.labelSelectCountry')))]),_c('v-select',{staticClass:"coin-select select-info",attrs:{"label":"name","options":_vm.coinTypes,"clearable":false},on:{"input":_vm.handleChangeCoin},scopedSlots:_vm._u([{key:"open-indicator",fn:function(ref){
var attributes = ref.attributes;
return [_c('span',_vm._b({},'span',attributes,false),[_c('feather-icon',{attrs:{"icon":"ChevronDownIcon","size":"20"}})],1)]}},{key:"selected-option",fn:function(ref){
var icon = ref.icon;
var name = ref.name;
var value = ref.value;
return [_c('img',{staticClass:"coin-spacing",staticStyle:{"width":"26px","height":"auto"},attrs:{"alt":"flag","src":icon}}),_c('strong',{staticClass:"coin-spacing"},[_vm._v(_vm._s(name))]),_vm._v(" - "+_vm._s(value)+" ")]}},{key:"option",fn:function(ref){
var icon = ref.icon;
var name = ref.name;
var value = ref.value;
return [_c('img',{staticClass:"coin-spacing",staticStyle:{"width":"26px","height":"auto"},attrs:{"alt":"flag","src":icon}}),_c('strong',[_vm._v(_vm._s(name))]),_vm._v(" - "+_vm._s(value)+" ")]}}]),model:{value:(_vm.coinType),callback:function ($$v) {_vm.coinType=$$v},expression:"coinType"}})],1),_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"btn-register"},[_c('div',{staticClass:"icon"},[_c('img',{staticClass:"img-icon",attrs:{"alt":"flag","src":require('@/assets/images/pages/payment/ic-form-btn-register-wise.svg')}})]),_c('div',{staticClass:"content-btn"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t('payment.wiseTransfer.contentIfDontHaveAccount'))+" ")])]),_c('div',{staticClass:"btn-airwallex"},[_c('a',{staticClass:"content-btn-register",attrs:{"href":"https://wise.com/invite/i/ducp122","target":"_blank"}},[_c('span',[_vm._v(_vm._s(_vm.$t('payment.wiseTransfer.btnRegisterWith')))]),_c('img',{attrs:{"alt":"logo-airwallex","src":require('@/assets/images/pages/payment/logo-wise.svg')}})])])])])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }