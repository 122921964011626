<template>
  <div
    class="top-up-container"
  >
    <div
      :class="hasStepGuide ? 'top-up-on-step' : 'top-up-form-container'"
    >
      <!--Top up form-->
      <div
        id="add-fund-form-credit-card"
        data-testid="add-fund-form-credit-card"
        class="add-fund-form"
      >
        <validation-observer
          v-slot="{ invalid }"
          ref="amountValidation"
        >
          <b-form
            class="amount-form"
          >
            <!--Setup cost-->
            <setup-cost
              v-if="hasStepGuide && isSetupCostModel"
              :amount="currentPaymentAmount.value"
            />
            <!-- /Setup cost-->
            <div v-else>
              <div class="d-block d-md-flex justify-content-between align-items-center">
                <h3
                  id="title-add-fund-credit-card"
                  data-testid="title-add-fund-credit-card"
                >
                  {{ $t('stepGuideControl.textAddFund') }}
                </h3>
              </div>
              <p
                id="sub-title-add-fund-credit-card"
                data-testid="sub-title-add-fund-credit-card"
              >{{ $t('payment.creditCard.textEnterAmount') }}</p>
              <b-form-group
                :label="$t('balance.textAmount')"
                class="position-relative form-label"
              >
                <validation-provider
                  #default="{ errors }"
                  id="amount"
                  :name="$t('balance.textAmount')"
                  :rules="{
                    required,
                    integerNumber,
                    greaterThanZero,
                    rangeTopUp: { min: minTopUp },
                  }"
                >
                  <b-input-group
                    id="amount-add-fund"
                    data-testid="amount-add-fund"
                    class="input-group-merge width-custom w-100"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="DollarSignIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      v-model.number="currentPaymentAmount.value"
                      id="input-amount-add-fund"
                      data-testid="input-amount-add-fund"
                      class="input-height"
                      autofocus
                      type="number"
                      placeholder="EX: 2500"
                      :state="errors.length > 0 ? false : null"
                      @input="onChangeCurrentPaymentAmount"
                    />
                    <b-input-group-append
                      v-if="currentPaymentAmount.value === maxAmount"
                      is-text
                    >
                      <img
                        :src="require('@/assets/images/pages/payment/ic-max.svg')"
                        alt="image"
                      >
                    </b-input-group-append>
                  </b-input-group>
                  <small
                    v-if="errors[0]"
                    id="errors-amount-credit-card"
                    data-testid="error-amount-credit-card"
                    class="text-danger"
                  >
                    {{ errors[0] }}
                  </small>
                  <small
                    v-if="!errors[0] && currentPaymentAmount.value"
                    id="amount-valid-credit-card"
                    data-testid="amount-valid-credit-card"
                    class="d-flex align-items-center"
                    style="margin-top: 10px"
                  >
                    <b-img
                      class="icons-tick"
                      fluid
                      alt="guide"
                      style="margin-right: 5px"
                      :src="require('@/assets/images/icons/icon-tick.svg')"
                    />
                    {{ $t('payment.textThisAmountIsSuitable') }}
                  </small>
                  <div class="info-box d-flex align-items-center">
                    <feather-icon
                      v-b-tooltip.v-light="`${$t('payment.creditCard.textAvailableLimit')}: $100,000`"
                      icon="HelpCircleIcon"
                      size="12"
                      class="icon-info"
                    />
                  </div>
                </validation-provider>
              </b-form-group>
              <div
                class="d-flex align-items-md-center flex-wrap max-width"
              >
                <top-up-amount-item
                  v-for="(item, index) in amountItem"
                  :key="index"
                  :id="`amount-${item.value}`"
                  :data-testid="`amount-${item.value}`"
                  :item="item"
                  :index="index"
                  :is-active="currentPaymentAmount.value === item.value"
                  @choosePaymentAmount="choosePaymentAmount"
                />
              </div>
            </div>

            <div
              v-if="hasAMId && tipMode"
              class="mt-2"
            >
              <b-form-checkbox
                v-model="showTipsForAM"
                :aria-expanded="showTipsForAM ? 'true' : 'false'"
                aria-controls="collapse-1-inner"
                data-testid="checkbox-show-tips-input"
                @input="handleShowTipsInput"
              >
                <span class="cursor-pointer">{{ $t('payment.txtCheckboxShowTipsForAM') }}</span>
              </b-form-checkbox>
            </div>

            <b-collapse
              v-model="showTipsForAM"
              id="collapse-1-inner"
              class="mt-2"
            >
              <b-form-group
                class="position-relative form-label"
                label-for="tip-amount"
              >
                <validation-provider
                  #default="{ errors }"
                  id="tip"
                  name="tip"
                  :rules="{
                    positive,
                    rangeTipAmount: { min: minTipAmount },
                  }"
                >
                  <b-input-group
                    class="input-group-merge width-custom w-100"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="DollarSignIcon" />
                    </b-input-group-prepend>
                    <cleave
                      v-model.number="amountTip"
                      id="tip-amount"
                      class="form-control"
                      :placeholder="$t('payment.creditCard.placeholderTip')"
                      :options="cleaveOptions"
                      :state="errors.length > 0 ? false : null"
                      @keyup.native="onChangeTipAmount"
                    />
                  </b-input-group>
                  <small
                    v-if="errors[0]"
                    class="text-danger"
                  >
                    {{ errors[0] }}
                  </small>
                </validation-provider>
              </b-form-group>
              <div
                class="d-flex align-items-md-center flex-wrap max-width"
              >
                <tip-amount-item
                  v-for="(item, index) in tipItem"
                  :key="index"
                  :id="`tip-${index+1}`"
                  :data-testid="`tip-${index+1}`"
                  :item="item"
                  :index="index"
                  :is-active="currentTipIndex === index"
                  @chooseTipAmount="chooseTipAmount"
                />
              </div>
            </b-collapse>

            <div class="d-flex align-items-center mt-2 justify-content-end">
              <!--        <span-->
              <!--          class="font-14 text-danger w-50"-->
              <!--        >-->
              <!--          {{ errorRecharge }}-->
              <!--        </span>-->
              <btn-loading
                id="trigger-top-up-cc"
                data-testid="trigger-top-up-cc"
                span-id="trigger-top-up-cc"
                class="btn-height btn-text-color"
                variant-convert="btn-submit"
                pill
                :disabled="invalid || isDisabledButton"
                :loading="reChargeLoading"
                @click.prevent="submitForm"
              >
                {{ $t('payment.creditCard.textTopUp') }}
              </btn-loading>
            </div>
          </b-form>
        </validation-observer>
      </div>
      <!-- /Top up form-->

      <!-- Horizontal-divide -->
      <div
        class="line-center"
      >
        <div class="horizontal-divide" />
      </div>
      <!-- /Horizontal-divide-->

      <!-- Card summary -->
      <div
        class="summary-form"
      >
        <credit-card-summary
          :amount="currentPaymentAmount.value || 0"
          :amount-tip="amountTip || 0"
          :current-tip="currentTip || 0"
          :show-tips-input="showTipsForAM"
        />
      </div>
      <!-- /Card summary-->
    </div>

    <div>
      <div
        v-if="errorTopup"
      >
        <error-tag :error="errorTopup" />
      </div>
    </div>
  </div>
</template>
<script>
// Bootstrap
import {
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormGroup,
  BForm,
  BImg,
  VBTooltip,
  BInputGroupAppend,
  BFormCheckbox,
  BCollapse,
  VBToggle,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'

import { PAYMENT_CHILD_TYPE, PAYMENT_METHOD, PERCENT_TIP_AMOUNT } from '@/constants'

// validation
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  greaterThanZero, required, integerNumber, rangeTopUp, positive, rangeTipAmount,
} from '@validations'

// mixins
import generalConfigsMixin from '@/mixins/generalConfigsMixin'
import billingMixin from '@/mixins/billingMixin'
import stepGuideMixin from '@/mixins/stepGuideMixin'
import { toastification } from '@core/mixins/toast'

// components
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import Ripple from 'vue-ripple-directive'
import { bus } from '@/plugins/bus'
import { createNamespacedHelpers } from 'vuex'
import _get from 'lodash/get'
import TipAmountItem from '@/views/payment/components/CreditCard/TipAmountItem.vue'
import numberFormatMixin from '@/mixins/numberFormatMixin'
import paymentMixin from '@/mixins/paymentMixin'
import errorTag from '@/components/errorTag.vue'
import SetupCost from '@/views/payment/components/SetupCost.vue'
import TopUpAmountItem from './TopUpAmountItem.vue'
import CreditCardSummary from './CreditCardSummary.vue'

const { mapGetters: mapGettersAuth } = createNamespacedHelpers('auth')
const { mapGetters } = createNamespacedHelpers('adsAccount')

export default {
  components: {
    SetupCost,
    errorTag,
    // components
    CreditCardSummary,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BForm,
    BFormGroup,
    BImg,
    BtnLoading,
    BInputGroupAppend,
    BFormCheckbox,
    BCollapse,
    TopUpAmountItem,
    TipAmountItem,

    // validation
    ValidationProvider,
    ValidationObserver,
    Cleave,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
    Ripple,
  },
  mixins: [
    toastification,
    generalConfigsMixin,
    billingMixin,
    stepGuideMixin,
    numberFormatMixin,
    paymentMixin,
  ],
  props: {
    reChargeLoading: {
      type: Boolean,
      default: false,
    },
    errorRecharge: {
      type: String,
      default: '',
    },

    errorTopup: {
      type: String,
      default: '',
    },
    userData: {
      type: Object,
      required: false,
      default: () => {
      },
    },
  },

  data() {
    return {
      showTipsForAM: false,
      PAYMENT_METHOD,

      tipItem: PERCENT_TIP_AMOUNT,
      currentPaymentAmount: {
        value: null,
        type: PAYMENT_CHILD_TYPE.NUMBER,
      },

      currentTipIndex: null,
      currentTip: null,
      amountTip: null,

      // validation
      required,
      greaterThanZero,
      rangeTopUp,
      rangeTipAmount,
      integerNumber,
      positive,

      adAccount: {},
      // isToggle: null,
      error: '',
      cleaveOptions: {
        numeral: true,
        numeralPositiveOnly: true,
        numeralThousandsGroupStyle: 'none',
      },
      minTipAmount: 0,

      transactionType: false,
      isDisabledButton: false,
    }
  },

  computed: {
    ...mapGetters(['listAccount', 'loading']),
    ...mapGettersAuth(['user']),

    listAccounts() {
      return _get(this.listAccount, ['content'], [])
    },

    maxAmount() {
      return 100000
    },

    checkHasNextBtn() {
      return this.hasStepGuide && this.user?.data?.balance > 0
    },
  },

  watch: {
    user: {
      async handler(currentUser) {
        this.handleSetupCost()
        if (currentUser?.data?.onboardingStep) {
          this.isDisabledButton = this.isSetupCostModel && currentUser?.data?.onboardingStep === 5
        }
      },
      deep: true,
      immediate: true,
    },

    generalConfigs: {
      handler(val) {
        if (val) {
          this.handleSetupCost()
        }
      },
      deep: true,
      immediate: true,
    },
  },

  // mounted() {
  //   this.resetForm()
  // },

  methods: {
    // handleSelectAdAccount(data) {
    //   this.adAccount = data?.account
    //   this.isToggle = data.isToggle
    // },

    handleShowTipsInput() {
      if (!this.showTipsForAM) {
        this.amountTip = null
        this.onChangeTipAmount()
      }
    },

    handleSetupCost() {
      if (this.hasStepGuide && this.isSetupCostModel) {
        this.currentPaymentAmount.value = this.generalConfigs?.setupCostFee
        this.transactionType = 'setup_cost'
      }
    },

    onChangeTipAmount() {
      this.currentTipIndex = null
      this.currentTip = null
    },

    onChangeCurrentPaymentAmount() {
      if (this.currentTip) {
        this.amountTip = ((this.currentTip * this.currentPaymentAmount.value) / 100)
      }
    },

    choosePaymentAmount(data) {
      this.currentPaymentAmount = { ...data.item }
      this.currentIndex = data.index
      this.onChangeCurrentPaymentAmount()

      this.$nextTick(() => {
        this.$refs.amountValidation.validate()
      })
    },

    chooseTipAmount(data) {
      this.currentTip = data.percent === this.$t('payment.textNoTip') ? null : data.percent
      this.amountTip = ((this.currentTip * this.currentPaymentAmount.value) / 100)
      this.currentTipIndex = data.index
    },
    async submitForm() {
      if (this.noPlan) {
        this.$router.push('/billing')
      } else {
        const success = await this.$refs.amountValidation.validate()
        if (success) {
          const params = {
            amount: this.currentPaymentAmount.value,
            ...(this.amountTip && { tipAMAmount: this.amountTip }),
            ...(this.transactionType && { transactionType: this.transactionType }),
            // ...(this.isToggle && this.adAccount && { adsAccountId: this.adAccount?.value }),
          }
          await this.$emit('recharge', params)
        }
      }
    },

    resetForm() {
      this.currentPaymentAmount = {
        value: null,
        type: PAYMENT_CHILD_TYPE.NUMBER,
      }
      this.amountTip = null
      this.currentTip = null
      this.currentTipIndex = null
      this.currentIndex = null
      this.$refs.amountValidation.reset()
    },

    sendInvoice() {
      // send data to success page.
      const dataInvoice = {
        amount: this.currentPaymentAmount.value,
      }
      bus.$emit('set-invoice-data', dataInvoice)
    },
  },
}
</script>
<style lang="scss">
.is-invalid{
  #tip-amount:not(:focus){
    border-color: #e11c4e !important;
  }
}
</style>

<style lang="scss" scoped>
.top-up-container {
  padding: 24px;
  display: flex;
  flex-direction: column;
}

.top-up-on-step {
  .line-center {
    display: none;
  }
}

.top-up-form-container {
  display: flex;
  justify-content: space-between;

  .add-fund-form {
    max-width: 50%;
  }

  .line-center {
    margin: 0 1.5rem;
  }

  .summary-form {
    width: 50%;
  }

  @media (max-width: 1399px) {
    display: block;

    .add-fund-form {
      max-width: 100%;
    }

    .line-center {
      display: none;
    }

    .summary-form {
      width: 100%;
    }
  }
}

.horizontal-divide {
  width: 1px;
  height: 100%;
  background: #eeeeee;
}

.max-width {
  max-width: 410px;
}

.input-height {
  height: 50px;
}

.mr-mb {
  margin-right: 10px;
}

.width-custom {
  width: 63%;
  @media (max-width: 767px) {
    width: 100%;
  }
}

.info-box {
  position: absolute;
  top: -23px;
  right: 3px;
  font-size: 0.875rem;

  .icon-info {
    margin-left: 4px;
  }
}

.form-label {
  font-size: 12px;
  font-weight: 600;
}

.error-message-stripe {
  margin: 24px 0 1rem;
  background: rgba(225, 28, 78, .1);
  border-radius: 20px;
  padding: 1rem 2rem;
  color: #000;

  .content-error {
    margin-left: 6px;
    margin-bottom: 0;
  }
}
</style>
