<template>
  <div>
    <div
      class="bg-white list-card px-0 d-flex flex-column justify-content-center"
      :class="hasStepGuide ? 'radius-content' : 'list-card'"
    >
      <div class="list-header">
        <b-row class="mx-2 my-1">
          <b-col
            cols="6"
            md="3"
            class="pl-0 text-placeholder"
          >
            {{ $t('payment.payoneer.titlePaymentAccount', {payment: "PO"}) }}
          </b-col>
          <b-col
            cols="6"
            md="3"
            class="text-placeholder"
          >
            {{ $t('account.status') }}
          </b-col>
          <b-col
            cols="3"
            md="3"
            class="d-none d-md-block text-placeholder"
          >
            {{ $t('payment.payoneer.paymentBalance', {payment: "PO"}) }}
          </b-col>
          <b-col
            cols="3"
            md="3"
            class="d-none d-md-block text-center"
          />
        </b-row>
      </div>
      <div
        class="list-body"
        :style="{margin: isConnected ? '15px 0 20px 0': ''}"
      >
        <b-row v-if="!isConnected">
          <b-col
            v-if="($route.query.error)"
            cols="12"
            class="my-0 text-center text-placeholder"
          >
            <span class="text-primary">
              <b-badge
                variant="primary"
              >{{ $t('common.textError') }}</b-badge>
              {{ $route.query.error }}!
            </span>
            <hr class="hr-custom">
          </b-col>

          <b-col
            v-else
            cols="12"
            class="m-0 p-0 text-center text-placeholder"
          >
            <span>
              {{ $t('payment.payoneer.contentNoAccountPO') }}
            </span>
          </b-col>
        </b-row>
        <b-row
          v-else
          class="justify-content-between"
        >
          <b-col
            cols="6"
            md="3"
          >
            <div class="pl-2 d-flex font-medium">
              <img
                :src="payoneerLogo"
                width="24px"
                style="margin-right: 8px"
                alt="Payoneer"
              >
              Payoneer
            </div>
          </b-col>
          <b-col
            cols="6"
            md="3"
          >
            <h5 class="ml-md-1">
              <b-badge
                pill
                variant="light"
                class="text-primary"
                style="margin-top: -2px"
              >
                <div class="d-flex align-items-center font-weight-normal">
                  <b-img
                    :src="require('@/assets/images/common/ic-tick-circle-green.svg')"
                    alt="verified"
                    style="margin-right: 6px"
                    width="21"
                  />
                  {{ $t('payment.payoneer.textConnected') }}
                </div>
              </b-badge>
            </h5>
          </b-col>

          <b-col
            cols="9"
            md="3"
            class=""
          >
            <div
              v-if="currentUsdAccount"
              class="font-medium d-flex align-items-center justify-content-between po-balance"
            >
              <span class="d-inline-block d-sm-none ml-1">
                {{ $t('payment.payoneer.paymentBalance', {payment: "Payoneer"}) }}
              </span>

              <span
                class="font-medium mr-xs-1 mr-1 ml-1"
              >
                ${{ currentUsdAccount.available_balance }}
                <span class="font-14 font-weight-normal text-placeholder">{{ currentUsdAccount.currency }}</span>
              </span>

            </div>
          </b-col>

          <b-col
            cols="3"
            md="3"
            class="text-center"
          >
            <feather-icon
              v-b-tooltip.v-light="this.$t('payment.payoneer.textRemove')"
              class="cursor-pointer"
              :icon="disconnectAccount ? 'Trash2Icon': 'PlusCircleIcon'"
              size="16"
              @click="disconnectAccount"
            />
          </b-col>
        </b-row>
      </div>

      <div
        v-if="!isConnected"
        class="list-footer"
      >
        <b-row class="w-100 m-0">
          <b-col
            cols="12"
            class="text-center"
          >
            <div
              v-if="!isConnected"
              class="d-flex align-items-center position-relative"
            >
              <b-row class="w-100">
                <b-col
                  md="5"
                  class="content-btn"
                >
                  <div class="left-content d-flex align-items-center justify-content-center">
                    <b-spinner
                      v-show="loading"
                      variant="secondary"
                      small
                      style="margin-right: 5px"
                    />
                    <span
                      class="text-success cursor-pointer"
                      @click="connectAccount"
                    >
                      {{ $t('guidePopUp.guideTopUpPayoneer.btnConnectPOAccount') }}
                    </span>
                  </div>
                </b-col>
                <b-col
                  md="2"
                  class="d-flex justify-content-center"
                >
                  <div class="line-center" />
                </b-col>
                <b-col
                  md="5"
                  class="content-btn"
                >
                  <div class="text-align-center">
                    <span
                      class="text-danger cursor-pointer"
                      @click="createAccount"
                    >
                      {{ $t('guidePopUp.guideTopUpPayoneer.btnCreateNewPOAccount') }}
                    </span>
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BRow, BCol, BSpinner, BBadge, BImg, VBTooltip,
} from 'bootstrap-vue'
import { createNamespacedHelpers } from 'vuex'
import _has from 'lodash/has'
import _find from 'lodash/find'
import envMixin from '@/mixins/envMixin'
import stepGuideMixin from '@/mixins/stepGuideMixin'

const { mapActions, mapState, mapGetters } = createNamespacedHelpers('payment')
const { mapActions: mapActionsAuth, mapGetters: mapGettersAuth } = createNamespacedHelpers('auth')

export default {

  directives: {
    'b-tooltip': VBTooltip,
  },

  components: {
    BRow,
    BCol,
    BSpinner,
    BBadge,
    BImg,
  },

  mixins: [envMixin, stepGuideMixin],

  props: {
    account: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      toggleAddNewAccount: false,
      showAddNewCard: false,
    }
  },

  computed: {
    ...mapGetters(['loading', 'message', 'status']),
    ...mapGettersAuth(['user']),
    ...mapState({
      payoneerAccount: state => state.payoneerAccount,
      clientRedirectUrl: state => state.payoneerAccount.clientRedirectUrl,
      currentAccounts: state => state.payoneerAccount.currentAccounts,
    }),

    isConnected() {
      return _has(this.user.data.billings, 'payoneer')
    },

    payoneerLogo() {
      // eslint-disable-next-line global-require
      return require('@/assets/images/pages/payment/payoneer.svg')
    },

    currentUsdAccount() {
      return _find(this.currentAccounts, n => n.currency === 'USD')
    },
  },

  watch: {
    clientRedirectUrl:
    {
      handler(newUrl) {
        if (newUrl !== '') {
          window.location.href = newUrl
        }
      },
      immediate: true,
      deep: true,
    },

    isConnected: {
      handler(newValue) {
        if (newValue) this.getPayoneerAccountBalance()
      },
      immediate: true,
      deep: true,
    },

  },

  mounted() {
    // this.getAccountInfo()
  },

  methods: {
    ...mapActions(['connectPayoneerAccount', 'disconnectPayoneerAccount', 'getPayoneerAccountBalance']),
    ...mapActionsAuth(['getAccountInfo']),

    connectAccount() {
      const params = {
        clientRedirectUrl: `${process.env.VUE_APP_SITE_URL}/payment/add-fund`,
      }
      this.connectPayoneerAccount(params)
    },

    createAccount() {
      window.open('https://www.payoneer.com/raf/?rid=49E2CE70-6C60-41B8-AB02-88B4BA1C66EE')
    },

    async disconnectAccount() {
      await this.disconnectPayoneerAccount()
      this.getAccountInfo()
    },
  },

}
</script>
<style lang="scss" scoped>
.list-header{
  border-bottom: 1px solid #ebe9f1;
}

.list-body{
  border-bottom: 1px solid #ebe9f1;
  padding: 1rem 0;
}

  .list-card{
    // max-width: 864px;
    border-radius: var(--border-radius-base);
    @media (max-width: 992px) {
      padding-top: 42px;
    }
  }

  .radius-content {
    border-radius: 0px 0px var(--border-radius-base) var(--border-radius-base);
  }

  .content-btn{
    padding: 1rem 0;
  }

  .line-center{
    background: #ebe9f1;

    width: 1px;
    height: 100%;
  }

  .divider-vertical{
    height: 56px;
    width: 1px;
    top: -16px;
    background: #ebe9f1;
    left: 0;
    right: 0;
    margin: auto;
    @media (max-width: 767px) {
      display: none;
    }
  }

  .po-balance {
    width: max-content;
  }
</style>
