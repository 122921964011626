<template>
  <div>
    <div class="d-block d-md-flex justify-content-between align-items-center">
      <h3
        id="title-add-fund-credit-card"
        data-testid="title-add-fund-credit-card"
        class="mb-1"
      >
        {{ $t('stepGuideControl.textSetupCost') }}
      </h3>
    </div>

    <div class="setup-cost-container">
      <div class="setup-cost-amount">
        <h3 class="mb-0">
          {{ amount }} $
        </h3>
      </div>
      <div class="font-14 setup-cost-content">
        <ul class="pl-2">
          <li class="mb-1">
            {{ $t('setupCost.noteSetupCost1', {platform: platformName}) }}
          </li>
          <li>
            {{ $t('setupCost.noteSetupCost2', {platform: platformName}) }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { PAYMENT_METHOD } from '@/constants'
import generalConfigsMixin from '@/mixins/generalConfigsMixin'
import stepGuideMixin from '@/mixins/stepGuideMixin'

export default {
  mixins: [generalConfigsMixin, stepGuideMixin],
  props: {
    amount: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      PAYMENT_METHOD,

      isDisabledButton: false,
    }
  },
}
</script>
<style lang="scss" scoped>
.setup-cost-container {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  .setup-cost-amount {
    padding: 12px 18px;
    border-radius: 10px 10px 0 0;
    background-color: rgba(0, 0, 0, 0.05);
  }
  .setup-cost-content {
    margin: 18px;
    color: rgba(0, 0, 0, 0.7);

    ul {
      list-style: disc;
    }
  }
}

.btn-paypal {
  max-width: 200px;
  width: 100%;
  position: relative;
  z-index: 1;

  .disable-btn {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    z-index: 9999;
    cursor: no-drop;
  }

  .paypal-button {
    position: relative;

    &.disable {
      opacity: 0.75;
    }
  }
}
</style>
