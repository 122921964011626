<template>
  <div>
    <div
      class="bg-white font-14"
      :class="hasStepGuide ? 'payment-container-step' : 'payment-container'"
    >
      <div class="warning-message p-2 border-form d-flex align-items-start mx-2 mb-2">
        <img
          class="alert-img mr-50"
          alt="alert"
          :src="require('@/assets/images/common/ic-alert.svg')"
        >
        <span class="text-warning-refresh">{{ $t('SMB.payment.contentNoteAddFund') }}
          <span
            class="border-text-copy text-success cursor-pointer"
            @click="$emit('handle-refresh-list-bank')"
          >{{ $t('SMB.payment.textRefresh') }}</span>
          {{ $t('SMB.payment.textGetDifferentContent') }}</span>
      </div>

      <b-row class="payment-info mx-2 mt-2 py-1">
        <b-col
          cols="12"
          md="7"
        >
          <b-img
            class="mb-1 w-50"
            :src="paymentInfo.logo"
            alt="logo-branch"
            fluid
          />
          <p>{{ paymentInfo.name }} ({{ paymentInfo.shortName }})</p>
          <p>{{ $t('SMB.payment.textBankAccountName') }}: <span class="font-16 font-medium">{{ paymentInfo.accName }}</span></p>

          <div class="d-flex justify-content-between align-items-end mb-1">
            <div :class="hasStepGuide ? 'd-md-flex' : 'd-lg-flex'">
              <p class="mb-0 margin-right-4">
                {{ $t('SMB.payment.textBankAccountNumber') }}:
              </p>
              <p class="font-16 font-medium mb-0">
                {{ paymentInfo.accNumber }}
              </p>
            </div>
            <b-button
              variant="outline-success"
              class="border-btn-copy ml-1 font-14"
              @click="$emit('handle-copy', paymentInfo.accNumber)"
            >
              {{ $t('SMB.payment.textCopy') }}
            </b-button>
          </div>

          <b-row class="d-flex align-items-end mb-1">
            <b-col
              :cols="hasStepGuide ? 7 : 8"
              :class="hasStepGuide ? 'content-step-detail' : 'd-md-flex' "
              class="align-items-center pr-0"
            >
              <p class="mb-0">
                {{ $t('SMB.payment.textDescriptionTransfer') }}:
              </p>
              <p class="font-16 text-success font-medium text-description mb-0">
                {{ paymentInfo.description }}
              </p>
            </b-col>
            <b-col
              :cols="hasStepGuide ? 5 : 4"
              class="list-btn-refresh pl-0"
            >
              <b-button
                variant="outline-success"
                class="border-btn-copy ml-md-1 font-14"
                @click="$emit('handle-copy', paymentInfo.description)"
              >
                {{ $t('SMB.payment.textCopy') }}
              </b-button>
            </b-col>
          </b-row>

          <b-button
            class="mr-1 mt-2 border-0 btn-back"
            variant="secondary"
            pill
            @click.prevent="handleCancel"
          >
            {{ $t('SMB.payment.textBack') }}
          </b-button>
        </b-col>

        <b-col
          cols="12"
          md="5"
          class="mt-2 mt-md-0"
        >
          <b-img
            :src="urlQRCode"
            alt="logo-branch"
            fluid
          />
        </b-col>

        <div class="warning-message p-2 border-form d-flex align-items-start w-100 mx-1 mt-2">
          <img
            class="alert-img mr-50"
            alt="alert"
            :src="require('@/assets/images/common/ic-alert.svg')"
          >
          <span>{{ $t('SMB.payment.contentNoticeAfterBank') }}
            <user-hint
              :hide-icon="true"
              :hide-quote="true"
            />
          </span>
        </div>
      </b-row>
    </div>

    <top-up-success-modal
      ref="open-top-up-success-modal"
      :top-up-amount="topUpAmount"
      :amount-balance="amountBalance"
      @setIntervalGetNoti="setIntervalGetNoti"
    />
  </div>
</template>
<script>
import {
  BImg,
  BButton,
  BRow,
  BCol,
  VBTooltip,
} from 'bootstrap-vue'
import UserHint from '@/components/UserHint.vue'
import generalConfigsMixin from '@/mixins/generalConfigsMixin'
import { toastification } from '@core/mixins/toast'
import stepGuideMixin from '@/mixins/stepGuideMixin'
import { createNamespacedHelpers } from 'vuex'
import TopUpSuccessModal from '@/views/payment/components/LocalBank/TopUpSuccessModal.vue'
import numberFormatMixin from '@/mixins/numberFormatMixin'

const { mapGetters: mapGettersAuth, mapActions: mapActionsAuth } = createNamespacedHelpers('auth')
const { mapGetters, mapActions } = createNamespacedHelpers('payment')

export default {
  components: {
    TopUpSuccessModal,
    BImg,
    BButton,
    BRow,
    BCol,
    UserHint,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [generalConfigsMixin, toastification, stepGuideMixin, numberFormatMixin],
  props: {
    paymentSelected: {
      type: String,
      default: '',
    },

    urlQRCode: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      paymentInfo: {},
    }
  },

  computed: {
    ...mapGetters(['listLocalBank', 'listRecentNotification', 'loading', 'status', 'message']),
    ...mapGettersAuth(['user']),

    topUpAmount() {
      return this.amountFormat(this.listRecentNotification.data?.[0].amount) || 0
    },

    amountBalance() {
      return this.user?.data?.balance
    },
  },

  watch: {
    listLocalBank: {
      handler(val) {
        if (val) {
          this.paymentInfo = this.listLocalBank.find(item => item.code === this.paymentSelected)
          this.$emit('handleGetQRCode')
        }
      },
      deep: true,
      immediate: true,
    },
  },

  created() {
    this.fetchRecentNoti()
    this.setIntervalGetNoti()
    this.$emit('handle-get-list-local-bank')
  },

  beforeDestroy() {
    clearInterval(this.fetchTopUpSuccess)
  },

  methods: {
    ...mapActions(['getListLocalBank', 'recentLocalBankNotification']),
    ...mapActionsAuth(['getAccountInfo']),

    async fetchRecentNoti() {
      await this.recentLocalBankNotification()
      if (this.status) {
        if (Array.isArray(this.listRecentNotification.data) && this.listRecentNotification.data.length > 0) {
          this.$refs['open-top-up-success-modal'].showModal()
          this.$emit('handle-get-list-local-bank')
          this.getAccountInfo()
          clearInterval(this.fetchTopUpSuccess)
        }
      }
    },

    setIntervalGetNoti() {
      this.fetchTopUpSuccess = setInterval(async () => {
        await this.fetchRecentNoti()
      }, 5000)
    },

    handleCancel() {
      this.$emit('handle-prev')
    },
  },
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/variables/_variables.scss";
.payment-container, .payment-container-step {
  border-radius: var(--border-radius-base);
  padding: 24px 0;
  margin: 0 auto;

  .payment-info {
    border: 1px solid #ededed;
    border-radius: 20px;
  }
}

.payment-container-step {
  width: 100%;
}

.payment-container {
  max-width: 850px;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.btn-amount {
  padding: 6px 12px;
  margin-right: 10px;
  margin-top: 10px;

  border: 1px solid #e0e0e5;
  background-color: white;
  border-radius: 6px;

  cursor: pointer;

  color: #3d405b;
  font-size: 14px;

  &:hover,
  &.active {
    border: 1px solid $primary;
    color: $primary;
  }

  &:last-child {
    margin-right: 0;
  }

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
}

.form-label {
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: 600;
}

.info-box {
  position: absolute;
  top: -23px;
  right: 3px;
  font-size: .875rem;
  .icon-info{
    margin-left: 4px;
  }
}

.btn-back {
  color: rgb(22 33 62 / 76%) !important;
  background-color: rgba(0, 0, 0, 0.05) !important;
  padding: 11px 20px;
  height: max-content;
}

.warning-message {
  background: rgba(251, 176, 59, 0.1);
  border-radius: 10px;
  padding: 10px 12px 10px 16px;
}

.text-description {
  border: 2px solid #4cb375;
  padding: 4px 8px;
  border-radius: 6px;
  width: max-content;
  margin-left: 4px;
}

.border-btn-copy {
  border: 1px solid;
  padding: 8px 10px;
  border-radius: 4px;
  margin-left: 2px;
}

.border-btn-copy:hover, .border-text-copy:hover {
  background-color: rgba(40, 199, 111, 0.15) !important;
}

.border-text-copy {
  border: 1px solid;
  padding: 4px 6px;
  border-radius: 4px;
}

.list-btn-refresh {
  text-align: end;
}

.text-warning-refresh {
  line-height: 24px;
}

.margin-right-4 {
  margin-right: 4px;
}

.content-step-detail {
  @media (min-width: 1768px) {
    display: flex;
  }
}

.top-up-success-container {
  border-radius: var(--border-radius-base);
  max-width: 375px;
  margin: 0 auto;
  text-align: center;
}
</style>
