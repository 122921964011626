<template>
  <div
    class="p-2"
    :class="hasStepGuide ? 'top-up-on-step' : 'top-up-form-container'"
  >
    <!--Top up form-->
    <div class="add-fund-form">
      <validation-observer
        v-slot="{ invalid }"
        ref="amountValidation"
      >
        <b-form
          class="amount-form"
        >
          <div class="d-block d-md-flex justify-content-between align-items-center">
            <h3>{{ $t('stepGuideControl.textAddFund') }}</h3>
          </div>
          <p>{{ $t('payment.creditCard.textEnterAmount') }}</p>
          <b-form-group
            :label="$t('balance.textAmount')"
            class="position-relative form-label"
          >
            <validation-provider
              #default="{ errors }"
              id="amount"
              :name="$t('balance.textAmount')"
              :rules="`required|integerNumber|greaterThanZero|rangeTopUp:${minTopUp}|availableBalance:${currentUsdBalance},${percentFee}, ${taxRate}`"
            >
              <b-input-group
                class="input-group-merge width-custom w-100"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-input-group-prepend is-text>
                  <feather-icon icon="DollarSignIcon" />
                </b-input-group-prepend>
                <b-form-input
                  v-model.number="currentPaymentAmount.value"
                  id="input-amount-po"
                  data-testid="input-amount-po"
                  class="input-height"
                  autofocus
                  type="number"
                  placeholder="EX: 2500"
                  :state="errors.length > 0 ? false : null"
                  @input="onChangeCurrentPaymentAmount"
                />
                <b-input-group-append
                  v-if="currentPaymentAmount.value === maxAmount"
                  is-text
                >
                  <img
                    :src="require('@/assets/images/pages/payment/ic-max.svg')"
                    alt="image"
                  >
                </b-input-group-append>
              </b-input-group>
              <small
                v-if="errors[0]"
                id="error-amount-payoneer"
                data-testid="error-amount-payoneer"
                class="text-danger"
              >
                {{ errors[0] }}
              </small>
              <small
                v-if="!errors[0] && currentPaymentAmount.value"
                id="amount-valid-payoneer"
                data-testid="amount-valid-payoneer"
                class="d-flex align-items-center"
                style="margin-top: 10px"
              >
                <b-img
                  class="icons-tick"
                  fluid
                  style="margin-right: 5px"
                  :src="require('@/assets/images/icons/icon-tick.svg')"
                  alt="guide"
                />
                {{ $t('payment.textThisAmountIsSuitable') }}
              </small>
              <div class="info-box d-flex align-items-center">
                <feather-icon
                  v-b-tooltip.v-light="{id: 'tooltip-payoneer-limit-amount', title: `${$t('payment.creditCard.textAvailableLimit')}: $100,000`}"
                  icon="HelpCircleIcon"
                  size="12"
                  class="icon-info"
                />
              </div>
            </validation-provider>
          </b-form-group>
        </b-form>

        <div
          class="
          d-flex
          align-items-md-center
          mt-1
          max-width
          flex-wrap
          justify-content-between
        "
        >
          <top-up-amount-item
            v-for="(item, index) in amountItem"
            :key="index"
            :id="`amount-${item.value}`"
            :item="item"
            :index="index"
            :is-active="currentPaymentAmount.value === item.value"
            @choosePaymentAmount="choosePaymentAmount"
          />
        </div>

        <div
          v-if="hasAMId && tipMode"
          class="mt-2"
        >
          <b-form-checkbox
            v-model="showTipsForAM"
            :aria-expanded="showTipsForAM ? 'true' : 'false'"
            aria-controls="collapse-1-inner"
            data-testid="checkbox-show-tips-input"
            @input="handleShowTipsInput"
          >
            <span class="cursor-pointer">{{ $t('payment.txtCheckboxShowTipsForAM') }}</span>
          </b-form-checkbox>
        </div>

        <b-collapse
          v-model="showTipsForAM"
          id="collapse-1-inner"
          class="mt-2"
        >
          <b-form-group
            class="position-relative form-label"
            label-for="tip-amount"
          >
            <validation-provider
              #default="{ errors }"
              id="tip"
              name="tip"
              :rules="{
                positive,
                rangeTipAmount: { min: minTipAmount },
              }"
            >
              <b-input-group
                class="input-group-merge width-custom w-100"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-input-group-prepend is-text>
                  <feather-icon icon="DollarSignIcon" />
                </b-input-group-prepend>
                <cleave
                  v-model.number="tipAMAmount"
                  id="tip-amount-po"
                  data-testid="tip-amount-po"
                  class="form-control"
                  :placeholder="$t('payment.creditCard.placeholderTip')"
                  :options="cleaveOptions"
                  :state="errors.length > 0 ? false : null"
                  @keyup.native="onChangeTipAmount"
                />
              </b-input-group>
              <small
                v-if="errors[0]"
                id="error-tip-amount-payoneer"
                data-testid="error-tip-amount-payoneer"
                class="text-danger"
              >
                {{ errors[0] }}
              </small>
            </validation-provider>
          </b-form-group>
          <div
            class="d-flex align-items-md-center flex-wrap max-width"
          >
            <div
              v-for="(item, index) in tipItem"
              :key="index"
              class="btn-tip"
              :class="{ active: currentTipIndex === index }"
              @click="chooseTipAmount({item, index})"
            >
              <span>{{ item.value === $t('payment.textNoTip') ? item.value : `${item.value}%` }}</span>
            </div>
          </div>
        </b-collapse>

        <div class="d-flex justify-content-end">
          <btn-loading
            id="trigger-top-up-po"
            span-id="trigger-top-up-po"
            class="mt-2 btn-height btn-text-color"
            type="submit"
            variant-convert="btn-submit"
            pill
            :disabled="invalid"
            :loading="loading || isLoading"
            @click="submitForm"
          >
            {{ $t('payment.creditCard.textTopUp') }}
          </btn-loading>
        </div>
      </validation-observer>
    </div>
    <!-- /Top up form-->

    <!-- Horizontal-divide -->
    <div class="line-center">
      <div class="horizontal-divide" />
    </div>
    <!-- /Horizontal-divide-->

    <!-- Card summary -->
    <div class="summary-form">
      <payoneer-summary
        :amount="currentPaymentAmount.value || 0"
        :amount-tip="tipAMAmount || 0"
        :current-tip="currentTip || 0"
        :show-tips-input="showTipsForAM"
      />
    </div>
    <!-- /Card summary-->
  </div>
</template>
<script>
// Bootstrap
import {
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormGroup,
  BForm,
  BImg,
  VBTooltip,
  BInputGroupAppend,
  BFormCheckbox,
  BCollapse,
  VBToggle,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'

import { PAYMENT_CHILD_TYPE, PAYMENT_METHOD, PERCENT_TIP_AMOUNT } from '@/constants'

// validation
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  greaterThanZero,
  required,
  rangeTopUp,
  availableBalance,
  integerNumber,
  rangeTipAmount,
  positive,
} from '@validations'

// mixins
import generalConfigsMixin from '@/mixins/generalConfigsMixin'
import stepGuideMixin from '@/mixins/stepGuideMixin'
import { toastification } from '@core/mixins/toast'
import billingMixin from '@/mixins/billingMixin'
import paymentMixin from '@/mixins/paymentMixin'
import gaTrackingMixin from '@/mixins/gaTrackingMixin'
import envMixin from '@/mixins/envMixin'

// plugins
import { createNamespacedHelpers } from 'vuex'

// components
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import Ripple from 'vue-ripple-directive'

import _find from 'lodash/find'
import get from 'lodash/get'
import TopUpAmountItem from './TopUpAmountItem.vue'
import PayoneerSummary from './PayoneerSummary.vue'

const {
  mapActions,
  mapState,
  mapGetters,
} = createNamespacedHelpers('payment')
const {
  mapGetters: mapGettersAuth,
  mapActions: mapActionsAuth,
} = createNamespacedHelpers('auth')
const { mapGetters: mapGettersAds } = createNamespacedHelpers('adsAccount')

export default {
  components: {
    // components
    PayoneerSummary,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BForm,
    BFormGroup,
    BImg,
    BtnLoading,
    BInputGroupAppend,
    TopUpAmountItem,
    BFormCheckbox,
    BCollapse,
    // validation
    ValidationProvider,
    ValidationObserver,
    Cleave,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
    Ripple,
  },
  mixins: [
    toastification,
    generalConfigsMixin,
    billingMixin,
    paymentMixin,
    stepGuideMixin,
    gaTrackingMixin,
    envMixin,
  ],
  props: {},

  data() {
    return {
      showTipsForAM: false,
      tipItem: PERCENT_TIP_AMOUNT,
      currentPaymentAmount: {
        value: null,
        type: PAYMENT_CHILD_TYPE.NUMBER,
      },
      // validation
      required,
      greaterThanZero,
      rangeTopUp,
      availableBalance,
      integerNumber,
      rangeTipAmount,
      positive,

      adAccount: {},
      // isToggle: true,
      isLoading: false,

      currentTipIndex: null,
      currentTip: null,
      tipAMAmount: null,

      cleaveOptions: {
        numeral: true,
        numeralPositiveOnly: true,
        numeralThousandsGroupStyle: 'none',
      },
      minTipAmount: 0,
    }
  },
  computed: {
    ...mapGetters(['message', 'status', 'loading', 'payoneerAccount']),
    ...mapGettersAuth(['user']),
    ...mapState({
      request: state => state.payoneerAccount.request,
      currentAccounts: state => state.payoneerAccount.currentAccounts,
    }),
    ...mapGettersAds(['listAccount']),

    maxAmount() {
      return 100000
    },

    currentUsdBalance() {
      return _find(this.currentAccounts, n => n.currency === 'USD')
        ?.available_balance
    },

    percentFee() {
      return this.user?.data?.plans?.transferFee?.payoneer || 0
    },

    listAccounts() {
      return get(this.listAccount, ['content'], [])
    },

    checkHasNextBtn() {
      return this.hasStepGuide && this.user?.data?.balance > 0
    },
  },

  watch: {
    request: {
      async handler(newRequest) {
        const { type } = this.$route?.query
        // eslint-disable-next-line no-underscore-dangle
        if (newRequest && newRequest._id !== '' && type !== 'recharge') {
          const { _id } = newRequest
          const params2 = {
            transactionId: _id,
          }
          if (_id) {
            try {
              this.isLoading = true
              await this.confirmPayoneerPayment(params2)
              // If there is MFA redirect to PO to confirm
              if (this.status) {
                const { redirectUrl } = this.payoneerAccount.confirm
                const checkUrl = redirectUrl.toLowerCase().includes('/payment/success')
                if (checkUrl && this.hasStepGuide) {
                  this.getAccountInfo()
                  this.toastSuccess(this.$t('payment.toastTopUpSuccess'))
                  this.$router.push({ name: 'my-ads-account' })
                  this.isLoading = false
                } else {
                  window.location.href = redirectUrl
                }
              }
            } catch (e) {
              this.isLoading = false
            }
          }
        }
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    ...mapActions([
      'requestPayoneerPayment',
      'confirmPayoneerPayment',
      'createAdsRecharge',
    ]),
    ...mapActionsAuth(['getAccountInfo']),

    handleShowTipsInput() {
      if (!this.showTipsForAM) {
        this.tipAMAmount = null
        this.onChangeTipAmount()
      }
    },

    onChangeTipAmount() {
      this.currentTipIndex = null
      this.currentTip = null
    },

    onChangeCurrentPaymentAmount() {
      if (this.currentTip) {
        this.tipAMAmount = ((this.currentTip * this.currentPaymentAmount.value) / 100)
      }
    },

    choosePaymentAmount(data) {
      this.currentPaymentAmount = { ...data.item }

      this.onChangeCurrentPaymentAmount()

      this.$nextTick(() => {
        this.$refs.amountValidation.validate()
      })
    },

    chooseTipAmount(data) {
      this.currentTip = data.item.value === this.$t('payment.textNoTip') ? null : data.item.value
      this.tipAMAmount = ((this.currentTip * this.currentPaymentAmount.value) / 100)
      this.currentTipIndex = data.index
    },
    // async submitForm() {
    //   if (this.noPlan) {
    //     this.$router.push('/pricing')
    //   } else {
    //     const success = await this.$refs.amountValidation.validate()
    //     if (success) {
    //       const params = {
    //         amount: this.currentPaymentAmount.value,
    //         ...(this.isToggle && this.adAccount && { adsAccountId: this.adAccount.id }),
    //       }
    //       // TODO: Reduce the post to 3 call API here
    //       try {
    //         await this.requestPayoneerPayment(params)
    //         if (this.status) {
    //           this.toastSuccess(
    //             'Create the payment request success. Please wait for Payoneer to confirm.',
    //           )
    //         } else {
    //           this.toastFailure(this.message)
    //         }
    //       } catch (error) {
    //         // { "status": 400, "code": "error.payoneer_account_has_reach_limit", "message": "Your Payoneer account has reached limit on paying." }
    //         this.toastFailure(error.message)
    //       }
    //     }
    //   }
    // },
    async submitForm() {
      if (this.noPlan) {
        this.$router.push('/billing')
      } else {
        const success = await this.$refs.amountValidation.validate()
        if (success) {
          const params = {
            amount: this.currentPaymentAmount.value,
            tipAMAmount: this.tipAMAmount,
            // ...(this.isToggle
            //   && this.adAccount && { adsAccountId: this.adAccount?.value }),
          }
          // TODO: Reduce the post to 3 call API here
          await this.requestPayoneerPayment(params)
          if (this.isOnlyEcomdyPlatform) {
            this.gaTrackingUserAddFund(params.amount, PAYMENT_METHOD.PAYONEER) // GA Tracking
          }
          if (!this.status) {
            this.toastFailure(this.message)
          }
        }
      }
    },

    // handleSelectAdAccount(data) {
    //   this.adAccount = data.account
    //   this.isToggle = data.isToggle
    // },
  },
}
</script>
<style lang="scss">
.is-invalid {
  #tip-amount:not(:focus) {
    border-color: #e11c4e !important;
  }
}
</style>

<style lang="scss" scoped>
@import "@/assets/scss/variables/_variables.scss";

.top-up-on-step {
  .line-center {
    display: none;
  }
}

.top-up-form-container {
  display: flex;

  .add-fund-form {
    max-width: 50%;
  }

  .line-center {
    margin: 0 1.5rem;
  }

  .summary-form {
    width: 50%;
  }

  @media (max-width: 1399px) {
    display: block;

    .add-fund-form {
      max-width: 100%;
    }

    .line-center {
      display: none;
    }

    .summary-form {
      width: 100%;
    }
  }
}

.horizontal-divide {
  width: 1px;
  height: 100%;
  background: #eeeeee;
}

.max-width {
  max-width: 410px;
}

.input-height {
  height: 50px;
}

.mr-mb {
  margin-right: 10px;
}

.width-custom {
  width: 63%;
  @media (max-width: 767px) {
    width: 100%;
  }
}

.info-box {
  position: absolute;
  top: -23px;
  right: 3px;
  font-size: 0.875rem;

  .icon-info {
    margin-left: 4px;
  }
}

.form-label {
  font-size: 12px;
  font-weight: 600;
}

.btn-tip {
  border: 1px solid #e0e0e5;
  border-radius: 6px;
  background-color: white;

  cursor: pointer;

  color: #3d405b;
  font-size: 14px;

  padding: 6px 12px;
  margin-right: 10px;
  margin-top: 10px;

  &:hover,
  &.active {
    border: 1px solid $primary;
    color: $primary;
    font-weight: 600;
  }

  &:last-child {
    margin-right: 0;
  }

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
}
</style>
