<template>
  <div>
    <div
      v-if="showSuccessPage"
      class="top-up-success"
    >
      <top-up-success
        :amount="amount"
        :transfer="transfer"
        :show-success-page.sync="showSuccessPage"
        :payment-method="paymentMethod"
      />
    </div>
    <div v-else>
      <div class="bg-white list-card p-2">
        <div>
          <h4 class="mb-2">
            {{ $t('payment.wiseTransfer.titleFollowInstruction') }}
          </h4>
          <div class="mt-2 d-flex">
            <div class="step-number">
              1
            </div>
            <p class="font-14 font-weight-600">
              {{ $t('payment.wiseTransfer.contentInstructionWise') }}
            </p>
          </div>
          <div class="detail-account">
            <b-row class="title-details">
              <b-col class="p-0 d-flex align-items-center">
                <h2 class="m-0">
                  {{ $t('payment.wiseTransfer.titleTransferDetails') }}
                </h2>
              </b-col>
              <b-col
                md="6"
                class="d-flex align-items-center justify-content-end p-0"
              >
                <p class="font-24 m-0">
                  {{ $t('addFundPage.creditCardPayment.textTotalAmount') }}:
                </p>
                <strong
                  class="font-24 total-amount ml-1"
                >
                  {{ totalAmount }} USD
                </strong>
              </b-col>
            </b-row>
            <div class="mb-2">
              <b-row class="content-account m-0">
                <b-col
                  md="6"
                  class="p-0"
                >
                  <h4 class="title-content">
                    {{ $t('payment.wiseTransfer.titleAccountHolder', { paymentMethod: 'Wise'}) }}
                  </h4>
                </b-col>
                <b-col
                  md="6"
                  class="d-flex align-items-center justify-content-end p-0"
                >
                  <p class="m-0">
                    {{ paymentMethod.accountHolder }}
                  </p>
                  <feather-icon
                    icon="CopyIcon"
                    size="20"
                    class="cursor-pointer icon-copy"
                    @click="handleCopy(paymentMethod.accountHolder)"
                  />
                </b-col>
              </b-row>
            </div>
            <div class="mb-2">
              <b-row class="content-account m-0">
                <b-col
                  md="6"
                  class="p-0"
                >
                  <h4 class="title-content">
                    {{ $t('payment.wiseTransfer.titleRoutingNumber') }}
                  </h4>
                </b-col>
                <b-col
                  md="6"
                  class="d-flex align-items-center justify-content-end p-0"
                >
                  <p class="m-0">
                    {{ paymentMethod.routingNumber }}
                  </p>
                  <feather-icon
                    icon="CopyIcon"
                    size="20"
                    class="cursor-pointer icon-copy"
                    @click="handleCopy(paymentMethod.routingNumber)"
                  />
                </b-col>
              </b-row>
            </div>
            <div class="mb-2">
              <b-row class="content-account m-0">
                <b-col
                  md="6"
                  class="p-0"
                >
                  <h4 class="title-content">
                    {{ $t('payment.wiseTransfer.textBankAccountNumber') }}
                  </h4>
                </b-col>
                <b-col
                  md="6"
                  class="d-flex align-items-center justify-content-end p-0"
                >
                  <p class="m-0">
                    {{ paymentMethod.bankAccountNumber }}
                  </p>
                  <feather-icon
                    icon="CopyIcon"
                    size="20"
                    class="cursor-pointer icon-copy"
                    @click="handleCopy(paymentMethod.bankAccountNumber)"
                  />
                </b-col>
              </b-row>
            </div>
            <div class="mb-2">
              <b-row class="content-account m-0">
                <b-col
                  md="6"
                  class="p-0"
                >
                  <h4 class="title-content">
                    {{ $t('payment.wiseTransfer.titleAccountType') }}
                  </h4>
                </b-col>
                <b-col
                  md="6"
                  class="d-flex align-items-center justify-content-end p-0"
                >
                  <p class="m-0">
                    {{ paymentMethod.accountType }}
                  </p>
                  <feather-icon
                    icon="CopyIcon"
                    size="20"
                    class="cursor-pointer icon-copy"
                    @click="handleCopy(paymentMethod.accountType)"
                  />
                </b-col>
              </b-row>
            </div>
            <div class="mb-2">
              <b-row class="content-account m-0">
                <b-col
                  md="6"
                  class="p-0"
                >
                  <h4 class="title-content">
                    {{ $t('payment.wiseTransfer.titleWiseAddress', { platformName: platformName }) }}
                  </h4>
                </b-col>
                <b-col
                  md="6"
                  class="d-flex align-items-center justify-content-end p-0"
                >
                  <div>
                    <p class="m-0 text-right">
                      {{ paymentMethod.bankAddress }}
                    </p>
                  </div>
                  <div style="width: max-content">
                    <feather-icon
                      icon="CopyIcon"
                      size="20"
                      class="cursor-pointer icon-copy"
                      @click="handleCopy(paymentMethod.bankAddress)"
                    />
                  </div>
                </b-col>
              </b-row>
            </div>
            <div class="mb-2">
              <b-row class="content-account m-0">
                <b-col
                  md="6"
                  class="p-0"
                >
                  <h4 class="title-content">
                    {{ $t('common.labelEmail') }}
                  </h4>
                </b-col>
                <b-col
                  md="6"
                  class="d-flex align-items-center justify-content-end p-0"
                >
                  <p class="m-0">
                    {{ paymentMethod.email }}
                  </p>
                  <feather-icon
                    icon="CopyIcon"
                    size="20"
                    class="cursor-pointer icon-copy"
                    @click="handleCopy(paymentMethod.email)"
                  />
                </b-col>
              </b-row>
            </div>
          </div>
          <div class="mt-2">
            <div class="mt-2 d-flex">
              <div class="step-number">
                2
              </div>
              <p class="font-14 font-weight-600">
                {{ $t('payment.wiseTransfer.secondContentInstructionWise') }}
              </p>
            </div>
            <div :class="hasStepGuide ? 'top-up-on-step' : 'top-up-form-container'">
              <div
                class="add-fund-form"
              >
                <validation-observer ref="formValidation">
                  <b-form class="transactions-form">
                    <b-form-group
                      :label="$t('payment.wiseTransfer.labelIDTransactions')"
                      class="position-relative form-label"
                    >
                      <validation-provider
                        #default="{ errors }"
                        id="transactions"
                        name="transactions"
                        rules="required"
                      >
                        <b-input-group
                          class="input-group-merge width-custom w-100"
                          :class="errors.length > 0 ? 'is-invalid' : null"
                        >
                          <b-form-input
                            v-model="transactionId"
                            class="input-height"
                            placeholder="EX: 123456789"
                            :state="errors.length > 0 ? false : null"
                          />
                        </b-input-group>
                        <small
                          v-if="errors[0]"
                          class="text-danger"
                        >
                          {{ errors[0] }}
                        </small>
                      </validation-provider>

                      <div class="mt-2">
                        <p
                          class="d-flex align-items-center upload"
                          @click="$refs.refImage.$el.click()"
                        >
                          <b-img
                            fluid
                            alt="img"
                            :src="
                              require('@/assets/images/pages/payment/ic-attachment-white.svg')
                            "
                          />
                          <span>{{ $t('payment.wiseTransfer.placeHolderUploadImg') }}
                            <span class="font-14">(PDF, JPG, PNG)</span></span>
                        </p>
                        <b-form-file
                          v-model="imageFile"
                          ref="refImage"
                          plain
                          accept=".jpg, .png, .pdf"
                          :hidden="true"
                          @input="inputImageRenderer"
                        />

                        <p
                          v-if="fileUrl && imageFile && imageFile.name"
                          class="form-label font-small-2 text-success"
                          style="margin-top: 5px"
                        >
                          <span>{{
                            `${$t('payment.wiseTransfer.textUploaded')} ${
                              imageFile.name || fileUrl.name
                            } ${$t('payment.wiseTransfer.textUploaded')}!`
                          }}</span>
                        </p>

                        <small
                          v-if="imageError"
                          class="text-danger"
                        >
                          {{ $t('payment.wiseTransfer.errorRequiredAttachment') }}
                        </small>
                      </div>

                      <slide-step
                        class="mt-2"
                        :step="2"
                      />
                      <div
                        class="
                          d-flex
                          justify-content-between
                          font-14 font-medium
                          mb-2
                        "
                      >
                        <span>{{ $t('payment.wiseTransfer.placeHolderUploadImg') }}</span>
                        <span>2/2</span>
                      </div>

                      <div
                        class="
                          d-flex
                          align-items-center
                          justify-content-end
                          mt-2
                        "
                      >
                        <b-button
                          id="trigger-back"
                          class="mr-1 border-0 btn-back"
                          variant="secondary"
                          pill
                          @click.prevent="handleClickPrev"
                        >
                          {{ $t('stepGuideControl.stepVerifyInfo.textBack') }}
                        </b-button>
                        <btn-loading
                          id="trigger-top-up-wise"
                          span-id="trigger-top-up-wise"
                          class="btn-height btn-text-color"
                          variant-convert="btn-submit"
                          pill
                          :loading="loading"
                          @click.prevent="submit"
                        >
                          {{ $t('guidePopUp.guideTopUpWise.textDone') }}
                        </btn-loading>
                      </div>
                    </b-form-group>
                  </b-form>
                </validation-observer>
              </div>
              <div class="line-center">
                <div class="horizontal-divide" />
              </div>

              <div class="summary-form">
                <summary-amount
                  :amount="amount"
                  :payment-method="paymentMethod"
                  :transfer="transfer"
                  :amount-tip="amountTip || 0"
                  :current-tip="currentTip || 0"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  VBTooltip,
  BButton,
  BFormInput,
  BInputGroup,
  BFormGroup,
  BForm,
  BImg,
  BFormFile,
} from 'bootstrap-vue'
import BtnLoading from '@/layouts/components/BtnLoading.vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'

import paymentMixin from '@/mixins/paymentMixin'
import stepGuideMixin from '@/mixins/stepGuideMixin'
import generalConfigsMixin from '@/mixins/generalConfigsMixin'
import numberFormatMixin from '@/mixins/numberFormatMixin'
import gaTrackingMixin from '@/mixins/gaTrackingMixin'
import envMixin from '@/mixins/envMixin'
import { toastification } from '@core/mixins/toast'
import { createNamespacedHelpers } from 'vuex'

import { ref } from '@vue/composition-api'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { PAYMENT_METHOD, TICKET_TYPE } from '@/constants'
import SummaryAmount from './Summary.vue'
import TopUpSuccess from './TopUpSuccess.vue'
import SlideStep from '../SlideStep.vue'

const { mapGetters: mapGettersAuth } = createNamespacedHelpers('auth')
const { mapGetters, mapActions } = createNamespacedHelpers('file')
const { mapActions: mapPaymentActions, mapGetters: mapPaymentGetters } = createNamespacedHelpers('payment')

export default {
  components: {
    BRow,
    BCol,
    BtnLoading,
    BButton,
    BFormInput,
    BInputGroup,
    BFormGroup,
    BImg,
    BFormFile,
    BForm,
    ValidationProvider,
    ValidationObserver,
    SummaryAmount,
    TopUpSuccess,
    SlideStep,
  },

  directives: {
    'b-tooltip': VBTooltip,
  },

  mixins: [
    paymentMixin,
    generalConfigsMixin,
    numberFormatMixin,
    toastification,
    stepGuideMixin,
    gaTrackingMixin,
    envMixin,
  ],

  props: {
    paymentMethod: {
      type: Object,
      required: false,
      default() {
        return {}
      },
    },

    transfer: {
      type: Object,
      required: false,
      default() {
        return {}
      },
    },

    amount: {
      type: Number,
      required: true,
    },

    account: {
      type: Object,
      required: true,
    },

    // isToggle: {
    //   type: Boolean,
    //   required: true,
    // },

    transactionInfo: {
      type: String,
      required: true,
    },

    billing: {
      type: [Array, File],
      required: false,
      default() {
        return []
      },
    },

    amountTip: {
      type: Number || null,
      default: null,
    },
    currentTip: {
      type: Number || null,
      default: null,
    },
  },

  setup() {
    const refImage = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refImage, () => {})

    return {
      refImage,
      inputImageRenderer,
    }
  },

  data() {
    return {
      transactionId: this.transactionInfo || '',
      imageFile: this.billing,
      imageError: false,
      showSuccessPage: false,
    }
  },

  computed: {
    ...mapGettersAuth(['user']),
    ...mapGetters(['status', 'fileUrl', 'loading', 'message']),
    ...mapPaymentGetters(['errorPayment']),

    isInTrial() {
      return this.user?.isInTrial
    },

    amountValidate() {
      return !!(this.amount >= this.minAmount && this.amount <= this.maxAmount)
    },

    minAmount() {
      return 200
    },

    maxAmount() {
      return 100000
    },

    serviceCharge() {
      // return parseFloat(this.truncDigits(this.percentFee * this.amount)).toLocaleString('en-US')
      return this.amountFormat(this.percentFee * this.amount).toLocaleString(
        'en-US',
      )
    },

    percentFee() {
      return this.user?.data?.plans?.transferFee?.transferWise || 0
    },

    percentFeeTxt() {
      return `${(this.percentFee * 100).toFixed(2)}%`
    },

    totalAmount() {
      const fee = this.amountFormat(this.percentFee * this.amount)
      const tax = this.amountFormat(this.taxRate * this.amount)
      // return parseFloat(this.truncDigits(this.amount + fee + tax)).toLocaleString('en-US')
      return (this.amount + fee + tax + this.amountTip).toLocaleString('en-US')
    },
  },

  watch: {
    imageFile(val) {
      if (val) {
        this.upload(val)
        this.imageError = false
      }
    },
  },

  methods: {
    ...mapActions(['uploadFile']),
    ...mapPaymentActions(['createRecharge', 'getListTicket']),

    async upload(file) {
      if (file) {
        await this.uploadFile(file)
        if (this.status) {
          this.toastSuccess('Upload file success!')
          this.$emit('set-url', this.fileUrl)
        } else {
          this.toastFailure(this.message)
        }
      }
    },

    handleClickPrev() {
      this.$emit('on-prev', {
        amount: this.amount,
        transactionId: this.transactionId,
        billing: this.imageFile,
        currentTip: this.currentTip,
        amountTip: this.amountTip,
      })
    },

    async fetchListTicket() {
      const params = {
        type: TICKET_TYPE.RECHARGE_USER_BALANCE,
        status: ['opening'],
      }
      await this.getListTicket({ params })
    },

    submit() {
      if (!this.fileUrl) {
        this.imageError = true
      }

      this.$refs.formValidation.validate().then(async success => {
        if (success && this.fileUrl) {
          const params = {
            type: TICKET_TYPE.RECHARGE_USER_BALANCE,
            details: {
              note: this.transactionId,
              amount: this.amount,
              paymentMethod: 'transferWise',
              coinType: this.paymentMethod.value,
              billingAttachment: this.fileUrl,
              coinNetwork: this.paymentMethod.value,
              tipAMAmount: this.amountTip,
              // ...(this.isToggle
              //   && this.account && { adsAccountId: this.account?.value }),
            },
          }

          await this.createRecharge(params)
          if (this.isOnlyEcomdyPlatform) {
            this.gaTrackingUserAddFund(params.details.amount, PAYMENT_METHOD.TRANSFERWISE) // GA Tracking
          }
          if (this.errorPayment.message) {
            this.toastFailure(this.errorPayment.message)
          } else {
            this.showSuccessPage = true
            this.fetchListTicket()
            this.$emit('handleShowSuccess', this.showSuccessPage)
          }
        }
      })
    },

    handleCopy(val) {
      window.navigator.clipboard
        .writeText(val)
        .then(() => {
          this.toastSuccess('Copy success!')
        })
        .catch(e => {
          this.toastFailure(e)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables/_variables.scss';
  .step-number{
    min-width: 20px;
    height: 20px;
    background: $green;
    border-radius: 50px;
    line-height: 20px;
    color: white;
    font-size: 12px;
    text-align: center;
    margin: 2px 5px 0px 0px;
  }

.content-step {
  margin: 24px 0;
}

.point {
  margin-bottom: 0;
  &::before {
    display: inline-block;
    content: "";
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #000;
    margin-right: 5px;
    margin-bottom: 3px;
  }
  &.warning {
    color: #f22548;
  }
}

.upload {
  cursor: pointer;
  background: #f5f5f7;
  border: 1px dashed rgba(0, 0, 0, 0.1);
  border-radius: 14px;
  padding: 32px 0px;
  justify-content: center;

  img {
    max-width: 15px;
    margin-right: 5px;
  }
}

.text-back {
  color: #828287;
  margin-bottom: 24px;
}

.top-up-success {
  max-width: 410px;
  margin: 0 auto;
  margin-top: 42px;
}

.detail-account {
  border-radius: 14px;
  border: 1px solid rgba(102, 102, 102, 0.1);
  margin: 0 auto;

  .title-details {
    margin: 0 auto 1rem;
    padding: 10px 20px;

    border-bottom: 1px solid rgba(102, 102, 102, 0.1);

    h2 {
      font-size: 1rem;
    }
  }

  .title-content {
    font-size: 16px;
    margin: 0;
  }

  .icon-copy {
    color: #e11c4e;
    margin-left: 10px;
  }

  .content-account {
    padding: 0 20px;
    align-items: center;
  }
}

.btn-back {
  color: rgb(22 33 62 / 76%) !important;
  background-color: rgba(0, 0, 0, 0.05) !important;
  padding: 11px 20px;
  font-size: 14px;
  font-weight: 600;
}

.top-up-on-step {
  .line-center {
    display: none;
  }
}

.top-up-form-container {
  display: flex;

  .add-fund-form {
    width: 50%;
  }

  .line-center {
    margin: 0 1.5rem;
  }

  .summary-form {
    width: 50%;
  }

  @media (max-width: 1399px) {
    display: block;

    .add-fund-form {
      width: 100%;
    }

    .line-center {
      display: none;
    }

    .summary-form {
      width: 100%;
    }
  }
}

.total-amount{
  color: #00B9FF;
}
</style>
