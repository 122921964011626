var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"bg-white px-2 pb-2 border-form",class:_vm.hasStepGuide ? 'radius-content' : 'pt-lg-2 py-2'},[_c('b-row',{staticClass:"align-items-end"},[_c('b-col',{attrs:{"xl":_vm.hasStepGuide ? 8 : 6,"md":_vm.hasStepGuide ? 12 : 6,"xs":"12"}},[_c('label',{staticClass:"font-14 mrb-10"},[_vm._v(_vm._s(_vm.$t('guidePopUp.guideTopUpUSDT.textSelectCoin')))]),_c('v-select',{staticClass:"coin-select select-info",attrs:{"data-testid":"usdt-method-coin-type","label":"name","options":_vm.coinTypes,"clearable":false},on:{"input":_vm.handleChangeCoin},scopedSlots:_vm._u([{key:"open-indicator",fn:function(ref){
var attributes = ref.attributes;
return [_c('span',_vm._b({},'span',attributes,false),[_c('feather-icon',{attrs:{"icon":"ChevronDownIcon","size":"20"}})],1)]}},{key:"selected-option",fn:function(ref){
var icon = ref.icon;
var name = ref.name;
var value = ref.value;
return [_c('img',{staticClass:"coin-spacing",attrs:{"alt":"coin","src":_vm.getBrandIcon(("" + icon))}}),_c('strong',{staticClass:"coin-spacing",attrs:{"data-testid":"selected-coin-type"}},[_vm._v(_vm._s(value))]),_c('span',{staticClass:"font-size-option",attrs:{"data-testid":"selected-coin-name"}},[_vm._v(_vm._s(name))])]}},{key:"option",fn:function(ref){
var icon = ref.icon;
var name = ref.name;
var value = ref.value;
return [_c('img',{staticClass:"coin-spacing",attrs:{"alt":"coin","src":_vm.getBrandIcon(("" + icon))}}),_c('strong',{staticClass:"coin-spacing",attrs:{"data-testid":"coin-type"}},[_vm._v(_vm._s(value))]),_c('span',{staticClass:"font-size-option",attrs:{"data-testid":"coin-name"}},[_vm._v(_vm._s(name))])]}}]),model:{value:(_vm.coinType),callback:function ($$v) {_vm.coinType=$$v},expression:"coinType"}})],1),_c('b-col',{class:_vm.hasStepGuide ? 'mt-1' : 'mt-1 mt-md-0',attrs:{"xl":_vm.hasStepGuide ? 8 : 6,"md":_vm.hasStepGuide ? 12 : 6,"xs":"12"}},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('label',{staticClass:"font-14 mrb-10"},[_vm._v(_vm._s(_vm.$t('payment.USDT.labelTransferNetwork')))]),(false)?_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-light",value:('Something here'),expression:"'Something here'",modifiers:{"v-light":true}}],staticClass:"icon-info",attrs:{"icon":"HelpCircleIcon","size":"12"}}):_vm._e()],1),_c('v-select',{staticClass:"transfer-option",attrs:{"data-testid":"usdt-transfer-network","label":"transfer","options":_vm.transferNetworks,"clearable":false},on:{"input":_vm.handleChangeTransferNetwork},scopedSlots:_vm._u([{key:"open-indicator",fn:function(ref){
var attributes = ref.attributes;
return [_c('span',_vm._b({},'span',attributes,false),[_c('feather-icon',{attrs:{"icon":"ChevronDownIcon","size":"20"}})],1)]}},{key:"selected-option",fn:function(ref){
var name = ref.name;
var value = ref.value;
return [_c('strong',{staticClass:"coin-spacing select-info",attrs:{"data-testid":"usdt-selected-transfer-network"}},[_vm._v(_vm._s(value))]),_c('span',{staticClass:"font-size-option",attrs:{"data-testid":"usdt-selected-transfer-network-note"}},[_vm._v(_vm._s(name))])]}},{key:"option",fn:function(ref){
var name = ref.name;
var value = ref.value;
return [_c('strong',{staticClass:"coin-spacing",attrs:{"data-testid":"usdt-transfer-network"}},[_vm._v(_vm._s(value))]),_c('span',{staticClass:"font-size-option",attrs:{"data-testid":"usdt-transfer-network-note"}},[_vm._v(_vm._s(name))])]}}]),model:{value:(_vm.transferNetwork),callback:function ($$v) {_vm.transferNetwork=$$v},expression:"transferNetwork"}})],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }