<template>
  <div
    class="btn-tip"
    :class="{'active': isActive}"
    @click="chooseTipAmount"
  >
    <span>{{ item.value === $t('payment.textNoTip') ? item.value : `${item.value}%` }}</span>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
  },

  methods: {
    chooseTipAmount() {
      this.$emit('chooseTipAmount', {
        percent: this.item.value,
        index: this.index,
      })
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables/variables';

.btn-tip {
  margin-top: 5px;
  margin-right: 10px;
  border: 1px solid #e0e0e5;
  border-radius: 6px;
  padding: 6px 12px;
  background-color: white;
  color: #3D405B;
  cursor: pointer;
  font-size: 14px;

  &:hover, &.active {
    border: 1px solid $primary;
    color: $primary;
    font-weight: 600;
  }

  &:last-child {
    margin-right: 0
  }

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }

}
</style>
