<template>
  <div>
    <div
      md="9"
      xs="12"
      class="bg-white px-2 pb-2 border-form"
      :class="hasStepGuide ? 'radius-content' : 'pt-3 pt-lg-2 py-2'"
    >
      <b-row>
        <b-col
          md="12"
          xs="12"
        >
          <label class="font-14 mrb-10">{{ $t('payment.wiseTransfer.labelSelectCountry') }}</label>
          <v-select
            v-model="coinType"
            class="coin-select select-info"
            label="name"
            :options="coinTypes"
            :clearable="false"
            @input="handleChangeCoin"
          >
            <template #open-indicator="{ attributes }">
              <span v-bind="attributes">
                <feather-icon
                  icon="ChevronDownIcon"
                  size="20"
                />
              </span>
            </template>

            <template #selected-option="{ icon, name, value }">
              <img
                class="coin-spacing"
                alt="flag"
                style="width: 26px;height: auto"
                :src="icon"
              >
              <strong class="coin-spacing">{{ name }}</strong> - {{ value }}
            </template>

            <template #option="{ icon, name, value }">
              <img
                class="coin-spacing"
                alt="flag"
                style="width: 26px;height: auto"
                :src="icon"
              >
              <strong>{{ name }}</strong> - {{ value }}
            </template>
          </v-select>
        </b-col>
        <b-col md="12">
          <div class="btn-register">
            <div class="icon">
              <img
                class="img-icon"
                alt="flag"
                :src="require('@/assets/images/pages/payment/ic-form-btn-register-wise.svg')"
              >
            </div>
            <div class="content-btn">
              <p>
                {{ $t('payment.wiseTransfer.contentIfDontHaveAccount') }}
              </p>
            </div>
            <div class="btn-airwallex">
              <a
                href="https://wise.com/invite/i/ducp122"
                target="_blank"
                class="content-btn-register"
              >
                <span>{{ $t('payment.wiseTransfer.btnRegisterWith') }}</span>
                <img
                  alt="logo-airwallex"
                  :src="require('@/assets/images/pages/payment/logo-wise.svg')"
                >
              </a>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { BRow, BCol, VBTooltip } from 'bootstrap-vue'
import vSelect from 'vue-select'
import paymentMixin from '@/mixins/paymentMixin'
import envMixin from '@/mixins/envMixin'
import stepGuideMixin from '@/mixins/stepGuideMixin'

export default {
  components: {
    BRow,
    BCol,
    vSelect,
  },

  directives: {
    'b-tooltip': VBTooltip,
  },

  mixins: [paymentMixin, envMixin, stepGuideMixin],

  props: {
    method: {
      type: Object,
      default: () => {},
      required: true,
    },

    coinTypes: {
      type: Array,
      default: () => [],
      required: true,
    },
  },

  data() {
    return {
      coinType: this.method,
    }
  },

  methods: {
    handleChangeCoin(value) {
      this.$emit('on-change', value)
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';

.coin-spacing {
  margin-right: 5px;
}

.coin-select {
  width: 368px;
}
.select-info {
  &:hover {
    background-color: #fff;
  }
}
</style>
<style lang="scss" scoped>
.radius-content {
  border-radius: 0px 0px var(--border-radius-base) var(--border-radius-base) !important;
  padding-top: 1rem;
  @media (max-width: 992px) {
    padding-top: 3rem;
  }
}

  .border-form{
    border-radius: var(--border-radius-base);
  }

.coin-select{
  @media (max-width: 767px) {
    width: 100%;
  }
}

   .btn-register{
    display: flex;
    align-items: center;
    justify-content: space-between;

    background: rgba(0, 185, 255, 0.1);
    border-radius: 12px;

    margin-top: 24px;
    padding: 12px;

    .icon{
      max-width: 40px;
      width: 100%;

      .img-icon{
        width: 100%;
      }
    }

    .content-btn{
      margin: 0 20px 0 12px;

      font-size: 14px;

      p{
        margin: 0;
      }

      @media (max-width: 767px) {
        margin: 12px 0;
      }
    }

    .btn-airwallex{

      .content-btn-register{
        color: #fff;
        background: #00B9FF;
        border-radius: 100px;

        width: max-content;

        display: flex;
        align-items: center;

        padding: 8px 16px;

        font-size: 14px;

        span{
          margin-right: 8px;
        }
      }

    }

    @media (max-width: 767px) {
      flex-direction: column;
    }
  }
</style>
