<template>
  <div class="summary-container mt-2">
    <h4
      id="title-summary-ideal"
      data-testid="title-summary-ideal"
      class="title-summary"
    >
      {{ $t('addFundPage.creditCardPayment.textSummary') }}
    </h4>
    <hr class="hr-custom">
    <div class="d-flex align-items-center justify-content-between mb-1">
      <div
        id="title-amount-ideal"
        data-testid="title-amount-ideal"
        class="font-14 text-placeholder"
      >
        {{ $t('balance.textAmount') }}
      </div>
      <strong
        v-if="amountValidate"
        id="ideal-current-amount"
        data-testid="ideal-current-amount"
      >
        {{ localStringFormat(amount) }} USD
      </strong>
      <strong v-else>
        --
      </strong>
    </div>
    <div class="d-flex align-items-center justify-content-between mb-1">
      <div
        id="title-ideal-method"
        data-testid="title-ideal-method"
        class="font-14 text-placeholder"
      >
        {{ $t('addFundPage.creditCardPayment.textPaymentMethod') }}
      </div>
      <div
        v-if="amount"
        id="ideal-method"
        data-testid="ideal-method"
      >
        <img
          :src="getBrandIcon(PAYMENT_METHOD.IDEAL)"
          width="18px"
          alt="coin"
          style="margin-right: 6px"
        >
        <strong id="payment-method">iDEAL</strong>
      </div>
      <strong v-else>
        --
      </strong>
    </div>

    <div class="align-items-center mb-1 d-flex flex-row justify-content-between">
      <div
        id="summany-ideal-title-account-holder-name"
        data-testid="summany-ideal-title-account-holder-name"
        class="font-14 text-placeholder"
      >
        {{ $t('setupCost.accountHolderName') }}:
      </div>

      <strong
        v-if="amount && userName"
        id="summary-ideal-account-holder-name"
        data-testid="summary-ideal-account-holder-name"
        class="font-14 text-primary"
      >
        {{ userName }}
      </strong>
      <strong v-else>
        --
      </strong>
    </div>

    <div
      v-if="percentFee"
      class="d-flex align-items-center justify-content-between mb-1"
    >
      <div
        id="title-service-fee-ideal"
        data-testid="title-service-fee-ideal"
        class="font-14 text-placeholder"
      >
        {{ $t('payment.balanceTransactionsTable.textServiceCharge') }}
        <small
          id="percent-service-ideal-fee"
          data-testid="percent-service-ideal-fee"
          class="font-small-1"
          style="margin-right: 6px"
        >
          {{ `(${percentFeeTxt})` }}
        </small>
      </div>
      <div>
        <div
          v-if="amountValidate"
          id="ideal-service-charge"
          data-testid="ideal-service-charge"
          class="d-flex align-items-center"
        >
          <strong
            id="ideal-current-service-charge"
            data-testid="ideal-current-service-charge"
            class="text-primary"
          >{{ serviceCharge }} USD</strong>
        </div>
        <strong v-else>
          --
        </strong>
      </div>
    </div>

    <div
      v-if="methodFee"
      class="d-flex align-items-center justify-content-between mb-1"
    >
      <div
        id="title-ideal-fee"
        data-testid="title-ideal-fee"
        class="font-14 text-placeholder"
      >
        {{ $t('payment.paypal.textPaymentFee', {payment:"iDEAL"}) }}
        <small
          id="percent-ideal-fee"
          data-testid="percent-ideal-fee"
          class="font-small-1"
          style="margin-right: 6px"
        >
          {{ `(${methodFeeTxt})` }}
        </small>
      </div>
      <div>
        <div
          v-if="amountValidate"
          id="ideal-fee"
          data-testid="ideal-fee"
          class="d-flex align-items-center"
        >
          <strong
            id="current-ideal-fee"
            data-testid="current-ideal-fee"
            class="text-primary"
          >{{ iDEALFee }} USD</strong>
        </div>
        <strong v-else>
          --
        </strong>
      </div>
    </div>

    <div
      v-if="taxRate"
      class="d-flex align-items-center justify-content-between mb-1"
    >
      <div
        id="title-tax-fee-ideal"
        data-testid="title-tax-fee-ideal"
        class="font-14 text-placeholder"
      >
        {{ $t('payment.balanceTransactionsTable.textTax') }}
        <small
          id="percent-tax-ideal-fee"
          data-testid="percent-tax-ideal-fee"
          class="font-small-1"
          style="margin-right: 6px"
        >
          {{ `(${percentTaxTxt})` }}
        </small>
      </div>
      <div>
        <div
          v-if="amountValidate"
          class="d-flex align-items-center"
        >
          <strong
            id="current-ideal-tax-fee"
            data-testid="current-ideal-tax-fee"
            class="text-primary"
          >{{ tax }} USD</strong>
        </div>
        <strong v-else>
          --
        </strong>
      </div>
    </div>

    <div
      v-if="hasAMId && tipMode"
      class="d-flex align-items-center justify-content-between"
    >
      <div
        id="title-tip-ideal"
        data-testid="title-tip-ideal"
        class="font-14 text-placeholder"
      >
        Tip
        <small
          v-if="currentTip > 0"
          id="percent-tip-ideal-fee"
          data-testid="percent-tip-ideal-fee"
          class="font-small-1"
          style="margin-right: 6px"
        >
          {{ `(${percentTipTxt})` }}
        </small>
      </div>
      <div>
        <div
          v-if="amountValidate"
          class="d-flex align-items-center"
        >
          <strong
            id="current-ideal-tip-amount"
            data-testid="current-ideal-tip-amount"
            class="text-primary"
          >{{ amountTipTxt }} USD</strong>
        </div>
        <strong v-else>
          --
        </strong>
      </div>
    </div>

    <hr class="hr-custom">
    <div class="d-flex align-items-center justify-content-between pb-3">
      <div
        id="title-total-amount-ideal"
        data-testid="title-total-amount-ideal"
        class="font-24 text-placeholder"
      >
        {{ $t('addFundPage.creditCardPayment.textTotalAmount') }}
      </div>
      <strong
        v-if="amountValidate"
        id="current-ideal-total-amount"
        data-testid="current-ideal-total-amount"
        class="font-24 total-amount"
      >
        {{ totalAmount }} USD
      </strong>
      <strong v-else>
        --
      </strong>
    </div>
  </div>
</template>

<script>
import paymentMixin from '@/mixins/paymentMixin'
import generalConfigsMixin from '@/mixins/generalConfigsMixin'
import numberFormatMixin from '@/mixins/numberFormatMixin'

// plugins
import { createNamespacedHelpers } from 'vuex'
import { PAYMENT_METHOD } from '@/constants'
import envMixin from '@/mixins/envMixin'

const { mapGetters: mapGettersAuth } = createNamespacedHelpers('auth')

export default {

  mixins: [paymentMixin, generalConfigsMixin, numberFormatMixin, envMixin],

  props: {
    amount: {
      type: Number,
      required: true,
    },

    userName: {
      type: String,
      required: true,
    },

    amountTip: {
      type: Number,
      required: true,
    },

    currentTip: {
      type: Number,
      required: true,
    },
  },

  computed: {
    PAYMENT_METHOD() {
      return PAYMENT_METHOD
    },
    ...mapGettersAuth(['user']),

    isInTrial() {
      return this.user?.isInTrial
    },

    percentFee() {
      return this.user?.data?.plans?.transferFee?.ideal || 0
    },

    percentFeeTxt() {
      return `${(this.percentFee * 100).toFixed(2)}%`
    },

    percentTaxTxt() {
      return `${(this.taxRate * 100).toFixed(2)}%`
    },

    methodFee() {
      return this.generalConfigs?.paymentGateFee?.idealGate || 0
    },

    methodFeeTxt() {
      return `${(this.methodFee * 100).toFixed(2)}%`
    },

    iDEALFee() {
      return this.amountFormat(this.methodFee * this.amount).toLocaleString('en-US')
    },

    tax() {
      // return parseFloat(this.truncDigits(this.taxRate * this.amount)).toLocaleString('en-US')
      return this.amountFormat(this.taxRate * this.amount).toLocaleString('en-US')
    },

    percentTipTxt() {
      return `${(this.currentTip).toFixed(2)}%`
    },

    amountTipTxt() {
      return this.amountFormat(this.amountTip).toLocaleString('en-US')
    },

    totalAmount() {
      const fee = this.amountFormat(this.percentFee * this.amount)
      const tax = this.amountFormat(this.taxRate * this.amount)
      const methodFee = this.amountFormat(this.methodFee * this.amount)
      // return parseFloat(this.truncDigits(this.amount + fee + tax)).toLocaleString('en-US')
      return (this.amount + fee + tax + methodFee + this.amountTip).toLocaleString('en-US')
    },

    serviceCharge() {
      // return parseFloat(this.truncDigits(this.percentFee * this.amount)).toLocaleString('en-US')
      return this.amountFormat(this.percentFee * this.amount).toLocaleString('en-US')
    },

    amountValidate() {
      return !!(this.amount >= this.minTopUp && this.amount <= this.maxAmount)
    },

    maxAmount() {
      return 100000
    },
  },
}
</script>

<style lang="scss" scoped>
.summary-container {
  padding: 20px;
  background-color: #F5F5F7;
  // border: 1px solid rgba(22, 33, 62, 0.1);
  border-radius: 16px;

  .title-summary{
    margin-bottom: 21px;
  }
}
  .transfer {
    max-width: 125px;
  }

.total-amount{
  color: #27a27a;
}
</style>
