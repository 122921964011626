<template>
  <div class="text-center">
    <div class="header px-2 pb-1">
      <img
        alt="success"
        :src="require('@/assets/images/pages/payment/ic-payment-success-2.svg')"
      >
      <h3
        :id="`text-topup-success-${paymentMethodName}`"
        :data-testid="`text-topup-success-${paymentMethodName}`"
        class="my-1"
      >
        {{ $t('addFundPage.creditCardPayment.textTopupSuccess') }}
      </h3>
      <p
        :id="`sub-title-topup-success-${paymentMethodName}`"
        :data-testid="`sub-title-topup-success-${paymentMethodName}`"
      >
        {{ $t('addFundPage.creditCardPayment.subTitlePaymentSuccess') }}
      </p>
    </div>
    <div
      id="form-summary-credit-card"
      data-testid="form-summary-credit-card"
      class="summary p-2"
    >
      <h4
        :id="`text-summary-${paymentMethodName}`"
        :data-testid="`text-summary-${paymentMethodName}`"
        class="text-left mb-2"
      >
        {{ $t('addFundPage.creditCardPayment.textSummary') }}
      </h4>
      <div
        :id="`client-${paymentMethodName}`"
        :data-testid="`client-${paymentMethodName}`"
        class="d-flex align-items-center justify-content-between mb-1 flex-wrap"
      >
        <div
          :id="`text-client-${paymentMethodName}`"
          :data-testid="`text-client-${paymentMethodName}`"
          class="font-14 text-placeholder"
        >
          {{ $t('addFundPage.creditCardPayment.textClient') }}
        </div>
        <strong
          :id="`client-name-${paymentMethodName}`"
          :data-testid="`client-name-${paymentMethodName}`"
        >
          {{ fullName }}
        </strong>
      </div>
      <div
        :id="`amount-section-${paymentMethodName}`"
        :data-testid="`amount-section-${paymentMethodName}`"
        class="d-flex align-items-center justify-content-between mb-1"
      >
        <div
          :id="`title-amount-${paymentMethodName}`"
          :data-testid="`title-amount-${paymentMethodName}`"
          class="font-14 text-placeholder"
        >
          {{ $t('balance.textAmount') }}
        </div>
        <strong
          :id="`amount-${paymentMethodName}`"
          :data-testid="`amount-${paymentMethodName}`"
        >
          {{ localStringFormat(amount) }} USD
        </strong>
      </div>
      <div
        :id="`payment-method-${paymentMethodName}`"
        :data-testid="`payment-method-${paymentMethodName}`"
        class="d-flex align-items-center justify-content-between mb-1"
      >
        <div
          :id="`title-payment-${paymentMethodName}`"
          :data-testid="`title-payment-${paymentMethodName}`"
          class="font-14 text-placeholder"
        >
          {{ $t('addFundPage.creditCardPayment.textPaymentMethod') }}
        </div>
        <div v-if="isCreditCard">
          <img
            :src="creditCardLogo"
            height="16px"
            alt="credit"
            style="margin-right: 5px"
          >
          <strong
            id="method-credit-card"
            data-testid="method-credit-card"
            class="font-14 text-primary"
          >
            {{ cardName }}
          </strong>
        </div>
        <div v-if="isPayPal">
          <img
            :src="require('@/assets/images/pages/payment/paypal.svg')"
            height="16px"
            alt="credit"
            style="margin-right: 5px"
          >
          <strong
            id="method-paypal"
            data-testid="method-paypal"
            class="font-14 text-primary"
          >
            Paypal
          </strong>
        </div>
      </div>

      <div
        v-if="percentFee"
        :id="`payment-section-${paymentMethodName}`"
        :data-testid="`payment-section-${paymentMethodName}`"
        class="d-flex align-items-center justify-content-between mb-1"
      >
        <div
          :id="`payment-service-${paymentMethodName}`"
          :data-testid="`payment-service-${paymentMethodName}`"
          class="font-14 text-placeholder"
        >
          {{ $t('payment.balanceTransactionsTable.textServiceCharge') }}
        </div>
        <strong
          :id="`service-charge-${paymentMethodName}`"
          :data-testid="`service-charge-${paymentMethodName}`"
          class="text-primary"
        >{{ serviceCharge }} USD</strong>
      </div>

      <div
        v-if="methodPayPalFee || methodCreditCardFee"
        :id="`payment-section-${paymentMethodName}`"
        :data-testid="`payment-section-${paymentMethodName}`"
        class="d-flex align-items-center justify-content-between mb-1"
      >
        <div
          :id="`payment-method-${paymentMethodName}`"
          :data-testid="`payment-method-${paymentMethodName}`"
          class="font-14 text-placeholder"
        >
          {{
            isPayPal ? `${ $t('payment.paypal.textPaymentFee', { payment: 'PayPal' }) }` : `${ $t('payment.creditCard.titleCreditCardFee') }`
          }}
        </div>
        <div>
          <div
            :id="`payment-details-${paymentMethodName}`"
            class="d-flex align-items-center"
            :data-testid="`payment-details-${paymentMethodName}`"
          >
            <strong
              :id="`method-${isPayPal ? 'paypal' : 'credit-card'}-fee`"
              :data-testid="`method-${isPayPal ? 'paypal' : 'credit-card'}-fee`"
              class="text-primary"
            >{{ isPayPal ? payPalFee : creditCardFee }} USD</strong>
          </div>
        </div>
      </div>

      <div
        v-if="taxRate"
        :id="`tax-section-${paymentMethodName}`"
        :data-testid="`tax-section-${paymentMethodName}`"
        class="d-flex align-items-center justify-content-between mb-1"
      >
        <div
          :id="`tax-text-${paymentMethodName}`"
          :data-testid="`tax-text-${paymentMethodName}`"
          class="font-14 text-placeholder"
        >
          {{ $t('payment.balanceTransactionsTable.textTax') }}
        </div>
        <strong
          :id="`tax-${paymentMethodName}-fee`"
          :data-testid="`tax-${paymentMethodName}-fee`"
          class="text-primary"
        >{{ tax }} USD</strong>
      </div>

      <div
        v-if="tipAmount > 0"
        :id="`tip-section-${paymentMethodName}`"
        class="d-flex align-items-center justify-content-between mb-1"
        :data-testid="`tip-section-${paymentMethodName}`"
      >
        <div
          :id="`tip-text-${paymentMethodName}`"
          class="font-14 text-placeholder"
          :data-testid="`tip-text-${paymentMethodName}`"
        >
          {{ $t('payment.balanceTransactionsTable.textTip') }}
        </div>
        <strong
          :id="`tip-${paymentMethodName}-fee`"
          :data-testid="`tip-${paymentMethodName}-fee`"
          class="text-primary"
        >{{ tipAmountTxt }} USD</strong>
      </div>

      <hr class="hr-custom">

      <div
        :id="`total-amount-section-${paymentMethodName}`"
        :data-testid="`total-amount-section-${paymentMethodName}`"
        class="d-flex align-items-center justify-content-between"
      >
        <div
          :id="`total-amount-text-${paymentMethodName}`"
          :data-testid="`total-amount-text-${paymentMethodName}`"
          class="font-14 text-placeholder text-left"
        >
          {{
            `${$t('addFundPage.creditCardPayment.textTotalAmount')} = ${$t('balance.textAmount')}
            ${percentFee ? `+ ${$t('payment.balanceTransactionsTable.textServiceCharge')}` : ''}
            ${taxRate ? `+ ${$t('payment.balanceTransactionsTable.textTax')}` : ''}
            ${methodPayPalFee || methodCreditCardFee ? `+ ${isPayPal ? $t('payment.paypal.textPaymentFee', { payment: 'PayPal' }) : $t('payment.creditCard.titleCreditCardFee')}` : ''}
            ${tipAmount > 0 ? `+ ${$t('payment.balanceTransactionsTable.textTip')}` : ''}`
          }}
        </div>
      </div>
      <h3
        :id="`total-amount-${paymentMethodName}`"
        :data-testid="`total-amount-${paymentMethodName}`"
        class="mt-2 text-left"
      >
        {{ totalAmount }} USD
      </h3>
    </div>
    <div class="action mt-2 d-flex justify-content-between">
      <btn-loading
        :id="`btn-recharge-${paymentMethodName}`"
        :data-testid="`btn-recharge-${paymentMethodName}`"
        variant-convert="outline-dark"
        class="btn-sm-block btn-recharge mr-1"
        pill
        @click="rechargePayment(paymentMethod)"
      >
        {{ $t('payment.textRecharge') }}
      </btn-loading>

      <btn-loading
        :id="`btn-show-ad-account-${paymentMethodName}`"
        :data-testid="`btn-show-ad-account-${paymentMethodName}`"
        class="w-100 py-1"
        variant-convert="btn-submit"
        pill
        @click="$router.history.push('/my-ads-account')"
      >
        {{ $t('addFundPage.creditCardPayment.textShowMeAdAccount') }}
      </btn-loading>
    </div>
    <p
      v-if="isOnlyEcomdyPlatform"
      class="text-center mt-1"
    >*{{ $t('addFundPage.creditCardPayment.textYouCanDownload') }}
      <span
        class="text-purple cursor-pointer underline"
        @click="$router.push('/payment?tab=payment_history')"
      >
        {{ $t('addFundPage.textHere') }}
      </span>
    </p>
  </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
import _find from 'lodash/find'
import _get from 'lodash/get'
import { bus } from '@/plugins/bus'
import { PAYMENT_METHOD } from '@/constants'
import gaTrackingMixin from '@/mixins/gaTrackingMixin'
import numberFormatMixin from '@/mixins/numberFormatMixin'
import paymentMixin from '@/mixins/paymentMixin'
import generalConfigsMixin from '@/mixins/generalConfigsMixin'
import envMixin from '@/mixins/envMixin'
import BtnLoading from '@/layouts/components/BtnLoading.vue'

const { mapGetters } = createNamespacedHelpers('payment')
const { mapGetters: mapGettersAuth } = createNamespacedHelpers('auth')

export default {
  components: {
    BtnLoading,
  },
  mixins: [numberFormatMixin, generalConfigsMixin, paymentMixin, envMixin, gaTrackingMixin],
  props: {
    paymentMethod: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      amount: 0,
      historyTransaction: {},
    }
  },

  computed: {
    ...mapGetters(['listCard', 'loading', 'listBusinessAccountHistoryLimit']),
    ...mapGettersAuth(['user', 'generalConfigs']),

    paymentMethodName() {
      if (this.isPayPal) {
        return 'paypal'
      }
      return 'credit-card'
    },

    fullName() {
      const fullName = _get(this.user, ['data', 'fullName'], '')
      const email = _get(this.user, ['data', 'email'], '')
      return fullName || email
    },

    creditCardLogo() {
      const cardSelected = _find(this.listCard, { default: true })
      if (cardSelected) {
        return this.getCreditCardLogo(cardSelected.brand)
      }
      return ''
    },

    cardName() {
      const cardSelected = _find(this.listCard, { default: true })
      if (cardSelected) {
        return this.getCreditCardName(cardSelected.brand)
      }
      return ''
    },

    percentFee() {
      if (this.isCreditCard) {
        return this.user?.data?.plans?.transferFee?.creditCard || 0
      }
      if (this.isPayPal) {
        return this.user?.data?.plans?.transferFee?.paypal || 0
      }

      return 0
    },

    serviceCharge() {
      // return parseFloat(this.truncDigits(this.percentFee * this.amount)).toLocaleString('en-US')
      return this.amountFormat(this.percentFee * this.amount)
        .toLocaleString('en-US')
    },

    tax() {
      // return parseFloat(this.truncDigits(this.taxRate * this.amount)).toLocaleString('en-US')
      return this.amountFormat(this.taxRate * this.amount)
        .toLocaleString('en-US')
    },

    tipAmount() {
      return this.historyTransaction?.payload?.tipAMAmount || null
    },

    tipAmountTxt() {
      return this.amountFormat(this.tipAmount)
        .toLocaleString('en-US')
    },

    totalAmount() {
      const fee = this.amountFormat(this.percentFee * this.amount)
      const tax = this.amountFormat(this.taxRate * this.amount)
      const methodFee = this.isPayPal ? this.amountFormat(this.methodPayPalFee * this.amount) : this.amountFormat(this.methodCreditCardFee * this.amount)
      // return parseFloat(this.truncDigits(this.amount + fee + tax)).toLocaleString('en-US')
      return (this.amount + fee + tax + methodFee + this.tipAmount).toLocaleString('en-US')
    },

    queryType() {
      return this.$route.query.type
    },

    isCreditCard() {
      return this.paymentMethod === PAYMENT_METHOD.CREDITCARD
    },

    isPayPal() {
      return this.paymentMethod === PAYMENT_METHOD.PAYPAL
    },

    methodPayPalFee() {
      return this.generalConfigs?.paymentGateFee?.paypalGate || 0
    },

    methodCreditCardFee() {
      return this.generalConfigs?.paymentGateFee?.creditCardGate || 0
    },

    payPalFee() {
      return this.amountFormat(this.methodPayPalFee * this.amount)
        .toLocaleString('en-US') || 0
    },

    creditCardFee() {
      return this.amountFormat(this.methodCreditCardFee * this.amount)
        .toLocaleString('en-US') || 0
    },
  },

  watch: {
    listBusinessAccountHistoryLimit: {
      handler(transactions) {
        if (transactions.paging.total > 0) {
          this.historyTransaction = transactions.content.find(transaction => transaction.paymentMethod === PAYMENT_METHOD.CREDITCARD
            || transaction.paymentMethod === PAYMENT_METHOD.PAYPAL)
        }
      },
      deep: true,
      immediate: true,
    },
  },

  created() {
    bus.$on('set-invoice-data', data => {
      this.amount = data.amount
    })

    if (this.isOnlyEcomdyPlatform) {
      this.gaTrackingUserAddFundSuccess(this.amount, this.paymentMethod) // GA Tracking
    }
  },

  methods: {
    rechargePayment(payment) {
      if (payment === 'paypal') {
        this.$emit('handle-recharge-paypal')
      } else {
        this.$emit('handle-recharge-credit-card')
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.hr-custom {
  border-top: 1px solid #EEEEEE;
}

.summary {
  border-radius: var(--border-radius-base);
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
}

.btn-recharge{
  font-size: 14px;
  padding: 11px 20px;
  width: 150px;
}
</style>
