<template>
  <div>
    <div
      v-if="isInProgress"
      :class=" hasStepGuide ? 'in-process-step-guide' : null"
    >
      <Progress :is-in-progress="isInProgress" />
    </div>
    <b-row
      v-else
      class="width-content add-fund-container"
    >
      <b-col
        :md="hasStepGuide ? 12 : 9"
        class="p-0 card-content"
      >
        <div
          class="lianlian-top-up-amount"
          :class="hasStepGuide ? 'radius-content' : 'list-card'"
          @mouseover="showGuideDefault = false"
          @mouseleave="showGuideDefault = true"
        >
          <top-up-amount
            :payment-method="paymentMethod"
            :amount="amount"
            @on-confirm="handleRequestLianLian"
          />
        </div>

        <div class="user-hint mt-1 mb-2 d-flex">
          <user-hint :class="showUserGuide ? 'd-lg-none' : ''" />
        </div>
      </b-col>
      <b-col
        v-if="!hasStepGuide"
        cols="12"
        md="9"
        :lg="hasStepGuide ? 12 : 3"
        class="payment-user-guide card-content"
      >
        <user-guide v-if="showUserGuide" />
      </b-col>
    </b-row>

    <modal-confirm-payment
      ref="open-modal-confirm-payment"
      @hide-modal-confirm="hideModalConfirm"
    />
  </div>
</template>
<script>
/* eslint-disable camelcase */
import { BRow, BCol } from 'bootstrap-vue'
import { toastification } from '@core/mixins/toast'
import { createNamespacedHelpers } from 'vuex'
import stepGuideMixin from '@/mixins/stepGuideMixin'
import authMixin from '@/mixins/authMixin'
import { LIAN_PAYMENT_STATUS } from '@/constants'
import UserHint from '@/components/UserHint.vue'
import UserGuide from '@/components/UserGuide.vue'
import TopUpAmount from './TopUpAmount.vue'
import Progress from './Progress.vue'
import ModalConfirmPayment from './ModalConfirmPayment.vue'

const {
  mapActions,
  mapGetters,
  mapState,
} = createNamespacedHelpers('payment')

export default {
  name: 'Lianlian',
  components: {
    UserHint,
    BRow,
    BCol,
    TopUpAmount,
    Progress,
    ModalConfirmPayment,
    UserGuide,
  },
  mixins: [toastification, stepGuideMixin, authMixin],
  data() {
    return {
      paymentMethod: 'lianlian',
      amount: 0,
      showGuideDefault: true,
      isInProgress: false,
      isConfirm: false,
    }
  },

  computed: {
    ...mapGetters(['status', 'message', 'lianlianPending']),
    ...mapState({
      clientRedirectUrl: state => state.lianlianRequest.clientRedirectUrl,
    }),
  },

  watch: {
    clientRedirectUrl: {
      handler(newUrl) {
        if (newUrl !== '') {
          window.location.href = newUrl
        }
      },
      immediate: true,
      deep: true,
    },

    lianlianPending: {
      handler(pending) {
        const { status } = pending?.payload?.lianlianPayload || ''
        const { query } = this.$route
        if (status) {
          switch (status) {
            case LIAN_PAYMENT_STATUS.PROCESSING:
              this.isInProgress = true
              break
            case LIAN_PAYMENT_STATUS.UNPAID:
              if (query?.tab === 'lianlian') {
                this.isConfirm = true
                this.$nextTick(() => {
                  this.showConfirmModal()
                })
              }
              break
            default:
              this.$router.push('/payment/add-fund?tab=lianlian')
          }
        }
      },
      deep: true,
      immediate: true,
    },

    $route: {
      handler(route) {
        if (route) {
          const { query } = this.$route
          const { status } = this.lianlianPending?.payload?.lianlianPayload || ''
          if (query?.tab === 'lianlian') {
            if (!this.isConfirm && status === LIAN_PAYMENT_STATUS.UNPAID) {
              this.isConfirm = true
              this.$nextTick(() => {
                this.showConfirmModal()
              })
            }
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },

  created() {
    if (!this.lianlianPending?.payload) {
      this.getLianlianPending()
    }
  },

  methods: {
    ...mapActions(['requestLianLianPayment', 'getLianlianPending']),

    hideModalConfirm() {
      this.isConfirm = false
    },

    openConfirmPaymentModal() {
      this.$refs['open-modal-confirm-payment'].showModal()
    },

    showConfirmModal() {
      if (this.isConfirm) {
        this.openConfirmPaymentModal()
      }
    },

    async handleRequestLianLian(data) {
      await this.requestLianLianPayment(data)
      if (!this.status) {
        this.toastFailure(this.message)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.note-content{
  font-size: 12px;
  font-style: italic;
}

.list-card{
  // max-width: 864px;
  border-radius: var(--border-radius-base);
}

.radius-content {
  border-radius: 0px 0px var(--border-radius-base) var(--border-radius-base);
}

.in-process-step-guide{
  border-radius: 0 0 24px 24px;
  padding: 1.5rem;
  background-color: #fff;
  margin-top: 0;

  .summary-form{
    max-width: 410px;
    margin: 0 auto;
  }
}

.warning-message {
  margin-top: 10px;
  background: rgba(251, 176, 59, 0.1);
  border-radius: 10px;
  padding: 10px 12px 10px 16px;
}

.additional-fee{
  font-family: 'Courier New', Courier, monospace;
}
</style>
