<template>
  <b-row class="width-content add-fund-container">
    <b-col
      :md="!hasStepGuide ? 9 : 12"
      cols="12"
      class="card-content p-0"
    >
      <div
        class="payoneer-connect-account position-relative"
        @mouseover="showGuideDefault = false"
        @mouseleave="showGuideDefault = true"
      >
        <payoneer-connect-account
          :account="payoneerAccount"
          :is-connected="isConnected"
        />
      </div>
      <div
        v-if="isConnected"
        class="payoneer-top-up position-relative"
        @mouseover="showGuideDefault = false"
        @mouseleave="showGuideDefault = true"
      >
        <payoneer-top-up />
      </div>

      <div
        class="user-hint mt-1 mb-2 d-flex"
      >
        <user-hint :class="showUserGuide ? 'd-lg-none' : ''" />
      </div>
    </b-col>
    <b-col
      v-if="!hasStepGuide"
      cols="12"
      md="9"
      :lg="hasStepGuide ? 12 : 3"
      class="payment-user-guide card-content"
    >
      <user-guide v-if="showUserGuide" />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { createNamespacedHelpers } from 'vuex'
import _has from 'lodash/has'
import stepGuideMixin from '@/mixins/stepGuideMixin'
import authMixin from '@/mixins/authMixin'
import UserHint from '@/components/UserHint.vue'
import UserGuide from '@/components/UserGuide.vue'
import PayoneerConnectAccount from './PayoneerConnectAccount.vue'
import PayoneerTopUp from './PayoneerTopUp.vue'
// eslint-disable-next-line no-unused-vars
const { mapGetters } = createNamespacedHelpers('payment')
const { mapGetters: mapGettersUser } = createNamespacedHelpers('auth')

export default {
  components: {
    BRow,
    BCol,
    PayoneerConnectAccount,
    PayoneerTopUp,
    UserHint,
    UserGuide,
  },
  mixins: [stepGuideMixin, authMixin],

  data() {
    return {
      showGuideDefault: true,
      contentModal: {},
      payoneerPaymentModal: {
        title: 'How to add fund with Payoneer?',
        description: '',
        video: 'https://www.youtube.com/embed/n0h_bVe3rIg',
        guideURL: process.env.VUE_APP_GUIDE_TO_TOP_UP_PAYONEER,
      },
      payoneerPaymentModalNoStep: {
        title: 'How to add fund with Payoneer?',
        description: '',
        video: 'https://www.youtube.com/embed/rHKTGmrBohw',
        guideURL: process.env.VUE_APP_GUIDE_TO_TOP_UP_PAYONEER,
      },
    }
  },

  computed: {
    ...mapGetters(['payoneerAccount', 'loading']),
    ...mapGettersUser(['user']),

    isConnected() {
      return _has(this.user.data.billings, 'payoneer')
    },
  },
}
</script>
<style lang="scss" scoped>
.width-content {
  max-width: 1200px;
  margin: 0px auto;

  @media (max-width: 992px){
    justify-content: center;
  }
}

.guide-video {
  position: absolute;
  top: 19px;
  left: 20px;
  color: #4CB375;
}

.guide-icon {
  top: 19px;
  right: 12px;
}

.payoneer-connect-account:hover .tooltip-payoneer-connect-account {
  @media (min-width: 992px) {
    display: block;
  }
}

.payoneer-top-up:hover .tooltip-payoneer-top-up {
  @media (min-width: 992px) {
    display: block;
  }
}
.card-content{
  position: relative;
  @media (max-width: 1399px) {
    max-width: 719px;
  }
}
</style>
