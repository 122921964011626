<template>
  <div>
    <b-row class="width-content add-fund-container">
      <b-col
        :md="hasStepGuide ? 12 : 9"
        class="p-0 mb-2 card-content"
      >
        <div class="mx-auto width-content">
          <div>
            <top-up-amount
              v-if="!isShowDetailInfo"
              :amount-selected="amountSelected"
              :amount-tip="amountTip"
              :percentage-tip="currentTip"
              :tip-index="currentTipIndex"
              :local-banks="listLocalBank"
              @submit="handleAmountSubmit"
            />

            <PaymentInfo
              v-else
              :payment-selected="paymentSelected"
              :url-q-r-code="urlQRCode"
              @handle-prev="handlePrev"
              @handle-copy="handleCopy"
              @handle-refresh-list-bank="handleRefreshListBank"
              @handleGetQRCode="handleGetQRCode"
              @handle-get-list-local-bank="handleGetListLocalBank"
            />

            <div
              class="user-hint mt-1 mb-2 d-flex mx-auto hint-width"
            >
              <user-hint />
            </div>
          </div>
        </div>
      </b-col>
      <b-col
        v-if="!hasStepGuide"
        cols="12"
        md="9"
        :lg="hasStepGuide ? 12 : 3"
        class="payment-user-guide card-content"
      >
        <user-guide v-if="showUserGuide" />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import TopUpAmount from '@/views/payment/components/LocalBank/TopUpAmount.vue'
import PaymentInfo from '@/views/payment/components/LocalBank/PaymentInfo.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { toastification } from '@core/mixins/toast'
import { createNamespacedHelpers } from 'vuex'
import paymentMixin from '@/mixins/paymentMixin'
import envMixin from '@/mixins/envMixin'
import { PaymentService } from '@/services'
import { PAYMENT_METHOD, TRANSACTION_TYPE } from '@/constants'
import UserHint from '@/components/UserHint.vue'
import authMixin from '@/mixins/authMixin'
import UserGuide from '@/components/UserGuide.vue'
import stepGuideMixin from '@/mixins/stepGuideMixin'
import { BCol, BRow } from 'bootstrap-vue'

const { mapGetters, mapActions } = createNamespacedHelpers('payment')
const { mapGetters: mapGettersAuth, mapActions: mapActionsAuth } = createNamespacedHelpers('auth')

const payOSConfig = (_self, checkoutURL, returnURL) => ({
  RETURN_URL: returnURL,
  ELEMENT_ID: 'payos-container',
  CHECKOUT_URL: checkoutURL,
  // eslint-disable-next-line no-shadow
  onSuccess: () => {
    // TODO: Hành động sau khi người dùng thanh toán đơn hàng thành công
    window.location.href = returnURL
  },
  // eslint-disable-next-line no-shadow
  onCancel: _self => {
    // TODO: Hành động sau khi người dùng Hủy đơn hàng
    if (localStorage.getItem('id_transaction')) {
      try {
        PaymentService.cancelTransactionPayOs(localStorage.getItem('id_transaction'))
        localStorage.removeItem('id_transaction')
        _self.toastSuccess('Hủy thanh toán thành công')
        _self.fetchHistory()
      } catch (e) {
        _self.toastFailure(e?.data?.message)
      }
    }
  },
})

const open = (_self, checkoutURL, returnURL) => window.PayOSCheckout.usePayOS(payOSConfig(_self, checkoutURL, returnURL)).open()

export default {
  components: {
    BRow,
    BCol,
    UserGuide,
    UserHint,
    TopUpAmount,
    PaymentInfo,
  },

  mixins: [toastification, paymentMixin, envMixin, authMixin, stepGuideMixin],

  data() {
    return {
      /* Casso */
      isShowDetailInfo: false,
      paymentSelected: '',
      amountSelected: null,
      urlQRCode: '',

      // PayOS
      checkoutURL: '',
      returnURL: '',
      topUpAmount: 0,

      currentTip: null,
      amountTip: null,
      currentTipIndex: null,
    }
  },

  computed: {
    PAYMENT_METHOD() {
      return PAYMENT_METHOD
    },
    ...mapGetters(['listLocalBank', 'loading', 'status', 'message']),
    ...mapGettersAuth(['user']),

    totalAmount() {
      const taxRate = this.user?.data?.plans?.transferFee?.tax || 0
      return this.amountSelected + (taxRate * this.amountSelected)
    },

    amountBalance() {
      return this.user?.data?.balance
    },
  },

  async created() {
    if (this.listLocalBank?.length === 0) {
      await this.handleGetListLocalBank()
    }

    await this.handleRedirectStatusTopUpPayOs()
  },

  methods: {
    ...mapActions(['getListLocalBank', 'getHistory', 'requestPayFastPayment']),
    ...mapActionsAuth(['getAccountInfo']),
    /* Casso */
    async handleGetListLocalBank() {
      await this.getListLocalBank()
      if (!this.status) {
        this.toastFailure(this.message)
      }
    },

    async handleGetQRCode() {
      const paymentInfo = this.listLocalBank.find(item => item.code === this.paymentSelected)
      this.urlQRCode = `https://img.vietqr.io/image/${paymentInfo?.bin}-${paymentInfo?.accNumber}-compact2.jpg?amount=${this.convertUSDtoVND(this.totalAmount)}&addInfo=${paymentInfo?.description}&accountName=${paymentInfo?.accName}`
    },

    async handleRefreshListBank() {
      await this.getListLocalBank()
      if (this.status) {
        await this.handleGetQRCode()
        this.toastSuccess(`${this.$t('SMB.payment.textRefreshSuccess')}`)
      } else {
        this.toastFailure(this.message)
      }
    },

    handlePrev() {
      this.isShowDetailInfo = false
      this.$router.push('add-fund')
    },

    handleCopy(content) {
      this.$copyText(content).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('SMB.payment.textCopySuccess'),
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      }, () => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('SMB.payment.textCopyUnsuccessfulPleaseTryAgain'),
            icon: 'BellIcon',
            variant: 'Danger',
          },
        })
      })
    },

    async handleTopUpCasso(bankCode, amount) {
      this.isShowDetailInfo = true
      this.amountSelected = amount
      this.paymentSelected = bankCode
      await this.handleGetQRCode()
    },
    /* End Casso */

    /* PayOs */
    async handleRedirectStatusTopUpPayOs() {
      const { query } = this.$route
      this.topUpAmount = Number(query?.amount) || 0
      if (query?.status === 'success') {
        this.toastSuccess(this.$t('SMB.payment.textTopUpSuccess'))
      } else if (query?.status === 'failed') {
        this.toastSuccess(this.$t('SMB.payment.textTopUpFail'))
      }
    },

    async handleTopUpPayOs(amount, tipAMAmount) {
      try {
        const params = {
          amount,
          tipAMAmount,
        }
        const { data } = await PaymentService.requestPaymentPayOs(params)
        const { result } = data
        this.checkoutURL = result?.checkoutUrl
        this.returnURL = result?.returnUrl
        open(this, this.checkoutURL, this.returnURL)
        this.fetchHistory()
        // eslint-disable-next-line no-underscore-dangle
        localStorage.setItem('id_transaction', result?._id)
      } catch (e) {
        this.toastFailure(e?.data?.message)
      }
    },
    /* End PayOs */

    handleAmountSubmit({ bankCode, amount, tipAMAmount }) {
      if (this.isSMBAgencyPlatform) {
        this.handleTopUpCasso(bankCode, amount)
      } else {
        this.handleTopUpPayOs(amount, tipAMAmount)
      }
    },

    async fetchHistory() {
      const params = {
        page: 1,
        size: 10,
        types: [
          TRANSACTION_TYPE.RECHARGE_USER_BALANCE,
          TRANSACTION_TYPE.ADMIN_FIX_RECHARGE_MONEY,
          TRANSACTION_TYPE.ADMIN_FIX_WITHDRAW_MONEY,
          TRANSACTION_TYPE.USER_TIP_FOR_AM,
          TRANSACTION_TYPE.CASHBACK,
          TRANSACTION_TYPE.SETUP_COST,
        ],
      }
      await this.getHistory({
        type: 'business',
        params,
      })
    },
  },
}
</script>
<style lang="scss" scoped>
</style>
