<template>
  <div>
    <div
      class="mt-2 bg-white list-card"
    >
      <div class="bg-white list-card">
        <top-up-form />
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import TopUpForm from './PayoneerTopUpForm.vue'

// eslint-disable-next-line no-unused-vars
const { mapGetters } = createNamespacedHelpers('payment')

export default {
  components: {
    TopUpForm,
  },
}
</script>
<style lang="scss">
  .list-card{
    border-radius: var(--border-radius-base);
    // max-width: 864px;
  }
</style>
