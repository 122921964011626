<template>
  <div
    class="top-up-container"
  >
    <div
      :class="hasStepGuide ? 'top-up-on-step' : 'top-up-form-container'"
    >
      <!--Top up form-->
      <div
        id="add-fund-form-ideal"
        data-testid="add-fund-form-ideal"
        class="add-fund-form"
      >
        <validation-observer
          v-slot="{ invalid }"
          ref="amountValidation"
        >
          <b-form
            id="payment-form"
            class="amount-form"
          >
            <!-- Setup cost-->
            <setup-cost
              v-if="hasStepGuide && isSetupCostModel"
              :amount="currentPaymentAmount.value"
            />
            <!-- /Setup cost-->
            <div v-else>
              <div class="d-block d-md-flex justify-content-between align-items-center">
                <h3
                  id="title-add-fund-ideal"
                  data-testid="title-add-fund-ideal"
                >
                  {{ $t('stepGuideControl.textAddFund') }}
                </h3>
              </div>
              <p
                id="sub-title-add-fund-ideal"
                data-testid="sub-title-add-fund-ideal"
              >{{ $t('payment.creditCard.textEnterAmount') }}</p>
              <b-form-group
                :label="$t('balance.textAmount')"
                class="position-relative form-label"
              >
                <validation-provider
                  #default="{ errors }"
                  id="amount"
                  :name="$t('balance.textAmount')"
                  :rules="{
                    required,
                    integerNumber,
                    greaterThanZero,
                    rangeTopUp: { min: minTopUp },
                  }"
                >
                  <b-input-group
                    id="amount-add-fund"
                    data-testid="amount-add-fund"
                    class="input-group-merge width-custom w-100"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="DollarSignIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      v-model.number="currentPaymentAmount.value"
                      id="input-amount-add-fund"
                      data-testid="input-amount-add-fund"
                      class="input-height"
                      autofocus
                      type="number"
                      placeholder="EX: 2500"
                      :state="errors.length > 0 ? false : null"
                      @input="onChangeCurrentPaymentAmount"
                    />
                    <b-input-group-append
                      v-if="currentPaymentAmount.value === maxAmount"
                      is-text
                    >
                      <img
                        :src="require('@/assets/images/pages/payment/ic-max.svg')"
                        alt="image"
                      >
                    </b-input-group-append>
                  </b-input-group>
                  <small
                    v-if="errors[0]"
                    id="errors-amount-ideal"
                    data-testid="error-amount-ideal"
                    class="text-danger"
                  >
                    {{ errors[0] }}
                  </small>
                  <small
                    v-if="!errors[0] && currentPaymentAmount.value"
                    id="amount-valid-ideal"
                    data-testid="amount-valid-ideal"
                    class="d-flex align-items-center"
                    style="margin-top: 10px"
                  >
                    <b-img
                      class="icons-tick"
                      fluid
                      alt="guide"
                      style="margin-right: 5px"
                      :src="require('@/assets/images/icons/icon-tick.svg')"
                    />
                    {{ $t('payment.textThisAmountIsSuitable') }}
                  </small>
                  <div class="info-box d-flex align-items-center">
                    <feather-icon
                      v-b-tooltip.v-light="`${$t('payment.creditCard.textAvailableLimit')}: $100,000`"
                      icon="HelpCircleIcon"
                      size="12"
                      class="icon-info"
                    />
                  </div>
                </validation-provider>
              </b-form-group>
              <div
                class="d-flex align-items-md-center flex-wrap max-width"
              >
                <top-up-amount-item
                  v-for="(item, index) in amountItem"
                  :key="index"
                  :id="`amount-${item.value}`"
                  :data-testid="`amount-${item.value}`"
                  :item="item"
                  :index="index"
                  :is-active="currentPaymentAmount.value === item.value"
                  @choosePaymentAmount="choosePaymentAmount"
                />
              </div>
            </div>

            <b-form-group
              :label="$t('setupCost.accountHolderName')"
              class="position-relative form-label mt-2"
            >
              <validation-provider
                #default="{ errors }"
                id="account-holder-name"
                :name="$t('setupCost.accountHolderName')"
                :rules="{ required, nameLength }"
              >
                <b-input-group
                  id="amount-add-fund"
                  data-testid="amount-add-fund"
                  class="input-group-merge width-custom w-100"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    v-model="userName"
                    id="name"
                    data-testid="input-name-top-up"
                    class="input-height"
                    :placeholder="$t('setupCost.accountHolderNamePlaceholder')"
                    :state="errors.length > 0 ? false : null"
                  />
                </b-input-group>
                <small
                  v-if="errors[0]"
                  id="errors-name-user-ideal"
                  data-testid="error-name-user-ideal"
                  class="text-danger"
                >
                  {{ errors[0] }}
                </small>
              </validation-provider>
            </b-form-group>

            <div>
              <label class="font-12 font-weight-600">
                iDEAL bank
              </label>
              <div
                id="ideal-bank-element"
                :class="!isBankSelected ? 'select-ideal-bank-error' : 'select-ideal-bank'"
                @mouseleave="onFocusIdealBank"
              >
                <!-- A Stripe Element will be inserted here. -->
              </div>
              <small
                v-if="!isBankSelected"
                id="errors-amount-ideal"
                data-testid="error-amount-ideal"
                class="text-danger"
              >
                The iDEAL bank field is required
              </small>
            </div>

            <div
              v-if="hasAMId && tipMode"
              class="mt-2"
            >
              <b-form-group
                :label="$t('payment.creditCard.labelTipAmount')"
                class="position-relative form-label"
                label-for="tip-amount"
              >
                <validation-provider
                  #default="{ errors }"
                  id="tip"
                  name="tip"
                  :rules="{
                    positive,
                    rangeTipAmount: { min: minTipAmount },
                  }"
                >
                  <b-input-group
                    class="input-group-merge width-custom w-100"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="DollarSignIcon" />
                    </b-input-group-prepend>
                    <cleave
                      v-model.number="amountTip"
                      id="ideal-tip-amount"
                      data-testid="ideal-tip-amount"
                      class="form-control"
                      :placeholder="$t('payment.creditCard.placeholderTip')"
                      :options="cleaveOptions"
                      :state="errors.length > 0 ? false : null"
                      @keyup.native="onChangeTipAmount"
                    />
                  </b-input-group>
                  <small
                    v-if="errors[0]"
                    id="error-tip-amount-ideal"
                    data-testid="error-tip-amount-ideal"
                    class="text-danger"
                  >
                    {{ errors[0] }}
                  </small>
                </validation-provider>
              </b-form-group>
              <div class="d-flex align-items-md-center flex-wrap max-width">
                <div
                  v-for="(item, index) in tipItem"
                  :key="index"
                  :id="`ideal-tip-item-${index+1}`"
                  :data-testid="`ideal-tip-item-${index+1}`"
                  class="btn-tip"
                  :class="{ active: currentTipIndex === index+1 }"
                  @click="chooseTipAmount({item, index})"
                >
                  <span>{{ item.value === $t('payment.textNoTip') ? item.value : `${item.value}%` }}</span>
                </div>
              </div>
            </div>

            <div class="d-flex align-items-center mt-2 justify-content-end">
              <btn-loading
                class="btn-height btn-text-color"
                variant-convert="btn-submit"
                pill
                :disabled="invalid || isDisabledButton"
                :loading="reChargeLoading"
                @click.prevent="submitForm"
              >
                {{ $t('payment.creditCard.textTopUp') }}
              </btn-loading>
            </div>
          </b-form>
        </validation-observer>
      </div>
      <!-- /Top up form-->

      <!-- Horizontal-divide -->
      <div
        class="line-center"
      >
        <div class="horizontal-divide" />
      </div>
      <!-- /Horizontal-divide-->

      <!-- Card summary -->
      <div
        class="summary-form"
      >
        <SummaryIdeal
          :amount="currentPaymentAmount.value || 0"
          :user-name="userName || ''"
          :amount-tip="amountTip || 0"
          :current-tip="currentTip || 0"
        />
      </div>
      <!-- /Card summary-->
    </div>
  </div>
</template>
<script>
// Bootstrap
import {
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormGroup,
  BForm,
  BImg,
  VBTooltip,
  BInputGroupAppend,
} from 'bootstrap-vue'

// validation
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  greaterThanZero, required, integerNumber, rangeTopUp, positive, rangeTipAmount, nameLength,
} from '@validations'

// mixins
import generalConfigsMixin from '@/mixins/generalConfigsMixin'
import billingMixin from '@/mixins/billingMixin'
import stepGuideMixin from '@/mixins/stepGuideMixin'
import { toastification } from '@core/mixins/toast'

// components
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import Ripple from 'vue-ripple-directive'
import numberFormatMixin from '@/mixins/numberFormatMixin'
import paymentMixin from '@/mixins/paymentMixin'
import { PAYMENT_CHILD_TYPE, PERCENT_TIP_AMOUNT } from '@/constants'
import Cleave from 'vue-cleave-component'
import Vue from 'vue'
import { StripePlugin } from '@vue-stripe/vue-stripe'

import { createNamespacedHelpers } from 'vuex'
import SetupCost from '@/views/payment/components/SetupCost.vue'
import TopUpAmountItem from './TopUpAmountItem.vue'
import SummaryIdeal from './Summary.vue'

const { mapGetters, mapActions } = createNamespacedHelpers('payment')

export default {
  components: {
    SetupCost,
    SummaryIdeal,
    TopUpAmountItem,
    // components
    Cleave,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BForm,
    BFormGroup,
    BImg,
    BtnLoading,
    BInputGroupAppend,

    // validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [
    toastification,
    generalConfigsMixin,
    billingMixin,
    stepGuideMixin,
    numberFormatMixin,
    paymentMixin,
  ],
  props: {
    userData: {
      type: Object,
      required: false,
      default: () => {
      },
    },
  },

  data() {
    return {
      minTipAmount: 0,
      tipItem: PERCENT_TIP_AMOUNT,
      currentTipIndex: null,
      currentTip: null,
      amountTip: null,

      cleaveOptions: {
        numeral: true,
        numeralPositiveOnly: true,
        numeralThousandsGroupStyle: 'none',
      },

      currentPaymentAmount: {
        value: null,
        type: PAYMENT_CHILD_TYPE.NUMBER,
      },

      userName: '',
      idealBank: null,
      reChargeLoading: false,
      isBankSelected: true,

      transactionType: '',
      isDisabledButton: false,

      // validation
      required,
      nameLength,
      greaterThanZero,
      rangeTopUp,
      rangeTipAmount,
      integerNumber,
      positive,
    }
  },

  computed: {
    ...mapGetters(['stripeBankRedirects', 'message', 'status', 'loading']),
    maxAmount() {
      return 100000
    },
  },

  watch: {
    user: {
      async handler(currentUser) {
        const locale = localStorage.getItem('locale') || process.env.VUE_APP_LOCALE
        const pk = currentUser?.data?.billings?.stripe?.stripe_pk
        if (!pk) return

        const options = {
          pk,
          locale,
        }
        await Vue.use(StripePlugin, options)
        this.stripeElements = this.$stripe.elements()
        this.initFormElement()

        this.handleSetupCost()
        if (currentUser?.data?.onboardingStep) {
          this.isDisabledButton = this.isSetupCostModel && currentUser?.data?.onboardingStep === 5
        }
      },
      deep: true,
      immediate: true,
    },

    generalConfigs: {
      handler(val) {
        if (val) {
          this.handleSetupCost()
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    ...mapActions(['requestStripeBankRedirects']),
    handleSetupCost() {
      if (this.hasStepGuide && this.isSetupCostModel) {
        this.currentPaymentAmount.value = this.generalConfigs?.setupCostFee
        this.transactionType = 'setup_cost'
      }
    },

    initFormElement() {
      this.$nextTick(async () => {
        this.idealBank = await this.stripeElements.create('idealBank', {
          style: {
            base: {
              padding: '15px 16px',
              color: '#16213e',
              fontSize: '15px',
              '::placeholder': {
                color: '#bbbbc5',
              },
            },
          },
        })
        this.idealBank.mount('#ideal-bank-element')
      })
    },

    onChangeCurrentPaymentAmount() {
      if (this.currentTip) {
        this.amountTip = ((this.currentTip * this.currentPaymentAmount.value) / 100)
      }
    },

    choosePaymentAmount(data) {
      this.currentPaymentAmount = { ...data.item }
      this.currentIndex = data.index
      this.onChangeCurrentPaymentAmount()

      if (this.currentTip) {
        this.amountTip = ((this.currentTip * this.currentPaymentAmount.value) / 100)
      }
    },

    chooseTipAmount(data) {
      this.currentTip = data.item.value === this.$t('payment.textNoTip') ? null : data.item.value
      this.amountTip = ((this.currentTip * this.currentPaymentAmount.value) / 100)
      this.currentTipIndex = data.index + 1
    },

    onChangeTipAmount() {
      this.currentTipIndex = null
      this.currentTip = null
    },

    onFocusIdealBank() {
      if (!this.isBankSelected) {
        this.isBankSelected = !this.isBankSelected
      }
    },

    async submitForm() {
      if (this.noPlan) {
        this.$router.push('/billing')
      } else {
        this.isBankSelected = document.getElementById('ideal-bank-element').classList.contains('StripeElement--complete')

        if (this.isBankSelected) {
          this.reChargeLoading = true
          const params = {
            amount: this.currentPaymentAmount.value,
            ...(this.amountTip && { tipAMAmount: this.amountTip }),
            ...(this.transactionType && { transactionType: this.transactionType }),
            accountHolderName: this.userName,
            paymentMethod: 'ideal',
          }

          await this.requestStripeBankRedirects(params)
          if (!this.status) {
            this.reChargeLoading = false
            this.toastFailure(this.message)
          }

          this.reChargeLoading = false
          const { clientSecret, returnURL } = this.stripeBankRedirects

          try {
            const { error: stripeError } = await this.$stripe.confirmIdealPayment(
              clientSecret,
              {
                payment_method: {
                  ideal: this.idealBank,
                  billing_details: {
                    name: this.userName,
                  },
                },
                return_url: returnURL,
              },
            )

            if (stripeError) {
              this.toastFailure(stripeError.message)
            }
          } catch (e) {
            this.toastFailure(e.message)
          }
        }
      }
    },
  },
}
</script>
<style lang="scss">
.is-invalid{
  #tip-amount:not(:focus){
    border-color: #e11c4e !important;
  }
}
</style>

<style lang="scss" scoped>
@import '@/assets/scss/variables/_variables.scss';
.top-up-container {
  padding: 24px;
  display: flex;
  flex-direction: column;
}

.top-up-on-step {
  .line-center {
    display: none;
  }
}

.btn-amount, .btn-tip {
  padding: 6px 12px;
  margin-right: 10px;
  margin-top: 10px;

  border: 1px solid #e0e0e5;
  background-color: white;
  border-radius: 6px;

  cursor: pointer;

  color: #3d405b;
  font-size: 14px;

  &:hover,
  &.active {
    border: 1px solid $primary;
    color: $primary;
    font-weight: 600;
  }

  &:last-child {
    margin-right: 0;
  }

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
}

.top-up-form-container {
  display: flex;
  justify-content: space-between;

  .add-fund-form {
    max-width: 50%;
  }

  .line-center {
    margin: 0 1.5rem;
  }

  .summary-form {
    width: 50%;
  }

  @media (max-width: 1399px) {
    display: block;

    .add-fund-form {
      max-width: 100%;
    }

    .line-center {
      display: none;
    }

    .summary-form {
      width: 100%;
    }
  }
}

.horizontal-divide {
  width: 1px;
  height: 100%;
  background: #eeeeee;
}

.max-width {
  max-width: 410px;
}

.input-height {
  height: 50px;
}

.mr-mb {
  margin-right: 10px;
}

.width-custom {
  width: 63%;
  @media (max-width: 767px) {
    width: 100%;
  }
}

.info-box {
  position: absolute;
  top: -23px;
  right: 3px;
  font-size: 0.875rem;

  .icon-info {
    margin-left: 4px;
  }
}

.form-label {
  font-size: 12px;
  font-weight: 600;
}

.error-message-stripe {
  margin: 24px 0 1rem;
  background: rgba(225, 28, 78, .1);
  border-radius: 20px;
  padding: 1rem 2rem;
  color: #000;

  .content-error {
    margin-left: 6px;
    margin-bottom: 0;
  }
}

#ideal-bank-element {
  border-radius: var(--input-border-radius-base);
  height: 50px;
  width: 100%;
}

.select-ideal-bank {
  border: 1px solid #e0e0e5;
}

.select-ideal-bank-error {
  border: 1px solid #e11c4e;
}
</style>
