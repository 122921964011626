<template>
  <div
    class="p-2"
    :class="hasStepGuide ? 'top-up-on-step' : 'top-up-form-container'"
  >
    <!--Top up form-->
    <div class="pr-0 add-fund-form">
      <validation-observer
        ref="amountValidation"
        v-slot="{ invalid }"
      >
        <b-form
          class="amount-form"
          @submit.prevent=""
        >
          <!--Setup cost-->
          <setup-cost
            v-if="hasStepGuide && isSetupCostModel"
            :amount="currentPaymentAmount.value || 0"
          />
          <!-- /Setup cost-->
          <div v-else>
            <div class="d-block d-md-flex justify-content-between align-items-center">
              <h3 id="title-add-amount-paypal">
                {{ $t('stepGuideControl.textAddFund') }}
              </h3>
            </div>
            <p
              id="text-enter-amount-paypal"
              data-testid="text-enter-amount-paypal"
            >
              {{ $t('payment.creditCard.textEnterAmount') }}
            </p>
            <b-form-group
              :label="$t('balance.textAmount')"
              class="position-relative form-label"
            >
              <validation-provider
                #default="{ errors }"
                id="amount"
                :name="$t('balance.textAmount')"
                :rules="{
                  required,
                  integerNumber,
                  greaterThanZero,
                  rangeTopUp: { min: minTopUp },
                }"
              >
                <b-input-group
                  id="input-amount-paypal"
                  data-testid="input-amount-paypal"
                  class="input-group-merge width-custom w-100"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-input-group-prepend is-text>
                    <feather-icon icon="DollarSignIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model.number="currentPaymentAmount.value"
                    id="input-amount-add-fund-paypal"
                    class="input-height"
                    type="number"
                    placeholder="EX: 2500"
                    data-testid="onboarding-paypal-amount-input"
                    :state="errors.length > 0 ? false : null"
                    @input="onChangeCurrentPaymentAmount"
                  />
                  <b-input-group-append
                    v-if="currentPaymentAmount.value === maxAmount"
                    is-text
                  >
                    <img
                      :src="require('@/assets/images/pages/payment/ic-max.svg')"
                      alt="image"
                    >
                  </b-input-group-append>
                </b-input-group>
                <small
                  v-if="errors[0]"
                  id="error-amount-paypal"
                  data-testid="error-amount-paypal"
                  class="text-danger"
                >
                  {{ errors[0] }}
                </small>
                <small
                  v-if="!errors[0] && currentPaymentAmount.value"
                  id="amount-valid-paypal"
                  data-testid="amount-valid-paypal"
                  class="d-flex align-items-center"
                  style="margin-top: 10px"
                >
                  <b-img
                    class="icons-tick"
                    fluid
                    alt="guide"
                    style="margin-right: 5px"
                    :src="require('@/assets/images/icons/icon-tick.svg')"
                  />
                  {{ $t('payment.textThisAmountIsSuitable') }}
                </small>
                <div class="info-box d-flex align-items-center">
                  <feather-icon
                    v-b-tooltip.v-light="{id: 'tooltip-paypal-limit-amount', title: `${$t('payment.creditCard.textAvailableLimit')}: $100,000`}"
                    icon="HelpCircleIcon"
                    size="12"
                    class="icon-info"
                    data-testid="paypal-limit-amount-note"
                  />
                </div>
              </validation-provider>
            </b-form-group>
            <div class="d-flex align-items-md-center flex-wrap max-width">
              <top-up-amount-item
                v-for="(item, index) in amountItem"
                :key="index"
                :id="`paypal-amount-${item.value}`"
                :data-testid="`paypal-amount-${index}`"
                :item="item"
                :index="index"
                :is-active="currentPaymentAmount.value === item.value"
                @choosePaymentAmount="choosePaymentAmount"
              />
            </div>
          </div>

          <div
            v-if="hasAMId && tipMode"
            class="mt-2"
          >
            <b-form-checkbox
              v-model="showTipsForAM"
              :aria-expanded="showTipsForAM ? 'true' : 'false'"
              aria-controls="collapse-1-inner"
              data-testid="checkbox-show-tips-input"
              @input="handleShowTipsInput"
            >
              <span class="cursor-pointer">{{ $t('payment.txtCheckboxShowTipsForAM') }}</span>
            </b-form-checkbox>
          </div>

          <b-collapse
            v-model="showTipsForAM"
            id="collapse-1-inner"
            class="mt-2"
          >
            <b-form-group
              class="position-relative form-label"
              label-for="tip-amount"
            >
              <validation-provider
                #default="{ errors }"
                id="tip"
                name="tip"
                :rules="{
                  positive,
                  rangeTipAmount: { min: minTipAmount },
                }"
              >
                <b-input-group
                  class="input-group-merge width-custom w-100"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-input-group-prepend is-text>
                    <feather-icon icon="DollarSignIcon" />
                  </b-input-group-prepend>
                  <cleave
                    v-model.number="amountTip"
                    id="tip-amount"
                    data-testid="tip-amount-paypal"
                    class="form-control"
                    :placeholder="$t('payment.creditCard.placeholderTip')"
                    :options="cleaveOptions"
                    :state="errors.length > 0 ? false : null"
                    @keyup.native="onChangeTipAmount"
                  />
                </b-input-group>
                <small
                  v-if="errors[0]"
                  id="error-tip-amount-paypal"
                  data-testid="error-tip-amount-paypal"
                  class="text-danger"
                >
                  {{ errors[0] }}
                </small>
              </validation-provider>
            </b-form-group>
            <div
              class="d-flex align-items-md-center flex-wrap max-width"
            >
              <tip-amount-item
                v-for="(item, index) in tipItem"
                :key="index"
                :item="item"
                :index="index"
                :is-active="currentTipIndex === index"
                :data-testid="`paypal-tip-item-${index+1}`"
                @chooseTipAmount="chooseTipAmount"
              />
            </div>
          </b-collapse>

          <div class="d-flex justify-content-end">
            <div class="btn-paypal">
              <div
                v-if="invalid || isDisabledButton"
                class="disable-btn"
                data-testid="paypal-top-up-button"
              />
              <div
                class="paypal-button mt-2"
                :class="invalid || isDisabledButton ? 'disable' : ''"
              >
                <div
                  id="paypal-button-container"
                  data-testid="payment-paypal-button"
                />
              </div>
            </div>
          </div>
        </b-form>
      </validation-observer>
    </div>
    <!-- /Top up form-->

    <!-- Horizontal-divide -->
    <div class="d-flex justify-content-center px-0 line-center">
      <div class="horizontal-divide" />
    </div>
    <!-- /Horizontal-divide-->

    <!-- Card summary -->
    <div class="mt-2 pl-0 summary-form">
      <paypal-card-summary
        :amount="currentPaymentAmount.value || 0"
        :amount-tip="amountTip || 0"
        :current-tip="currentTip || 0"
        :show-tips-input="showTipsForAM"
      />
    </div>
    <!-- /Card summary-->
  </div>
</template>
<script>
/* eslint-disable no-underscore-dangle */

// Bootstrap
import {
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormGroup,
  BForm,
  BImg,
  VBTooltip,
  BInputGroupAppend,
  BFormCheckbox,
  BCollapse,
  VBToggle,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'

import { PAYMENT_CHILD_TYPE, PAYMENT_METHOD, PERCENT_TIP_AMOUNT } from '@/constants'

// validation
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  greaterThanZero,
  required,
  rangeTopUp,
  integerNumber,
  rangeTipAmount,
  positive,
} from '@validations'

// mixins
import generalConfigsMixin from '@/mixins/generalConfigsMixin'
import billingMixin from '@/mixins/billingMixin'
import stepGuideMixin from '@/mixins/stepGuideMixin'
import gaTrackingMixin from '@/mixins/gaTrackingMixin'
import envMixin from '@/mixins/envMixin'
import { toastification } from '@core/mixins/toast'

// components
import Ripple from 'vue-ripple-directive'
import { bus } from '@/plugins/bus'
import axios from '@axios'
import { createNamespacedHelpers } from 'vuex'
import _get from 'lodash/get'
import TipAmountItem from '@/views/payment/components/Paypal/TipAmountItem.vue'
import paymentMixin from '@/mixins/paymentMixin'
import SetupCost from '@/views/payment/components/SetupCost.vue'
import TopUpAmountItem from './TopUpAmountItem.vue'
import PaypalCardSummary from './PaypalCardSummary.vue'
// import AttentionToggle from '../AttentionToggle.vue'

const { mapGetters } = createNamespacedHelpers('adsAccount')
const { mapGetters: mapGettersAuth } = createNamespacedHelpers('auth')

export default {
  components: {
    SetupCost,
    TipAmountItem,
    // components
    PaypalCardSummary,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BForm,
    BFormGroup,
    BImg,
    BFormCheckbox,
    BCollapse,
    // BtnLoading,
    BInputGroupAppend,
    TopUpAmountItem,
    // AttentionToggle,

    // validation
    ValidationProvider,
    ValidationObserver,
    Cleave,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
    Ripple,
  },
  mixins: [toastification, generalConfigsMixin, billingMixin, stepGuideMixin, paymentMixin, gaTrackingMixin, envMixin],
  props: {
    topUpStatusHandler: {
      type: Boolean,
      default: false,
    },
    userData: {
      type: Object,
      required: false,
      default: () => {
      },
    },
  },

  data() {
    return {
      showTipsForAM: false,
      PAYMENT_METHOD,

      tipItem: PERCENT_TIP_AMOUNT,
      currentPaymentAmount: {
        value: null,
        type: PAYMENT_CHILD_TYPE.NUMBER,
      },
      topUpError: null,

      currentTipIndex: null,
      currentTip: null,
      amountTip: null,
      // validation
      required,
      greaterThanZero,
      rangeTopUp,
      integerNumber,
      rangeTipAmount,
      positive,

      adAccount: {},

      cleaveOptions: {
        numeral: true,
        numeralPositiveOnly: true,
        numeralThousandsGroupStyle: 'none',
      },
      minTipAmount: 0,

      transactionType: false,
      isDisabledButton: false,
    }
  },

  computed: {
    ...mapGetters(['listAccount', 'loading']),
    ...mapGettersAuth(['user']),

    listAccounts() {
      return _get(this.listAccount, ['content'], [])
    },

    maxAmount() {
      return 100000
    },

    checkHasNextBtn() {
      return this.hasStepGuide && this.user?.data?.balance > 0
    },
  },

  watch: {
    user: {
      async handler(currentUser) {
        this.handleSetupCost()
        if (currentUser?.data?.onboardingStep) {
          this.isDisabledButton = this.isSetupCostModel && currentUser?.data?.onboardingStep === 5
        }
      },
      deep: true,
      immediate: true,
    },

    generalConfigs: {
      handler(val) {
        if (val) {
          this.handleSetupCost()
        }
      },
      deep: true,
      immediate: true,
    },
  },

  mounted() {
    this.$nextTick(() => {
      const _self = this
      _self.handleSetupCost()
      if (window.paypal) {
        window.paypal
          .Buttons({
            // eslint-disable-next-line consistent-return
            async createOrder() {
              _self.topUpError = false // reset error
              try {
                if (_self.noPlan) {
                  _self.$router.push('/billing')
                } else {
                  const success = await _self.$refs.amountValidation.validate()
                  if (success) {
                    const params = {
                      amount: _self.currentPaymentAmount.value,
                      ...(_self.amountTip && { tipAMAmount: _self.amountTip }),
                      ...(_self.transactionType && { transactionType: _self.transactionType }),
                      // ...(_self.isToggle
                      //   && _self.adAccount && {
                      //   adsAccountId: _self.adAccount?.value,
                      // }),
                    }
                    const { data } = await axios.post(
                      '/api/paypal-payment/request-payment',
                      params,
                    )
                    if (_self.isEcomdyPlatform && !_self.isExclusivePartner) {
                      _self.gaTrackingUserAddFund(_self.currentPaymentAmount.value, PAYMENT_METHOD.PAYPAL) // GA Tracking
                    }
                    return data?.result?.orderId
                  }
                }
              } catch (e) {
                _self.topUpError = e.data.message || 'Top Up Failure!'
                _self.$emit('top-up-status-handler', false)
              }
            },
            async onApprove(data) {
              try {
                const params = {
                  orderId: data.orderID,
                }
                await axios.post('/api/paypal-payment/fulfill', params)
                await _self.$emit('top-up-status-handler', true)
                await axios.get('/api/users')
              } catch (e) {
                _self.topUpError = e.data.message || 'Top Up Failure!'
                _self.$emit('top-up-status-handler', false)
              }
            },
            style: {
              layout: 'vertical',
              shape: 'pill',
              label: 'paypal',
            },
          })
          .render('#paypal-button-container')
      }
    })
    this.$refs.amountValidation.reset()
  },

  methods: {
    handleShowTipsInput() {
      if (!this.showTipsForAM) {
        this.amountTip = null
        this.onChangeTipAmount()
      }
    },

    handleSetupCost() {
      if (this.hasStepGuide && this.isSetupCostModel) {
        this.currentPaymentAmount.value = this.generalConfigs?.setupCostFee
        this.transactionType = 'setup_cost'
      }
    },

    onChangeTipAmount() {
      this.currentTipIndex = null
      this.currentTip = null
    },

    onChangeCurrentPaymentAmount() {
      if (this.currentTip) {
        this.amountTip = ((this.currentTip * this.currentPaymentAmount.value) / 100)
      }
    },

    choosePaymentAmount(data) {
      this.currentPaymentAmount = { ...data.item }

      this.onChangeCurrentPaymentAmount()

      this.$nextTick(() => {
        this.$refs.amountValidation.validate()
      })
    },

    chooseTipAmount(data) {
      this.currentTip = data.percent === this.$t('payment.textNoTip') ? null : data.percent
      this.amountTip = ((this.currentTip * this.currentPaymentAmount.value) / 100)
      this.currentTipIndex = data.index
    },

    resetForm() {
      this.currentPaymentAmount = {
        value: null,
        type: PAYMENT_CHILD_TYPE.NUMBER,
      }
      this.amountTip = null
      this.currentTip = null
      this.currentTipIndex = null
      this.$refs.amountValidation.reset()
    },

    sendInvoice() {
      // send data to success page.
      const dataInvoice = {
        amount: this.currentPaymentAmount.value,
        topUpError: this.topUpError,
      }
      bus.$emit('set-invoice-data', dataInvoice)
    },

    // handleSelectAdAccount(data) {
    //   this.adAccount = data?.account
    //   this.isToggle = data.isToggle
    // },
  },
}
</script>
<style lang="scss">
.is-invalid {
  #tip-amount:not(:focus) {
    border-color: #e11c4e !important;
  }
}
</style>

<style lang="scss" scoped>
.top-up-on-step {
  .line-center {
    display: none;
  }
}

.top-up-form-container {
  display: flex;

  .add-fund-form {
    max-width: 50%;
  }

  .line-center {
    margin: 0 1.5rem;
  }

  .summary-form {
    width: 50%;
  }

  @media (max-width: 1399px) {
    display: block;

    .add-fund-form {
      max-width: 100%;
    }

    .line-center {
      display: none;
    }

    .summary-form {
      width: 100%;
    }
  }
}

.horizontal-divide {
  width: 1px;
  height: 100%;
  background: #eeeeee;
}

.max-width {
  max-width: 410px;
}

.input-height {
  height: 50px;
}

.mr-mb {
  margin-right: 10px;
}

.width-custom {
  width: 63%;
  @media (max-width: 767px) {
    width: 100%;
  }
}

.info-box {
  position: absolute;
  top: -23px;
  right: 3px;
  font-size: 0.875rem;

  .icon-info {
    margin-left: 4px;
    cursor: pointer;
  }
}

.btn-paypal {
  max-width: 200px;
  width: 100%;
  position: relative;
  z-index: 1;

  .disable-btn {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    z-index: 9999;
    cursor: no-drop;
  }

  .paypal-button {
    position: relative;

    &.disable {
      opacity: 0.75;
    }
  }
}

.form-label {
  font-size: 12px;
  font-weight: 600;
}
</style>
