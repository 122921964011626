<template>
  <b-modal
    ref="modal-confirm-payment"
    id="block-child"
    class="modal-child"
    hide-footer
    hide-header
    centered
    no-close-on-backdrop
  >
    <div class="d-flex justify-content-between top-modal px-1 pt-1">
      <h3 class="font-medium text-primary">
        {{ $t('payment.tazapay.modalConfirmTazapay.titleModal') }}
      </h3>
      <div>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hideModal"
        />
      </div>
    </div>

    <div class="position-relative pl-1 pr-1 reason-reject mt-1">
      <p>{{ $t('payment.tazapay.modalConfirmTazapay.subModal') }}</p>

      <div class="summary p-2 card">
        <h4 class="text-left mb-2">
          {{ $t('addFundPage.creditCardPayment.textSummary') }}
        </h4>
        <div class="mb-1 d-flex flex-row flex-wrap justify-content-between">
          <div class="font-14 text-placeholder">
            {{ $t('addFundPage.creditCardPayment.textClient') }}
          </div>
          <strong>
            {{ fullName }}
          </strong>
        </div>
        <div class="mb-1 d-flex flex-row justify-content-between">
          <div class="font-14 text-placeholder">
            {{ $t('payment.airWallex.labelOrderAmount') }}
          </div>
          <strong>
            {{ currencyFormat(amount) }} USD
          </strong>
        </div>
        <div class="mb-1 d-flex flex-row justify-content-between">
          <div class="font-14 text-placeholder">
            {{ $t('addFundPage.creditCardPayment.textPaymentMethod') }}
          </div>
          <img
            :src="tazapayLogo"
            height="16px"
            alt="tazapay"
          >
        </div>

        <div
          v-if="serviceCharge"
          class="d-flex flex-row justify-content-between mb-1"
        >
          <div class="font-14 text-placeholder">
            {{ $t('payment.balanceTransactionsTable.textServiceCharge') }}
          </div>
          <strong class="text-primary">{{ currencyFormat(serviceCharge) }} USD</strong>
        </div>

        <div
          v-if="tazapayFee"
          class="d-flex align-items-center justify-content-between mb-1"
        >
          <div class="font-14 text-placeholder">
            {{ $t('payment.paypal.textPaymentFee', { payment: 'Tazapay' }) }}
          </div>
          <div>
            <div
              class="d-flex align-items-center"
            >
              <strong class="text-primary">{{ currencyFormat(tazapayFee) }} USD</strong>
            </div>
          </div>
        </div>

        <div
          v-if="tax"
          class="d-flex flex-row justify-content-between"
        >
          <div class="font-14 text-placeholder">
            {{ $t('payment.balanceTransactionsTable.textTax') }}
          </div>
          <strong class="text-primary">{{ currencyFormat(tax) }} USD</strong>
        </div>

        <div
          v-if="tipAmount > 0"
          class="d-flex align-items-center justify-content-between mt-1"
        >
          <div class="font-14 text-placeholder">
            Tip
          </div>
          <strong class="text-primary">{{ currencyFormat(tipAmountTxt) }} USD</strong>
        </div>

        <hr class="hr-custom mt-1">

        <div class="d-flex align-items-center justify-content-between">
          <div class="font-14 text-placeholder text-left">
            {{ $t('addFundPage.creditCardPayment.textTotalAmount') }} = {{ $t('balance.textAmount') }}
            {{ serviceCharge ? ` + ${$t('payment.balanceTransactionsTable.textServiceCharge')}` : '' }}
            {{ tax ? ` + ${$t('payment.balanceTransactionsTable.textTax')}` : '' }}
            {{ tazapayFee ? ` + ${$t('payment.paypal.textPaymentFee', {payment: "Tazapay"})}` : '' }}
            {{ tipAmount > 0 ? ' + Tip' : '' }}
          </div>
        </div>
        <h3 class="mt-2 text-left">
          {{ currencyFormat(bankTransferAmount) }} USD
        </h3>
      </div>
      <p>{{ $t('payment.tazapay.modalConfirmTazapay.contentConfirm') }}?</p>
    </div>

    <!--modal footer-->
    <div class="d-flex justify-content-end mt-2 mb-1">
      <btn-loading
        variant-convert="outline-dark"
        class="btn-recharge"
        pill
        @click="hideModal"
      >
        {{ $t('payment.tazapay.modalConfirmTazapay.btnNewPayment') }}
      </btn-loading>
      <btn-loading
        variant-convert="btn-submit"
        class="ml-1"
        pill
        @click="continuePayment"
      >
        {{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.textContinue') }}
      </btn-loading>
    </div>
    <!--/modal footer-->
  </b-modal>
</template>
<script>
/* eslint-disable camelcase */

import {
  BModal,
} from 'bootstrap-vue'
import _get from 'lodash/get'
import { createNamespacedHelpers } from 'vuex'
import stepGuideMixin from '@/mixins/stepGuideMixin'
import numberFormatMixin from '@/mixins/numberFormatMixin'
import paymentMixin from '@/mixins/paymentMixin'
import BtnLoading from '@/layouts/components/BtnLoading.vue'

const { mapGetters: mapGettersAuth } = createNamespacedHelpers('auth')
const { mapGetters } = createNamespacedHelpers('payment')

export default {
  components: {
    BtnLoading,
    BModal,
  },
  mixins: [stepGuideMixin, numberFormatMixin, paymentMixin],

  props: {
    // userData: {
    //   type: Object,
    //   default: () => ({}),
    //   required: true,
    // },
  },

  data() {
    return {}
  },

  computed: {
    ...mapGettersAuth(['user', 'loading', 'generalConfigs']),
    ...mapGetters(['tazapayPending']),

    tazapayLogo() {
      // eslint-disable-next-line global-require
      return require('@/assets/images/pages/payment/logo-tazapay.svg')
    },
    fullName() {
      const fullName = _get(this.user, ['data', 'fullName'], '')
      const email = _get(this.user, ['data', 'email'], '')
      return fullName || email
    },

    amount() {
      return this.tazapayPending?.amount
    },

    bankTransferAmount() {
      return this.tazapayPending?.payload?.bankTransferAmount
    },

    paymentMethod() {
      return this.tazapayPending.paymentMethod
    },

    serviceCharge() {
      return this.tazapayPending?.payload?.serviceCharge
    },

    tazapayFee() {
      return this.tazapayPending?.payload?.paymentGateCharge
    },

    tax() {
      return this.tazapayPending?.payload?.taxCharge
    },

    tipAmount() {
      return this.tazapayPending?.payload?.tipAMAmount || null
    },

    tipAmountTxt() {
      return this.amountFormat(this.tipAmount)
        .toLocaleString('en-US')
    },
  },

  methods: {
    continuePayment() {
      const { url } = this.tazapayPending?.payload?.tazapayPayload?.data

      window.location.href = url
    },

    hideModal() {
      this.$emit('hide-modal-confirm')
      this.$refs['modal-confirm-payment'].hide()
    },

    showModal() {
      this.$refs['modal-confirm-payment'].show()
    },
  },
}
</script>

<style lang="scss" scoped>
.summary {
  margin-bottom: 1rem;
}

.reason-reject-message {
  text-align: start;
  white-space: normal;
}

.reason-reject {
  width: 100%;
}

.btn-recharge {
  font-size: 14px;
  padding: 11px 20px;
}
</style>
