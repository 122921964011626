<template>
  <div>
    <div
      class="bg-white p-2 border-form"
      :class="hasStepGuide ? 'radius-content' : ''"
    >
      <b-row>
        <b-col md="12">
          <div class="btn-register">
            <div class="icon">
              <img
                class="img-icon"
                alt="flag"
                :src="require('@/assets/images/pages/payment/ic-register-airwallex.svg')"
              >
            </div>
            <div class="content-btn">
              <p>

                {{ $t('payment.airWallex.contentRegisterAirwallex') }} <span
                  class="cursor-pointer text-purple text-capitalize"
                  @click="goToAirCompany"
                >{{ $t('common.linkClickHere') }}</span> {{ $t('payment.airWallex.textToInquireCompanyRegistration') }}
              </p>
            </div>
            <div class="btn-airwallex">
              <a
                href="https://www.airwallex.com/signup?utm_source=a11BB0000007QqhYAE"
                target="_blank"
                class="content-btn-register"
              >
                <span>{{ $t('payment.wiseTransfer.btnRegisterWith') }}</span>
                <img
                  alt="logo-airwallex"
                  :src="require('@/assets/images/pages/payment/logo-airWallex.svg')"
                >
              </a>
            </div>
          </div>
        </b-col>
      </b-row>

    </div>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import stepGuideMixin from '@/mixins/stepGuideMixin'

export default {
  components: {
    BRow,
    BCol,
  },
  mixins: [stepGuideMixin],

  methods: {
    goToAirCompany() {
      window.open('http://www.hk-hg.cn/')
    },
  },
}
</script>
<style lang="scss" scoped>
.radius-content {
  border-radius: 0px 0px var(--border-radius-base) var(--border-radius-base) !important;
}

  .border-form{
    border-radius: var(--border-radius-base);
  }

  .coin-select{

    @media (max-width: 767px) {
      width: 100%;
    }
  }

  .btn-register{
    display: flex;
    align-items: center;

    background: linear-gradient(90deg, rgba(255, 79, 66, 0.1) 0.12%, rgba(255, 142, 60, 0.1) 100.23%);
    border-radius: 12px;
    padding: 12px;

    .icon{
      max-width: 40px;
      width: 100%;

      .img-icon{
        width: 100%;
      }
    }

    .content-btn{
      margin: 0 20px 0 12px;

      font-size: 14px;

      p{
        margin: 0;
      }

      @media (max-width: 992px) {
        margin: 12px 0;
      }
    }

    .btn-airwallex{

      .content-btn-register{
        color: #fff;
        background: linear-gradient(90deg, #FF4F42 0.12%, #FF8E3C 100.23%);
        border-radius: 100px;

        width: max-content;

        display: flex;
        align-items: center;

        padding: 8px 16px;

        font-size: 14px;

        span{
          margin-right: 8px;
        }
      }

    }

    @media (max-width: 992px) {
      flex-direction: column;
    }
  }
</style>
