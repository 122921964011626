<template>
  <div>
    <b-row class="width-content add-fund-container">
      <b-col
        :md="!hasStepGuide ? 9 : 12"
        class="p-0 top-up-form top-up-form"
      >
        <div
          class="bg-white"
          :class="hasStepGuide ? 'radius-content' : 'list-card'"
        >
          <top-up-form
            ref="top-up-form"
            :user-data="user"
          />
        </div>
        <div
          class="user-hint mt-1 mb-2 d-flex"
        >
          <user-hint :class="showUserGuide ? 'd-lg-none' : ''" />
        </div>
      </b-col>
      <!-- TopUp Form -->
      <b-col
        v-if="!hasStepGuide"
        cols="12"
        md="9"
        :lg="hasStepGuide ? 12 : 3"
        class="payment-user-guide card-content"
      >
        <user-guide v-if="showUserGuide" />
      </b-col>
    </b-row>

  </div>
</template>
<script>
/* eslint-disable global-require */

import { BRow, BCol } from 'bootstrap-vue'
import { toastification } from '@core/mixins/toast'
import paymentMixin from '@/mixins/paymentMixin'
import gaTrackingMixin from '@/mixins/gaTrackingMixin'
import { PAYMENT_METHOD } from '@/constants'
import stepGuideMixin from '@/mixins/stepGuideMixin'
import authMixin from '@/mixins/authMixin'
import envMixin from '@/mixins/envMixin'
import UserHint from '@/components/UserHint.vue'
import UserGuide from '@/components/UserGuide.vue'
import TopUpForm from './TopUpForm.vue'

export default {
  components: {
    TopUpForm,
    UserHint,
    UserGuide,
    BRow,
    BCol,
  },
  mixins: [toastification, paymentMixin, stepGuideMixin, authMixin, gaTrackingMixin, envMixin],
  data() {
    return {
      PAYMENT_METHOD,
    }
  },
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/variables/_variables.scss";

.hr-custom{
  border-top: 1px solid rgba(22, 33, 62, 0.1);
}

.list-card{
  // max-width: 864px;
  border-radius: var(--border-radius-base);
}

.radius-content {
  border-radius: 0px 0px var(--border-radius-base) var(--border-radius-base);
}

.top-up-form {
  // max-width: 864px;
  border-radius: 14px;

  @media (max-width: 1399px) {
    max-width: 719px;
  }
}

.success-page{
  max-width: 410px;
}

.width-content {
  max-width: 1200px;
  margin: 0px auto;

  @media (max-width: 992px){
    justify-content: center;
  }
}

.top-up-form:hover .tooltip-top-up-form {
  @media (min-width: 992px) {
    display: block;
  }
}

</style>
