<template>
  <div class="text-center">
    <div class="header px-2 pb-1">
      <img
        alt="success"
        :src="require('@/assets/images/pages/payment/ic-payment-failure.svg')"
      >
      <h3 class="my-1">
        {{ $t('payment.paypal.viewTopUpFail.titleTopUpFail') }}!
      </h3>
      <p>{{ $t('payment.paypal.viewTopUpFail.subTitleTopUpFail') }}: <span class="text-danger">{{ topUpError }}</span></p>
    </div>
    <div class="summary p-2">
      <h4 class="text-left mb-2">
        {{ $t('addFundPage.creditCardPayment.textSummary') }}
      </h4>
      <div class="d-flex align-items-center justify-content-between mb-1 flex-wrap">
        <div class="font-14 text-placeholder">
          {{ $t('balance.textAmount') }}
        </div>
        <strong>
          {{ fullName }}
        </strong>
      </div>
      <div class="d-flex align-items-center justify-content-between mb-1">
        <div class="font-14 text-placeholder">
          {{ $t('addFundPage.creditCardPayment.textClient') }}
        </div>
        <strong>
          {{ localStringFormat(amount) }} USD
        </strong>
      </div>
      <div class="d-flex align-items-center justify-content-between mb-1">
        <div class="font-14 text-placeholder">
          {{ $t('addFundPage.creditCardPayment.textPaymentMethod') }}
        </div>
        <div v-if="isCreditCard">
          <img
            :src="creditCardLogo"
            height="16px"
            alt="credit"
            style="margin-right: 5px"
          >
          <strong
            class="font-14 text-primary"
          >
            {{ cardName }}
          </strong>
        </div>
        <div v-if="isPayPal">
          <img
            :src="require('@/assets/images/pages/payment/paypal.svg')"
            height="16px"
            alt="credit"
            style="margin-right: 5px"
          >
          <strong
            class="font-14 text-primary"
          >
            Paypal
          </strong>
        </div>
      </div>
      <div
        v-if="percentFee"
        class="d-flex align-items-center justify-content-between mb-1"
      >
        <div class="font-14 text-placeholder">
          {{ $t('payment.balanceTransactionsTable.textServiceCharge') }}
        </div>
        <strong class="text-primary">{{ serviceCharge }} USD</strong>
      </div>
      <div
        v-if="taxRate"
        class="d-flex align-items-center justify-content-between mb-1"
      >
        <div class="font-14 text-placeholder">
          {{ $t('payment.balanceTransactionsTable.textTax') }}
        </div>
        <strong class="text-primary">{{ tax }} USD</strong>
      </div>
      <hr class="hr-custom">
      <div class="d-flex align-items-center justify-content-between">
        <div class="font-14 text-placeholder text-left">
          {{ $t('addFundPage.creditCardPayment.textTotalAmount') }}
          = {{ $t('balance.textAmount') }}
          {{ percentFee ? ` + ${$t('payment.balanceTransactionsTable.textServiceCharge')}` : '' }}
          {{ taxRate ? ` + ${$t('payment.balanceTransactionsTable.textTax')}` : '' }}
        </div>
      </div>
      <h3 class="mt-2 text-left">
        {{ totalAmount }} USD
      </h3>
    </div>
    <div class="action mt-2 d-flex justify-content-between">
      <btn-loading
        block
        variant-convert="btn-submit"
        pill
        @click="$emit('update:showErrorPage', false)"
      >
        {{ $t('payment.textRecharge') }}
      </btn-loading>
    </div>
  </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
import _get from 'lodash/get'
import { bus } from '@/plugins/bus'
import { PAYMENT_METHOD } from '@/constants'

import numberFormatMixin from '@/mixins/numberFormatMixin'
import paymentMixin from '@/mixins/paymentMixin'
import generalConfigsMixin from '@/mixins/generalConfigsMixin'
import BtnLoading from '@/layouts/components/BtnLoading.vue'

const { mapGetters } = createNamespacedHelpers('payment')
const { mapGetters: mapGettersAuth } = createNamespacedHelpers('auth')

export default {
  components: {
    BtnLoading,
  },
  mixins: [numberFormatMixin, generalConfigsMixin, paymentMixin],
  props: {
    paymentMethod: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      amount: 0,
      topUpError: null,
    }
  },

  computed: {
    ...mapGetters(['listCard', 'loading']),
    ...mapGettersAuth(['user']),

    fullName() {
      const fullName = _get(this.user, ['data', 'fullName'], '')
      const email = _get(this.user, ['data', 'email'], '')
      return fullName || email
    },

    percentFee() {
      if (this.isCreditCard) {
        return this.user?.data?.plans?.transferFee?.creditCard || 0
      } if (this.isPayPal) {
        return this.user?.data?.plans?.transferFee?.paypal || 0
      }

      return 0
    },

    serviceCharge() {
      // return parseFloat(this.truncDigits(this.percentFee * this.amount)).toLocaleString('en-US')
      return this.amountFormat(this.percentFee * this.amount).toLocaleString('en-US')
    },

    tax() {
      // return parseFloat(this.truncDigits(this.taxRate * this.amount)).toLocaleString('en-US')
      return this.amountFormat(this.taxRate * this.amount).toLocaleString('en-US')
    },

    totalAmount() {
      const fee = this.amountFormat(this.percentFee * this.amount)
      const tax = this.amountFormat(this.taxRate * this.amount)
      // return parseFloat(this.truncDigits(this.amount + fee + tax)).toLocaleString('en-US')
      return (this.amount + fee + tax).toLocaleString('en-US')
    },

    queryType() {
      return this.$route.query.type
    },

    isPayPal() {
      return this.paymentMethod === PAYMENT_METHOD.PAYPAL
    },

    isCreditCard() {
      return this.paymentMethod === PAYMENT_METHOD.CREDITCARD
    },
  },

  created() {
    bus.$on('set-invoice-data', data => {
      this.amount = data.amount
      this.topUpError = data.topUpError
    })
  },
}
</script>
<style lang="scss" scoped>
  .hr-custom{
    border-top: 1px solid #EEEEEE;
  }

  .summary{
    border-radius: var(--border-radius-base);
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  }
</style>
