<template>
  <vue-slider
    v-model="stepPayment"
    :min="0"
    :max="2"
    :tooltip="'none'"
    :disabled="true"
    class="vue-slider-step"
    :interval="1"
  />
</template>
<script>
import VueSlider from 'vue-slider-component'

export default {
  components: {
    VueSlider,
  },
  props: {
    step: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  data() {
    return {
      stepPayment: 0,
    }
  },
  watch: {
    step: {
      handler(val) {
        this.stepPayment = val
      },
      deep: true,
      immediate: true,
    },
  },
}
</script>
<style lang="scss">
  .vue-slider-step .vue-slider-rail {
    background-color: #F5F5F7;
  }
  .vue-slider-step .vue-slider-rail .vue-slider-process {
    background-color: #4CB375;
  }
</style>
