<template>
  <div>
    <form
      id="PayFast_payment_form"
      name="PayFast-payment-form"
      method="POST"
      action=""
      hidden
    >
      Currency Code: <input
        type="TEXT"
        name="CURRENCY_CODE"
        value="PKR"
      ><br>
      Merchant ID: <input
        type="TEXT"
        name="MERCHANT_ID"
        value=""
      ><br>
      Merchant Name: <input
        type="TEXT"
        name="MERCHANT_NAME"
        :value="name"
      ><br>
      Token: <input
        type="TEXT"
        name="TOKEN"
      ><br>
      Success URL: <input
        type="TEXT"
        name="SUCCESS_URL"
        value=""
      ><br>
      Failure URL: <input
        type="TEXT"
        name="FAILURE_URL"
        value=""
      ><br>
      Checkout URL: <input
        type="TEXT"
        name="CHECKOUT_URL"
        :value="apiUrl"
      ><br>
      Customer Email: <input
        type="TEXT"
        name="CUSTOMER_EMAIL_ADDRESS"
        :value="email"
      ><br>
      Customer Mobile: <input
        type="TEXT"
        name="CUSTOMER_MOBILE_NO"
        :value="phoneNumber"
      ><br>
      Transaction Amount: <input
        type="TEXT"
        name="TXNAMT"
        value=""
      ><br>
      Basket ID: <input
        type="TEXT"
        name="BASKET_ID"
        value=""
      ><br>
      Transaction Date: <input
        type="TEXT"
        name="ORDER_DATE"
      ><br>
      <input
        type="HIDDEN"
        name="MERCHANT_USERAGENT"
        value=""
      >
      <input
        type="SUBMIT"
        value="SUBMIT"
      >
    </form>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import get from 'lodash/get'

const { mapGetters: mapGettersAuth } = createNamespacedHelpers('auth')
export default {
  name: 'PayFastForm',
  data() {
    return {
      apiUrl: process.env.VUE_APP_SERVER_API,
    }
  },
  computed: {
    ...mapGettersAuth(['user']),
    name() {
      return this.user.data?.fullName
    },
    email() {
      return this.user.data?.email
    },
    phoneNumber() {
      const contactInfo = get(this.user, ['data', 'contactInfo'], {})
      if (contactInfo?.phone) {
        const phoneElm = contactInfo.phone.split('|')
        if (phoneElm.length === 3) {
          return `+${phoneElm[1]}${phoneElm[2]}`
        }
      }
      return ''
    },
  },
}
</script>
