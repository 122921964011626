<template>
  <div class="text-center bg-white p-2 mb-2 setup-cost-success-container">
    <div class="header px-2 pb-1">
      <img
        alt="success"
        :src="require('@/assets/images/pages/payment/setup-cost-success.png')"
      >
      <h3
        :id="`text-setup-success-${getPaymentMethod.id}`"
        :data-testid="`text-setup-success-${getPaymentMethod.id}`"
        class="my-1"
      >
        {{ $t('setupCost.titleSuccess') }}
      </h3>
      <p
        :id="`sub-title-setup-success-${getPaymentMethod.id}`"
        :data-testid="`sub-title-setup-success-${getPaymentMethod.id}`"
      >
        {{ isAdminSkipSetupCost ? $t('setupCost.descriptionAdminSkipSetupSuccess', {platform: platformName, amount: setupCostFee }) : $t('setupCost.descriptionSuccess', {platform: platformName}) }}
      </p>
    </div>
    <div
      v-if="!isAdminSkipSetupCost"
      id="form-success-credit-card"
      data-testid="form-success-credit-card"
      class="summary p-2"
    >
      <h4
        :id="`text-success-${getPaymentMethod.id}`"
        :data-testid="`text-success-${getPaymentMethod.id}`"
        class="text-left mb-2"
      >
        {{ $t('addFundPage.creditCardPayment.textSummary') }}
      </h4>
      <div
        :id="`client-${getPaymentMethod.id}`"
        :data-testid="`client-${getPaymentMethod.id}`"
        class="d-flex align-items-center justify-content-between mb-1 flex-wrap"
      >
        <div
          :id="`text-client-${getPaymentMethod.id}`"
          :data-testid="`text-client-${getPaymentMethod.id}`"
          class="font-14 text-placeholder"
        >
          {{ $t('addFundPage.creditCardPayment.textClient') }}
        </div>
        <strong
          :id="`client-name-${getPaymentMethod.id}`"
          :data-testid="`client-name-${getPaymentMethod.id}`"
        >
          {{ fullName }}
        </strong>
      </div>
      <div
        :id="`amount-section-${getPaymentMethod.id}`"
        :data-testid="`amount-section-${getPaymentMethod.id}`"
        class="d-flex align-items-center justify-content-between mb-1"
      >
        <div
          :id="`title-amount-${getPaymentMethod.id}`"
          :data-testid="`title-amount-${getPaymentMethod.id}`"
          class="font-14 text-placeholder"
        >
          {{ $t('balance.textAmount') }}
        </div>
        <strong
          :id="`amount-${getPaymentMethod.id}`"
          :data-testid="`amount-${getPaymentMethod.id}`"
        >
          {{ localStringFormat(amount) }} USD
        </strong>
      </div>
      <div
        :id="`payment-method-${getPaymentMethod.id}`"
        :data-testid="`payment-method-${getPaymentMethod.id}`"
        class="d-flex align-items-center justify-content-between mb-1"
      >
        <div
          :id="`title-payment-${getPaymentMethod.id}`"
          :data-testid="`title-payment-${getPaymentMethod.id}`"
          class="font-14 text-placeholder"
        >
          {{ $t('addFundPage.creditCardPayment.textPaymentMethod') }}
        </div>
        <div>
          <img
            :src="getPaymentMethod.logo"
            height="16px"
            :alt="getPaymentMethod.id"
            style="margin-right: 5px"
          >
          <strong
            :id="`method-${getPaymentMethod.id}`"
            :data-testid="`method-${getPaymentMethod.id}`"
            class="font-14 text-primary"
          >
            {{ getPaymentMethod.name }}
          </strong>
        </div>
      </div>

      <div
        v-if="accountHolderName"
        class="align-items-center mb-1 d-flex flex-row justify-content-between"
      >
        <div
          :id="`success-${getPaymentMethod.id}-title-account-holder-name`"
          :data-testid="`success-${getPaymentMethod.id}-title-account-holder-name`"
          class="font-14 text-placeholder"
        >
          {{ $t('setupCost.accountHolderName') }}:
        </div>

        <strong
          :id="`success-${getPaymentMethod.id}-account-holder-name`"
          :data-testid="`success-${getPaymentMethod.id}-account-holder-name`"
          class="font-14 text-primary"
        >
          {{ accountHolderName }}
        </strong>
      </div>

      <div
        v-if="verifiedName"
        class="align-items-center mb-1 d-flex flex-row justify-content-between"
      >
        <div
          :id="`success-${getPaymentMethod.id}-title-verified-name`"
          :data-testid="`success-${getPaymentMethod.id}-title-verified-name`"
          class="font-14 text-placeholder"
        >
          {{ $t('setupCost.verifiedName', { method: getPaymentMethod.name }) }}:
        </div>

        <strong
          :id="`success-${getPaymentMethod.id}-verified-name`"
          :data-testid="`success-${getPaymentMethod.id}-verified-name`"
          class="font-14 text-primary"
        >
          {{ verifiedName }}
        </strong>
      </div>

      <div
        v-if="bankName"
        class="align-items-center mb-1 d-flex flex-row justify-content-between"
      >
        <div
          :id="`success-${getPaymentMethod.id}-title-bank-name`"
          :data-testid="`success-${getPaymentMethod.id}-title-bank-name`"
          class="font-14 text-placeholder"
        >
          {{ $t('setupCost.idealBank', { method: getPaymentMethod.name }) }}:
        </div>

        <strong
          :id="`success-${getPaymentMethod.id}-bank-name`"
          :data-testid="`success-${getPaymentMethod.id}-bank-name`"
          class="font-14 text-primary"
        >
          {{ bankName }}
        </strong>
      </div>

      <div
        v-if="serviceCharge > 0"
        :id="`payment-section-${getPaymentMethod.id}`"
        :data-testid="`payment-section-${getPaymentMethod.id}`"
        class="d-flex align-items-center justify-content-between mb-1"
      >
        <div
          :id="`payment-service-${getPaymentMethod.id}`"
          :data-testid="`payment-service-${getPaymentMethod.id}`"
          class="font-14 text-placeholder"
        >
          {{ $t('payment.balanceTransactionsTable.textServiceCharge') }}
        </div>
        <strong
          :id="`service-charge-${getPaymentMethod.id}`"
          :data-testid="`service-charge-${getPaymentMethod.id}`"
          class="text-primary"
        >{{ serviceCharge }} USD</strong>
      </div>

      <div
        v-if="methodFee > 0"
        :id="`payment-section-${getPaymentMethod.id}`"
        :data-testid="`payment-section-${getPaymentMethod.id}`"
        class="d-flex align-items-center justify-content-between mb-1"
      >
        <div
          :id="`payment-method-${getPaymentMethod.id}`"
          :data-testid="`payment-method-${getPaymentMethod.id}`"
          class="font-14 text-placeholder"
        >
          {{ $t('payment.paypal.textPaymentFee', { payment: getPaymentMethod.name }) }}
        </div>
        <div>
          <div
            :id="`payment-details-${getPaymentMethod.id}`"
            class="d-flex align-items-center"
            :data-testid="`payment-details-${getPaymentMethod.id}`"
          >
            <strong
              :id="`method-${getPaymentMethod.id}-fee`"
              :data-testid="`method-${getPaymentMethod.id}-fee`"
              class="text-primary"
            >{{ methodFee }} USD</strong>
          </div>
        </div>
      </div>

      <div
        v-if="tax > 0"
        :id="`tax-section-${getPaymentMethod.id}`"
        :data-testid="`tax-section-${getPaymentMethod.id}`"
        class="d-flex align-items-center justify-content-between mb-1"
      >
        <div
          :id="`tax-text-${getPaymentMethod.id}`"
          :data-testid="`tax-text-${getPaymentMethod.id}`"
          class="font-14 text-placeholder"
        >
          {{ $t('payment.balanceTransactionsTable.textTax') }}
        </div>
        <strong
          :id="`tax-${getPaymentMethod.id}-fee`"
          :data-testid="`tax-${getPaymentMethod.id}-fee`"
          class="text-primary"
        >{{ tax }} USD</strong>
      </div>

      <div
        v-if="amountTip > 0"
        :id="`tip-section-${getPaymentMethod.id}`"
        class="d-flex align-items-center justify-content-between mb-1"
        :data-testid="`tip-section-${getPaymentMethod.id}`"
      >
        <div
          :id="`tip-text-${getPaymentMethod.id}`"
          class="font-14 text-placeholder"
          :data-testid="`tip-text-${getPaymentMethod.id}`"
        >
          {{ $t('payment.balanceTransactionsTable.textTip') }}
        </div>
        <strong
          :id="`tip-${getPaymentMethod.id}-fee`"
          :data-testid="`tip-${getPaymentMethod.id}-fee`"
          class="text-primary"
        >{{ amountTip }} USD</strong>
      </div>

      <hr class="hr-custom">

      <div
        :id="`total-amount-section-${getPaymentMethod.id}`"
        :data-testid="`total-amount-section-${getPaymentMethod.id}`"
        class="d-flex align-items-center justify-content-between"
      >
        <div
          :id="`total-amount-text-${getPaymentMethod.id}`"
          :data-testid="`total-amount-text-${getPaymentMethod.id}`"
          class="font-14 text-placeholder text-left"
        >
          {{
            `${$t('addFundPage.creditCardPayment.textTotalAmount')} = ${$t('balance.textAmount')}
            ${serviceCharge > 0 ? `+ ${$t('payment.balanceTransactionsTable.textServiceCharge')}` : ''}
            ${tax > 0 ? `+ ${$t('payment.balanceTransactionsTable.textTax')}` : ''}
            ${methodFee > 0 ? `+ ${$t('payment.paypal.textPaymentFee', { payment: getPaymentMethod.name })}` : ''}
            ${amountTip > 0 ? `+ ${$t('payment.balanceTransactionsTable.textTip')}` : ''}`
          }}
        </div>
      </div>
      <h3
        :id="`total-amount-${getPaymentMethod.id}`"
        :data-testid="`total-amount-${getPaymentMethod.id}`"
        class="mt-2 text-left"
      >
        {{ totalAmount }} USD
      </h3>
    </div>
  </div>
</template>
<script>
/* eslint-disable global-require */
import { createNamespacedHelpers } from 'vuex'
import _find from 'lodash/find'
import _get from 'lodash/get'
import { PAYMENT_METHOD, TRANSACTION_TYPE } from '@/constants'
import gaTrackingMixin from '@/mixins/gaTrackingMixin'
import numberFormatMixin from '@/mixins/numberFormatMixin'
import paymentMixin from '@/mixins/paymentMixin'
import generalConfigsMixin from '@/mixins/generalConfigsMixin'
import envMixin from '@/mixins/envMixin'

const { mapGetters, mapActions } = createNamespacedHelpers('payment')
const { mapGetters: mapGettersAuth } = createNamespacedHelpers('auth')

export default {
  mixins: [numberFormatMixin, generalConfigsMixin, paymentMixin, envMixin, gaTrackingMixin],
  data() {
    return {
      amount: 0,
      historyTransaction: {},
      amountTip: 0,
      totalAmount: 0,
      methodFee: 0,
      tax: 0,
      serviceCharge: 0,
      accountHolderName: '',
      bankName: '',
      verifiedName: '',
      isAdminSkipSetupCost: false,
    }
  },

  computed: {
    ...mapGetters(['listCard', 'loading', 'listBusinessAccountHistoryLimit']),
    ...mapGettersAuth(['user', 'generalConfigs']),
    getPaymentMethod() {
      const cardSelected = _find(this.listCard, { default: true })
      switch (this.paymentMethod) {
        case PAYMENT_METHOD.PAYPAL:
          return {
            id: 'paypal',
            logo: require('@/assets/images/pages/payment/ic-paypal.svg'),
            name: 'PayPal',
          }
        case PAYMENT_METHOD.PAYONEER:
          return {
            id: 'payoneer',
            logo: require('@/assets/images/pages/payment/ic-payoneer-2.svg'),
            name: 'Payoneer',
          }
        case PAYMENT_METHOD.USDT:
          return {
            id: 'usdt',
            logo: require('@/assets/images/pages/payment/ic-usdt.svg'),
            name: 'USDT',
          }
        case PAYMENT_METHOD.AIRWALLEX:
          return {
            id: 'airwallex',
            logo: require('@/assets/images/pages/payment/ic-airwallex.svg'),
            name: 'Airwallex',
          }
        case PAYMENT_METHOD.TRANSFERWISE:
          return {
            id: 'wise',
            logo: require('@/assets/images/pages/payment/ic-transferwise.svg'),
            name: 'Wise',
          }
        case PAYMENT_METHOD.TAZAPAY:
          return {
            id: 'tazapay',
            logo: require('@/assets/images/pages/payment/ic-tazapay.svg'),
            name: 'Tazapay',
          }
        case PAYMENT_METHOD.LIANLIAN:
          return {
            id: 'lianlian',
            logo: require('@/assets/images/pages/payment/ic-lianlian.svg'),
            name: 'LianLian',
          }
        case PAYMENT_METHOD.PAYFAST:
          return {
            id: 'payfast',
            logo: require('@/assets/images/pages/payment/ic-payfast.jpg'),
            name: 'Payfast',
          }
        case PAYMENT_METHOD.CREDITCARD:
          return {
            id: 'credit-card',
            logo: this.getCreditCardLogo(cardSelected?.brand),
            name: this.getCreditCardName(cardSelected?.brand),
          }
        case PAYMENT_METHOD.IDEAL:
          return {
            id: 'ideal',
            logo: require('@/assets/images/pages/payment/ic-ideal.svg'),
            name: 'iDEAL',
          }
        case PAYMENT_METHOD.BANCONTACT:
          return {
            id: 'bancontact',
            logo: require('@/assets/images/pages/payment/ic-bancontact.svg'),
            name: 'Bancontact',
          }
        case PAYMENT_METHOD.LOCALBANK_TICKET:
          return {
            id: 'local-bank-ticket',
            logo: require('@/assets/images/pages/payment/ic-local-bank.png'),
            name: 'Local Bank',
          }
        case PAYMENT_METHOD.LOCALBANK:
          return {
            id: 'local-bank',
            logo: require('@/assets/images/pages/payment/ic-local-bank.png'),
            name: 'Local Bank',
          }
        default: return ''
      }
    },

    fullName() {
      const fullName = _get(this.user, ['data', 'fullName'], '')
      const email = _get(this.user, ['data', 'email'], '')
      return fullName || email
    },
  },

  watch: {
    listBusinessAccountHistoryLimit: {
      handler(transactions) {
        if (transactions.paging.total > 0) {
          this.historyTransaction = transactions.content.find(transaction => transaction.type === TRANSACTION_TYPE.SETUP_COST && transaction.status === 'done')
          if (this.historyTransaction) {
            this.paymentMethod = this.historyTransaction?.paymentMethod

            this.amountTip = this.historyTransaction?.payload?.tipAMAmount || 0
            this.serviceCharge = this.amountFormat(this.historyTransaction?.payload?.serviceCharge) || 0
            this.tax = this.amountFormat(this.historyTransaction?.payload?.taxCharge) || 0
            this.methodFee = this.amountFormat(this.historyTransaction?.payload?.paymentGateCharge) || 0
            this.amount = this.amountFormat(this.historyTransaction?.payload?.toBalanceAmount) || 0
            this.accountHolderName = this.historyTransaction?.payload?.accountHolderName
            this.bankName = this.convertIdealBank(this.historyTransaction?.payload?.bankName)
            this.verifiedName = this.historyTransaction?.payload?.payerName

            this.totalAmount = this.historyTransaction?.payload?.bankTransferAmount
          } else {
            this.isAdminSkipSetupCost = true
          }
        } else {
          this.isAdminSkipSetupCost = true
        }
      },
      deep: true,
      immediate: true,
    },
  },

  async created() {
    await this.getListCard()
  },

  methods: {
    ...mapActions(['getListCard']),
  },
}
</script>
<style lang="scss" scoped>
.hr-custom {
  border-top: 1px solid #EEEEEE;
}

.summary {
  background-color: #f5f5f7;
  border-radius: 16px;
}

.setup-cost-success-container {
  border-radius: var(--border-radius-base);
}
</style>
