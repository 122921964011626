<template>
  <div class="box d-flex align-items-center">
    <div class="left-content">
      <b-card-title class="left-title">
        {{ $t('payment.USDT.titleUSDTInfo') }}
      </b-card-title>
      <b-card-text class="sub-title">
        {{ $t('payment.USDT.contentWhyChooseBUSD') }}
      </b-card-text>
      <ul class="list-content">
        <li
          v-for="(item, i) in content"
          :key="i"
        >
          {{ item }}
        </li>
      </ul>
      <p
        class="underline cursor-pointer"
        style="color: #2667FF"
        @click="goToUrl"
      >
        {{ $t('payment.USDT.contentCheckOutBUSD') }}
      </p>
      <div class="d-flex mt-2">
        <btn-loading
          variant-convert="warning"
          pill
          class="mr-1"
          @click="$router.push('/payment/add-fund?tab=busd')"
        >
          {{ $t('payment.USDT.btnUseBUSD') }}
        </btn-loading>
        <btn-loading
          variant-convert="outline-dark"
          pill
          @click="$emit('hide')"
        >
          {{ $t('payment.USDT.btnKeepUseUSDT') }}
        </btn-loading>
      </div>
    </div>
    <div class="right-content">
      <b-img
        :src="require('@/assets/images/pages/payment/usdt-intro.png')"
        alt="background payment"
        class="bg-img"
      />
    </div>
  </div>
</template>

<script>
import {
  BCardTitle, BCardText, BImg,
} from 'bootstrap-vue'
import BtnLoading from '@/layouts/components/BtnLoading.vue'

export default {
  components: {
    BtnLoading,
    BCardTitle,
    BCardText,
    BImg,
  },

  data() {
    return {
      content: [
        this.$t('payment.USDT.contentWithTheBUSD'),
        this.$t('payment.USDT.contentTakeTimeConfirm'),
        this.$t('payment.USDT.contentUSDTMethodIsClose'),
      ],
    }
  },

  methods: {
    goToUrl() {
      window.open('https://yudd2nso1l.larksuite.com/docs/docusaCz05JNrAZgv55u7dUgRRf')
    },
  },
}
</script>

<style lang="scss" scoped>
  .box{
    width: 100%;
    padding: 0 3rem;
    .left-content {

      max-width: 640px;

      .left-title {
        font-size: 24px;
        margin: 0;
      }

      .sub-title {
        margin: 26px 0 24px 0;
      }

      .list-content {
        margin-top: 12px;

        li {
          margin: 16px 0;
        }
      }

      @media (max-width: 767px) {
        width: 100%;
      }
    }

    .right-content {
      max-width: 350px;

      .bg-img {
        width: 100%;
        margin: 0 auto;
      }

      @media (max-width: 767px) {
        display: none;
      }
    }

    @media (max-width: 767px) {
      padding: 0;
    }
  }
</style>
