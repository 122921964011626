<template>
  <div class="summary-container mt-2">
    <h4
      id="title-summary-payfast"
      data-testid="title-summary-payfast"
      class="title-summary"
    >
      {{ $t('addFundPage.creditCardPayment.textSummary') }}
    </h4>
    <hr class="hr-custom">
    <div class="d-flex align-items-center justify-content-between mb-1">
      <div
        id="title-amount-payfast"
        data-testid="title-amount-payfast"
        class="font-14 text-placeholder"
      >
        {{ $t('balance.textAmount') }}
      </div>
      <strong
        v-if="amountValidate"
        id="amount-add-fund-tazpay"
        data-testid="amount-add-fund-tazpay"
      >
        {{ localStringFormat(amount) }} USD
      </strong>
      <strong v-else>
        --
      </strong>
    </div>

    <div
      class="d-flex align-items-center justify-content-between mb-1"
    >
      <div
        id="title-payfast-fee"
        data-testid="title-payfast-fee"
        class="font-14 text-placeholder"
      >
        {{ $t('addFundPage.creditCardPayment.textPaymentMethod') }}
      </div>
      <div
        v-if="amount"
        class="d-flex align-items-center"
      >
        <img
          :src="getBrandIcon(paymentMethod)"
          alt="payment"
          style="margin-right: 6px;width: 22px"
        >
        <strong
          id="current-payfast-method"
          data-testid="current-payfast-method"
          class="text-capitalize"
        >{{ convertNamePayment(paymentMethod) }}</strong>
      </div>
      <strong v-else> -- </strong>
    </div>

    <div
      v-if="percentFee"
      class="d-flex align-items-center justify-content-between mb-1"
    >
      <div
        id="title-service-fee-payfast"
        data-testid="title-service-fee-payfast"
        class="font-14 text-placeholder"
      >
        {{ $t('payment.balanceTransactionsTable.textServiceCharge') }}
        <small
          id="percent-service-payfast-fee"
          data-testid="percent-service-payfast-fee"
          class="font-small-1"
          style="margin-right: 6px"
        >
          {{ `(${percentFeeTxt})` }}
        </small>
      </div>
      <div>
        <div
          v-if="amountValidate"
          id="service-charge-payfast"
          data-testid="service-charge-payfast"
          class="d-flex align-items-center"
        >
          <strong
            id="current-service-charge-payfast"
            data-testid="current-service-charge-payfast"
            class="text-primary"
          >{{ serviceCharge.toLocaleString('en-US') }} USD</strong>
        </div>
        <strong v-else>
          --
        </strong>
      </div>
    </div>

    <div
      v-if="hasAMId && tipMode"
      class="d-flex align-items-center justify-content-between"
    >
      <div
        id="title-tip-payfast"
        data-testid="title-tip-payfast"
        class="font-14 text-placeholder"
      >
        Tip
        <small
          v-if="currentTip > 0"
          id="percent-tip-payfast-fee"
          data-testid="percent-tip-payfast-fee"
          class="font-small-1"
          style="margin-right: 6px"
        >
          {{ `(${percentTipTxt})` }}
        </small>
      </div>
      <div>
        <div
          v-if="amountValidate"
          class="d-flex align-items-center"
        >
          <strong
            id="current-tip-amount-payfast"
            data-testid="current-tip-amount-payfast"
            class="text-primary"
          >{{ amountTipTxt }} USD</strong>
        </div>
        <strong v-else>
          --
        </strong>
      </div>
    </div>

    <hr class="hr-custom">

    <div class="d-flex align-items-center justify-content-between mb-1">
      <div
        id="title-total-amount-payfast"
        data-testid="title-total-amount-payfast"
        class="font-16 font-medium"
      >
        Subtotal
      </div>
      <strong
        v-if="amountValidate"
        id="current-total-amount-payfast"
        data-testid="current-total-amount-payfast"
      >
        {{ subTotal.toLocaleString('en-US') }} USD
      </strong>
      <strong v-else>
        --
      </strong>
    </div>

    <div class="d-flex align-items-center justify-content-between mb-1">
      <div
        id="title-total-amount-payfast"
        data-testid="title-total-amount-payfast"
        class="font-14 text-placeholder"
      >
        Converted subtotal
      </div>
      <strong
        v-if="amountValidate"
        id="current-total-amount-payfast"
        data-testid="current-total-amount-payfast"
      >
        {{ subTotalTxt }} PKR
      </strong>
      <strong v-else>
        --
      </strong>
    </div>

    <div
      v-if="methodFee"
      class="d-flex align-items-center justify-content-between mb-1"
    >
      <div
        id="title-payfast-fee"
        data-testid="title-payfast-fee"
        class="font-14 text-placeholder"
      >
        {{ $t('payment.paypal.textPaymentFee', {payment: "PayFast"}) }}
        <small
          id="percent-payfast-fee"
          data-testid="percent-payfast-fee"
          class="font-small-1"
          style="margin-right: 6px"
        >
          {{ `(${methodFeeTxt})` }}
        </small>
      </div>
      <div>
        <div
          v-if="amountValidate"
          class="d-flex align-items-center"
        >
          <strong
            id="current-payfast-fee"
            data-testid="current-payfast-fee"
            class="text-primary"
          >{{ payfastFeeTxt }} PKR</strong>
        </div>
        <strong v-else>
          --
        </strong>
      </div>
    </div>

    <div
      v-if="taxRate"
      class="d-flex align-items-center justify-content-between mb-1"
    >
      <div
        id="title-tax-fee-payfast"
        data-testid="title-tax-fee-payfast"
        class="font-14 text-placeholder"
      >
        {{ $t('payment.balanceTransactionsTable.textTax') }}
        <small
          id="percent-tax-payfast-fee"
          data-testid="percent-tax-payfast-fee"
          class="font-small-1"
          style="margin-right: 6px"
        >
          {{ `(${percentTaxTxt})` }}
        </small>
      </div>
      <div>
        <div
          v-if="amountValidate"
          class="d-flex align-items-center"
        >
          <strong
            id="current-tax-fee-payfast"
            data-testid="current-tax-fee-payfast"
            class="text-primary"
          >{{ convertUSDtoPKR(tax).toLocaleString('en-US') }} PKR</strong>
        </div>
        <strong v-else>
          --
        </strong>
      </div>
    </div>

    <hr class="hr-custom">

    <div class="d-flex align-items-center justify-content-between mb-3">
      <div class="font-24 text-placeholder">
        {{ $t('addFundPage.creditCardPayment.textTotalAmount') }}
      </div>
      <strong
        v-if="amountValidate"
        id="amount"
        class="font-24 total-amount"
      >
        {{ totalAmount.toLocaleString('en-US') }} PKR
      </strong>
      <strong v-else>
        --
      </strong>
    </div>
  </div>
</template>

<script>
import paymentMixin from '@/mixins/paymentMixin'
import generalConfigsMixin from '@/mixins/generalConfigsMixin'
import numberFormatMixin from '@/mixins/numberFormatMixin'
import NP from 'number-precision'

// plugins
import { createNamespacedHelpers } from 'vuex'
import envMixin from '@/mixins/envMixin'

const { mapGetters: mapGettersAuth } = createNamespacedHelpers('auth')

export default {

  mixins: [paymentMixin, generalConfigsMixin, numberFormatMixin, envMixin],

  props: {
    amount: {
      type: Number,
      required: true,
    },
    paymentMethod: {
      type: String,
      required: true,
    },
    transfer: {
      type: Object,
      required: true,
    },
    amountTip: {
      type: Number,
      required: true,
    },
    currentTip: {
      type: Number,
      required: true,
    },
  },

  computed: {
    ...mapGettersAuth(['user', 'generalConfigs']),

    isInTrial() {
      return this.user?.isInTrial
    },

    percentFee() {
      return this.user?.data?.plans?.transferFee?.payfast || 0
    },

    percentFeeTxt() {
      return `${(this.percentFee * 100).toFixed(2)}%`
    },

    methodFee() {
      return this.generalConfigs?.paymentGateFee?.payfastGate || 0
    },

    methodFeeTxt() {
      return `${(this.methodFee * 100).toFixed(2)}%`
    },

    percentTaxTxt() {
      return `${(this.taxRate * 100).toFixed(2)}%`
    },

    tax() {
      return NP.times(this.taxRate, this.amount)
    },

    percentTipTxt() {
      return `${(this.currentTip).toFixed(2)}%`
    },

    amountTipTxt() {
      return this.amountTip
    },

    subTotal() {
      return this.amount + this.serviceCharge + this.tax
    },

    subTotalTxt() {
      return this.convertUSDtoPKR(this.subTotal - this.tax).toLocaleString('en-US')
    },

    totalAmount() {
      return NP.divide(Math.floor(NP.times(NP.plus(this.convertUSDtoPKR(this.subTotal), this.payfastFee), 100)), 100)
    },

    serviceCharge() {
      return this.percentFee * this.amount
    },

    payfastFee() {
      return this.methodFee * this.convertUSDtoPKR(this.subTotal)
    },

    payfastFeeTxt() {
      return (this.totalAmount - this.convertUSDtoPKR(this.subTotal)).toLocaleString('en-US')
    },

    amountValidate() {
      return !!(this.amount >= this.minTopUp && this.amount <= this.maxAmount)
    },

    maxAmount() {
      return 100000
    },
  },
}
</script>

<style lang="scss" scoped>
.summary-container {
  padding: 20px;
  background-color: #F5F5F7;
  // border: 1px solid rgba(22, 33, 62, 0.1);
  border-radius: 16px;

  .title-summary{
    margin-bottom: 21px;
  }
}
.transfer {
  max-width: 125px;
}

.total-amount{
  color: #00A9DE;
}
</style>
