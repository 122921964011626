<template>
  <div
    class="btn-amount"
    :class="{'active': isActive}"
    @click="choosePaymentAmount"
  >
    <span>${{ item.value }}</span>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },

  },

  computed: {},

  methods: {
    choosePaymentAmount() {
      this.$emit('choosePaymentAmount', {
        item: this.item,
        index: this.index,
      })
    },
  },
}
</script>
<style lang="scss" scoped>
  @import '@/assets/scss/variables/_variables.scss';
  .btn-amount{
    margin-top: 5px;
    margin-right: 10px;
    border: 1px solid #e0e0e5;
    border-radius: 6px;
    padding: 6px 12px;
    background-color: white;
    color: #3D405B;
    cursor: pointer;
    font-size: 14px;
    &:hover, &.active{
      border: 1px solid $primary;
      color: $primary;
      font-weight: 600;
    }

    &:last-child{
      margin-right: 0
    }

    @media (max-width: 767px) {
      margin-bottom: 10px;
    }

  }
</style>
