<template>
  <div>
    <b-row
      v-if="false"
      class="width-warning"
    >
      <b-col
        md="10"
        offset-md="1"
        class="mt-1"
      >
        <div class="warning-message p-2 border-form d-flex align-items-start">
          <img
            class="alert-img mr-50"
            alt="alert"
            :src="require('@/assets/images/common/ic-alert.svg')"
          >
          <span>{{ $t('payment.wiseTransfer.notificationMaintenance', {payment: "Tazapay"}) }}</span>
        </div>
        <user-hint :class="showUserGuide ? 'd-lg-none' : ''" />
      </b-col>
    </b-row>

    <div
      v-else-if="isInProgress"
      :class=" hasStepGuide ? 'in-process-step-guide' : null"
    >
      <Progress :is-in-progress="isInProgress" />
    </div>
    <div
      v-else
      class="width-content"
    >
      <b-row class="add-fund-container">
        <b-col
          :md="hasStepGuide ? 12 : 9"
          class="card-content"
        >
          <div
            class="airwallex-top-up-amount"
            :class="hasStepGuide ? 'radius-content' : 'list-card'"
            @mouseover="showGuideDefault = false"
            @mouseleave="showGuideDefault = true"
          >
            <top-up-amount
              :payment-method="paymentMethod"
              :amount="amount"
              @on-confirm="handleRequestTazapay"
            />
          </div>
        </b-col>
        <b-col
          v-if="!hasStepGuide"
          class="payment-user-guide card-content"
          cols="12"
          md="9"
          :lg="hasStepGuide ? 12 : 3"
        >
          <user-guide v-if="showUserGuide" />
        </b-col>
      </b-row>
      <div class="user-hint mt-1 mb-2 d-flex">
        <user-hint :class="showUserGuide ? 'd-lg-none' : ''" />
      </div>
    </div>

    <modal-confirm-tazapay
      ref="open-modal-confirm-tazapay"
      @hide-modal-confirm="hideModalConfirm"
    />
  </div>
</template>
<script>
/* eslint-disable camelcase */
import { BRow, BCol } from 'bootstrap-vue'
import { toastification } from '@core/mixins/toast'
import { createNamespacedHelpers } from 'vuex'
import stepGuideMixin from '@/mixins/stepGuideMixin'
import gaTrackingMixin from '@/mixins/gaTrackingMixin'
import ModalConfirmTazapay from '@/views/payment/components/Tazapay/ModalConfirmTazapay.vue'
import authMixin from '@/mixins/authMixin'
import envMixin from '@/mixins/envMixin'
import UserHint from '@/components/UserHint.vue'
import { PAYMENT_METHOD, TAZAPAY_PAYMENT_STATUS_V3 } from '@/constants'
import UserGuide from '@/components/UserGuide.vue'
import TopUpAmount from './TopUpAmount.vue'
import Progress from './Progress.vue'

const {
  mapActions,
  mapGetters,
  mapState,
} = createNamespacedHelpers('payment')

export default {
  name: 'Tazapay',
  components: {
    ModalConfirmTazapay,
    BRow,
    BCol,
    TopUpAmount,
    Progress,
    UserHint,
    UserGuide,
  },
  mixins: [toastification, stepGuideMixin, authMixin, gaTrackingMixin, envMixin],
  data() {
    return {
      paymentMethod: 'tazapay',
      amount: 0,
      showGuideDefault: true,
      isInProgress: false,
      isConfirm: false,
    }
  },

  computed: {
    ...mapGetters(['status', 'message', 'tazapayPending']),
    ...mapState({
      clientRedirectUrl: state => state.tazapayRequest.clientRedirectUrl,
    }),
  },

  watch: {
    clientRedirectUrl: {
      handler(newUrl) {
        if (newUrl !== '') {
          window.location.href = newUrl
        }
      },
      immediate: true,
      deep: true,
    },

    tazapayPending: {
      handler(pending) {
        if (pending?.payload) {
          const { query } = this.$route
          const {
            payment_status,
          } = pending?.payload?.tazapayPayload?.data || ''
          if (payment_status) {
            if (payment_status === TAZAPAY_PAYMENT_STATUS_V3.PAYMENT_PROCESSING) {
              this.isInProgress = true
            } else if (payment_status === TAZAPAY_PAYMENT_STATUS_V3.PAYMENT_UNPAID && query.tab === 'tazapay') {
              this.isConfirm = true
              this.$nextTick(() => {
                this.showConfirmModal()
              })
            }
          }
        }
      },
      deep: true,
      immediate: true,
    },

    $route: {
      handler(route) {
        if (route) {
          const { query } = this.$route
          const {
            payment_status,
          } = this.tazapayPending?.payload?.tazapayPayload?.data || ''
          if (query?.tab === 'tazapay') {
            if (!this.isConfirm && payment_status === TAZAPAY_PAYMENT_STATUS_V3.PAYMENT_UNPAID) {
              this.isConfirm = true
              this.$nextTick(() => {
                this.showConfirmModal()
              })
            }
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },

  created() {
    if (!this.tazapayPending?.payload) {
      this.getTazapayPending()
    }
  },

  methods: {
    ...mapActions(['requestTazapayPayment', 'getTazapayPending']),

    hideModalConfirm() {
      this.isConfirm = false
    },

    openConfirmPaymentModal() {
      this.$refs['open-modal-confirm-tazapay'].showModal()
    },

    showConfirmModal() {
      if (this.isConfirm) {
        this.openConfirmPaymentModal()
      }
    },

    async handleRequestTazapay(data) {
      await this.requestTazapayPayment(data)
      if (this.isOnlyEcomdyPlatform) {
        this.gaTrackingUserAddFund(data.amount, PAYMENT_METHOD.TAZAPAY) // GA Tracking
      }
      if (!this.status) {
        this.toastFailure(this.message)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.note-content{
  font-size: 12px;
  font-style: italic;
}

.list-card{
  // max-width: 864px;
  border-radius: var(--border-radius-base);
}

.radius-content {
  border-radius: 0px 0px var(--border-radius-base) var(--border-radius-base);
}

.in-process-step-guide{
  border-radius: 0 0 var(--border-radius-base) var(--border-radius-base);
  padding: 1.5rem;
  background-color: #fff;
  margin-top: 0;

  .summary-form{
    max-width: 410px;
    margin: 0 auto;
  }
}

.warning-message {
  margin-top: 10px;
  background: rgba(251, 176, 59, 0.1);
  border-radius: 10px;
  padding: 10px 12px 10px 16px;
}
</style>
