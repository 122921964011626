<template>
  <div>
    <b-row class="width-content add-fund-container">
      <b-col
        :md="hasStepGuide ? 12 : 9"
        class="p-0 mb-2 card-content"
      >
        <div class="airwallex-payment-info p-0 mb-2 card-content">
          <payment-infor />
        </div>

        <div
          class="airwallex-top-up-amount p-0 card-content"
          @mouseover="showGuideDefault = false"
          @mouseleave="showGuideDefault = true"
        >
          <top-up-amount
            :payment-method="paymentMethod"
            :amount="amount"
            @on-confirm="handleCreateAmountAirwallex"
          />
        </div>
        <div class="user-hint mt-1 mb-2 d-flex">
          <user-hint :class="showUserGuide ? 'd-lg-none' : ''" />
        </div>
      </b-col>
      <b-col
        v-if="!hasStepGuide"
        cols="12"
        md="9"
        :lg="hasStepGuide ? 12 : 3"
        class="payment-user-guide card-content"
      >
        <user-guide v-if="showUserGuide" />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { BRow, BCol } from 'bootstrap-vue'
import { toastification } from '@core/mixins/toast'
import {
  redirectToCheckout,
  loadAirwallex,
} from 'airwallex-payment-elements'
import { BANK_ACCOUNTS, PAYMENT_METHOD } from '@/constants'
import { createNamespacedHelpers } from 'vuex'
import stepGuideMixin from '@/mixins/stepGuideMixin'
import authMixin from '@/mixins/authMixin'
import gaTrackingMixin from '@/mixins/gaTrackingMixin'
import envMixin from '@/mixins/envMixin'
import UserHint from '@/components/UserHint.vue'
import UserGuide from '@/components/UserGuide.vue'
import TopUpAmount from './TopUpAmount.vue'
import PaymentInfor from './PaymentInfor.vue'

const {
  mapActions,
  mapGetters,
} = createNamespacedHelpers('payment')

loadAirwallex({
  env: process.env.VUE_APP_AIRWALLEX_ENV,
})

export default {
  name: 'AirwallexNew',
  components: {
    UserHint,
    BRow,
    BCol,
    TopUpAmount,
    PaymentInfor,
    UserGuide,
  },
  mixins: [toastification, stepGuideMixin, authMixin, gaTrackingMixin, envMixin],
  data() {
    return {
      paymentMethod: BANK_ACCOUNTS[0],
      amount: 0,
      showGuideDefault: true,
    }
  },
  computed: {
    ...mapGetters(['airwallexOptions', 'loading', 'status', 'message']),
  },
  methods: {
    ...mapActions(['requestAirwallexPayment']),

    redirectHppForCheckout() {
      const options = {
        ...this.airwallexOptions,
        logoUrl: process.env.VUE_APP_CHECKOUT_AIRWALLEX_ECOMDY_LOGO,
      }
      redirectToCheckout(options)
    },
    async handleCreateAmountAirwallex(data) {
      const params = {
        amount: data.amount,
        tipAMAmount: data.tipAMAmount,
      }
      await this.requestAirwallexPayment(params)
      if (this.isOnlyEcomdyPlatform) {
        this.gaTrackingUserAddFund(data.amount, PAYMENT_METHOD.AIRWALLEX_AUTO) // GA Tracking
      }
      if (this.status) {
        await loadAirwallex({
          env: this.airwallexOptions.env,
        })
        this.redirectHppForCheckout()
      } else {
        this.toastFailure(this.message || 'Error')
      }
    },
    goToAirGuide() {
      window.open(process.env.VUE_APP_GUIDE_TO_TOP_UP_AIRWALLET)
    },
  },
}
</script>
