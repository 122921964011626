<template>
  <b-modal
    ref="top-up-fail-modal"
    id="top-up-fail-modal"
    class="top-up-fail-modal"
    ok-title="Confirm"
    size="w-refund"
    hide-footer
    hide-header
    hide-header-close
    centered
    no-close-on-backdrop
  >
    <div class="d-flex justify-content-end top-modal">
      <div>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hideModal"
        />
      </div>
    </div>
    <div
      class="d-flex flex-column align-items-center"
    >
      <b-img
        rounded
        class="mr-50"
        :src="require('@/assets/images/pages/payment/ic-payment-failure.svg')"
        style="width: 60px"
      />
      <h4 class="mt-1">
        {{ $t('SMB.payment.textTopUpFail') }}
      </h4>

      <p
        v-if="topUpAmount"
        class="mt-1"
      >
        {{ $t('SMB.payment.contentTopUpFail', { topUpAmount }) }}
      </p>
    </div>

    <error-tag
      v-if="errorMessage"
      :error="decodeURIComponent(errorMessage)"
    />

    <user-hint />

    <!-- modal footer -->
    <div class="d-flex justify-content-end mt-2">
      <btn-loading
        class="btn-close"
        variant-convert="secondary"
        pill
        @click="hideModal"
      >
        {{ $t('SMB.payment.textClose') }}
      </btn-loading>
    </div>
    <!-- modal footer -->
  </b-modal>
</template>
<script>
/* eslint-disable no-underscore-dangle */
import {
  BModal,
  BImg,
} from 'bootstrap-vue'
import { toastification } from '@core/mixins/toast'
import stepGuideMixin from '@/mixins/stepGuideMixin'
import generalConfigsMixin from '@/mixins/generalConfigsMixin'
import UserHint from '@/components/UserHint.vue'
import ErrorTag from '@/components/errorTag.vue'
import BtnLoading from '@/layouts/components/BtnLoading.vue'

export default {
  components: {
    ErrorTag,
    BtnLoading,
    UserHint,
    BImg,
    BModal,
  },

  mixins: [toastification, stepGuideMixin, generalConfigsMixin],

  props: {
    topUpAmount: {
      type: Number,
      default: 0,
    },
    errorMessage: {
      type: String,
      default: '',
    },
  },

  methods: {
    hideModal() {
      this.$refs['top-up-fail-modal'].hide()
    },

    showModal() {
      this.$refs['top-up-fail-modal'].show()
      localStorage.removeItem('id_transaction')
      this.$router.push('/payment/add-fund')
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.modal-w-refund {
  //max-width: 556px;
  //margin-top: 55px;
}

.modal-w-withdraw {
  max-width: 734px;
  margin-top: 55px;
}

#top-up-fail-modal {
  .modal-footer-custom {
    border-top: none !important;
  }

  .modal-body {
    @media (min-width: 767px) {
      padding: 2rem !important;
    }
  }
}

.list-bank-select-refund {
  .vs__dropdown-toggle {
    align-items: start;
    padding: 6px 0;
  }
}
</style>
<style lang="scss" scoped>
.icon-info {
  margin-left: 5px;
}

.top-modal {
  margin-bottom: 30px;
}

.content-top {
  border-bottom: 1px solid rgba(22, 33, 62, 0.1);
  margin-bottom: 24px;
}

.input-height {
  height: 48px;
}

.text-secondary {
  color: #3d405b !important;
}

.mx-div {
  margin-top: 12px;
  margin-bottom: 12px;
}

.bank-logo{
  width: 60px;
  margin-right: 6px;
}

.btn-close {
  color: rgb(22 33 62 / 76%) !important;
  background-color: rgba(0, 0, 0, 0.05) !important;
  padding: 11px 20px;
  height: max-content;
  border: 0;
}

.warning-message {
  background: rgba(251, 176, 59, 0.1);
  border-radius: 10px;
  padding: 10px 12px 10px 16px;
}
</style>
